// index.js
import React, {useEffect, useState} from "react";
import styled, {css, keyframes} from 'styled-components';

function ModalComponent( {backdropOpacity, isVisible, hardwareAccelerated, children} ){

    console.log("ModalComponent!!!!!");
    useEffect(() => {
      /*console.log("window.location.href : " + window.location.href);
      console.log("indexOf : " + window.location.href.indexOf("admin"));
      if( window.location.href.indexOf("admin") > -1 ) {
        console.log("have");
      }*/
        console.log("App useEffect!!!!!");
    }, []);
  
    
    return (
      <>
        { isVisible == true && 
          <Container aniFade>
            {children}
          </Container>
        }
      </>
      
    );
}
export default ModalComponent;

/**
 * start - animation
 */
const aniFade = keyframes`
0% {
  opacity:0;
}
100% {
  opacity:1;
}
`;
/**
* End - animation
*/

const Container = styled.div`
  background:rgba(0,0,0,0.5);
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:9999;

  ${(props) => props.aniFade && css`
    animation:${aniFade} 0.4s ease-in-out;
  `}
`;
