// React 이전 에러 수정

import React, {useState, useEffect, useContext, createContext, useRef, useCallback, memo} from 'react';
import Const from '../util/Const';
import Util from '../util/Util';
import styled, {css} from 'styled-components';
//import RNPickerSelect from 'react-native-picker-select'; // TODO 다른거 대체 필요

import RNPickerSelectComponent from './component/RNPickerSelectComponent';
//import Modal from 'react-native-modal';
import ModalComponent from './component/ModalComponent.js';

// import DropDownPicker from 'react-native-dropdown-picker'; // TODO 다른거 대체 필요
import DropDownPickerComponent from './component/DropDownPickerComponent';

import ShadowComponent from './component/ShadowComponent';
import ButtonComponent from './component/ButtonComponent';
import CustomDrawerContent from "./CustomDrawerContent"; // 메뉴

import closeImg from '../img/close.png'
import infoImg from '../img/info.png'
import treeImg from '../img/tree.png'
import noImg from '../img/no_img.png'
import liveImg from '../img/live.png'
import timeImg from '../img/time.png'
import inImg from '../img/in.png'
import top_icon2Img from '../img/top_icon2.png'
import empty1Img from '../img/empty1.png'
import empty2Img from '../img/empty2.png'
import calendar_leftImg from '../img/calendar_left.png'
import calendar_rightImg from '../img/calendar_right.png'
import googleImg from '../img/google.png'
import photo_schoolImg from '../img/photo_school.png'
import ServerRequestManager from '../util/ServerRequestManager';

const date = new Date();
const Context = createContext();

// 최상단 아이 버튼 정보
const childrenArr = [
  {
    //logo: require('../img/google.png'),
    logo: "",
    name: '아이이름',
    schoolName: '어린이집'
  }
];

// 오늘의 온라인 교실 리스트
const todayArr = [
  {
    id: 0,
    title: '입학어린이 수업 및 교재 소개에 대한 오리엔테이션',
    start: '13:00',
    end: '14:00',
    status: ServerRequestManager.ROOM_STATUS_LIVE
  },
  {
    id: 1,
    title: '꽃잎반 3월 오리엔테이션',
    start: '15:00',
    end: '16:00',
    status: ServerRequestManager.ROOM_STATUS_RESERVATION
  },
  {
    id: 2,
    title: '새싹반 3월 오리엔테이션',
    start: '11:00',
    end: '12:00',
    status: ServerRequestManager.ROOM_STATUS_END
  },
];

// 예약일정 년, 월 선택 옵션
const years = [
  {label: String(date.getFullYear()), value: String(date.getFullYear())},
  {
    label: String(date.getFullYear() + 1),
    value: String(date.getFullYear() + 1),
  },
];

const months = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
];

// 예약일정 리스트
const monthclassArr = [
  {
    day: 4,
    title: '입학어린이 수업 및 교재 소개에 대한 오리엔테이션',
    start: '13:00',
    end: '14:00',
  },
  {
    day: 6,
    title: '꽃잎반 3월 오리엔테이션',
    start: '15:00',
    end: '16:00',
  },
];

// 어린이집 사진
const photoArr = [
  {
    id: 0,
    //img:require('../img/photo_school.png')
    img:photo_schoolImg
  },
  {
    id: 1,
    img:photo_schoolImg
  },
  {
    id: 2,
    img:photo_schoolImg
  },
];

const nickNameItems = [
  {label: '없음', value: 0},
  {label: '부모님', value: 1}
];
const groupItems = [
  {label: '햇님반1', value: '햇님반1'},
  {label: '햇님반2', value: '햇님반2'},
  {label: '햇님반3', value: '햇님반3'},
];

/**************************************************************************************************
 * 최상단 아이 선택 component
 *  @param {items.logo}          어린이집 로고
 *  @param {items.name}          아이 이름
 *  @param {childrenChk}         몇번째 아이버튼 클릭인지 체크
 *  @param {changeChildrenChk}   함수 => 버튼 클릭 감지
 ***************************************************************************************************/
function ChildrenSelect ({items, childrenChk, changeChildrenChk}){
  if (items == null || items == 'undefined') {
    items = ['1번', '2번', '3번'];
  }
  const childLength = items.length;

  const listItems = items.map((item, index) => (
    <Btn_child
      key={index}
      index={index} // INDEX => CSS에서 스타일 구분 용도로 사용중
      childrenChk={childrenChk}
      childLength={childLength}
      onClick={() => {
        changeChildrenChk(index);
      }}>
      <Img_child_box>
        <Img_child_logo
          src={Util.makeShowImageFromUrl(item.logo, treeImg)}
          resizeMode={'contain'}
          index={index}
          childrenChk={childrenChk}
        />
      </Img_child_box>

      <Text_child_name
        index={index}
        childrenChk={childrenChk}
        childLength={childLength}>
        {item.name}
      </Text_child_name>
    </Btn_child>
  ));
  return listItems;
};

/**************************************************************************************************
 *
 * 추가정보입력 팝업창 component
 *
 ****************************************************************************************************/
function AddInfoModal({props}) {


  const usingContext = useContext(Context);

  if( usingContext.state.childrenItems == null || usingContext.state.childrenItems.length == 0 ) {
    return null;
  }

  let childrenItem = usingContext.state.childrenItems[Number(usingContext.state.childrenChk)];
  let childrenData = childrenItem.childrenData;
  let childCareData = childrenItem.childCareData;
    
  let classItems = [];
  if( childCareData != null ) {
    classItems = Util.makeClassList(childCareData.class_data);
    if( classItems.length > 0 && usingContext.state.groupStr == "" ) {
      console.log("classItems[0].value : " + classItems[0].value);
      //usingContext.action.setGroupStr(classItems[0].value);
      console.log("classItems[0].value22222");
    }
  }

  const clickContinueButton = () => {
    let globalData = usingContext.state.globalData;

    var member_type = usingContext.state.globalData.loginData.member_type;
    var id = usingContext.state.globalData.loginData.id;
    var pw = "";
    var sess_id = usingContext.state.globalData.loginData.sess_id;
    var member_img = null; // TODO 변경된거 없다면 파라미터 미포함
    var member_name = usingContext.state.nameStr;
    var member_alias = usingContext.state.nickNameValue;
    var middleData = null;

    if( member_name== null || member_name == "" ) {
      Util.showToast("가입자 이름이 비어있습니다.");
      return;
    }

    console.log('clickContinueButton member_type : ' + member_type + 
                ", id : " + id + 
                ", sess_id : " + sess_id + 
                ", member_img : " + member_img + 
                ", member_name : " + member_name + 
                ", member_alias : " + member_alias
                );
    if( childrenData == null ) {
      let code = childCareData.parent_code == null || childCareData.parent_code == ""?"":childCareData.parent_code;
      let name = usingContext.state.childNameStr;
      let gender = usingContext.state.gender === Const.SEX_TYPE_MALE?"남":"여";
      let birthday = usingContext.state.birthValue;
      let className = usingContext.state.groupStr;

      if( name == null || name == "" ) { 
        Util.showToast("자녀 이름을 입력해주세요.");
        return;
      }

      if( className == null || className == "" ) { 
        Util.showToast("자녀의 반 이름을 선택해 주세요.");
        return;
      }

      if( className == "없음" ) {
        Util.showToast("반 정보가 없는 어린이집은 아이입력이 불가능합니다. 어린이집에 확인해 주세요.");
        return;
      }

      if( code == null || code == "" ) { 
        Util.showToast("가입코드 정보를 가져올 수 없습니다.");
        return;
      }

      middleData = {
        children_data:[{
          code:code,
          name:name,
          gender:gender,
          birthday:birthday,
          class:className
        }]
      };

      console.log("clickContinueButton middleData : " + JSON.stringify(middleData));
    }

    ServerRequestManager.requestUpdateUserInfo((result, data) => {
      console.log("requestUpdateUserInfo result result : " + result);
      console.log("requestUpdateUserInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        reSaveUserDataAndJoinRoom();
      }
    }, member_type, id, sess_id, pw, member_img, member_name, member_alias, middleData);
    /**/
  };

  const reSaveUserDataAndJoinRoom = () => {
    let globalData = usingContext.state.globalData;

    var id = usingContext.state.globalData.loginData.id;
    var sess_id = usingContext.state.globalData.loginData.sess_id;
    
    // 유저 정보 요청
    ServerRequestManager.requestGetUserInfo((result, data) => {
      console.log("requestGetUserInfo result result : " + result);
      console.log("requestGetUserInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

        globalData.userData = data.result_data;
        usingContext.action.setGlobalData(globalData);


        joinRoom( globalData.userData );
      }
    }, id, sess_id);  
  }

  const joinRoom = ( userData ) => {
    let globalData = usingContext.state.globalData;
    let childrenChk = usingContext.state.childrenChk;
    let childrenItems = usingContext.state.childrenItems;
    
    // 웹뷰 메시지 전송
    let roomData = {
      req_type:'r_join',
      req_data:{
        rid:usingContext.state.currentRoomData.data.id,
        rpw:usingContext.state.currentRoomData.data.pw,
        name:userData.name,
        codec:usingContext.state.currentRoomData.data.codec,
        logo:Util.makeShowImageFromUrl(childrenItems[Number(childrenChk)].logo, ""),
        rname:usingContext.state.currentRoomData.title

      }
    }
    // console.log('roomData = ' + childrenItems[Number(childrenChk)].schoolName)
    ServerRequestManager.requestWebViewPostMessage( window, roomData );
  }

  return (
    <div>
      <ModalComponent
          backdropOpacity={0.6}
          isVisible={usingContext.state.isAddModalVisible}
          hardwareAccelerated={true} // 하드웨어 가속
          // backdropTransitionInTiming={300}
          // backdropTransitionOutTiming={300}
        >
          <Modal_contents
            // animation={Const.ANIMATION_NAME}
            // duration={Const.ANIMATION_DURATION}
          >
            <Wrapper_modal>
                <Modal_head>
                  <Touchable_Modal_close
                    onClick={() => usingContext.action.setIsAddModalVisible(false)}>
                    <Img_close
                      //source={require('../img/close.png')}
                      src={closeImg}
                      resizeMode={'contain'}
                    />
                  </Touchable_Modal_close>
                  <Modal_head_top>
                    <Img_info
                      src={infoImg}
                      resizeMode={'contain'}
                    />
                    <Modal_head_top_title>추가정보 입력</Modal_head_top_title>
                  </Modal_head_top>
                  <Text_head_btm>
                    추가정보를 입력하시면 진행중인 온라인 교실에 참여할 수
                    있습니다.
                  </Text_head_btm>
                </Modal_head>

                <Line_mid2 />

                  <Modal_body_title>가입자 정보</Modal_body_title>
                  <ShadowComponent>
                    <View_input_wrap>
                      <Input_common
                        placeholder="가입자 이름을 입력해주세요"
                        placeholderTextColor={usingContext.state.nameStr == '' ? '#bbb' : '#000'}
                        onChange={(val) => usingContext.action.setNameStr(val.target.value)}
                        value={usingContext.state.nameStr}
                      />
                    </View_input_wrap>
                  </ShadowComponent>
                  <ShadowComponent>
                    <View_select_wrap>
                      <RNPickerSelectComponent
                        placeholder={{
                          label:
                            usingContext.state.nickNameValue == ''
                              ? '호칭을 선택해주세요'
                              : usingContext.state.nickNameValue,
                          value: usingContext.state.nickNameValue,
                          color: '#ff4784',
                        }}
                        items={nickNameItems}
                        onValueChange={(val) => {
                          usingContext.action.setNickNameValue(val);
                        }}
                        value={usingContext.state.nickNameValue}
                        style={{
                          placeholder: {
                            color: usingContext.state.nickNameValue == '' ? '#bbb' : '#000',
                          },
                        }}
                      />
                    </View_select_wrap>
                  </ShadowComponent>

                  <Text_info>
                    ※ 온라인 교실 참여 시 다른 사람에게 표시됩니다.
                  </Text_info>

                  <Line_mid></Line_mid> 

                  {childrenData == null && 
                  <>
                  <ShadowComponent>
                    <Container_school_name>
                      <Container_img_box>
                        <Img_logo_small
                          src={treeImg}
                          resizeMode={'contain'}
                        />
                      </Container_img_box>
                      <Text_school_name_popup>{childrenItem.schoolName}</Text_school_name_popup>
                    </Container_school_name>
                  </ShadowComponent>
                  <ShadowComponent>
                    <View_input_wrap>
                      <View_input_area>                        
                        <View_input_width>
                          <Input_common
                            placeholder="아이 이름을 입력해주세요"
                            placeholderTextColor={
                              usingContext.state.childNameStr == '' ? '#bbb' : '#000'
                            }
                            onChange={(e) => usingContext.action.setChildNameStr(e.target.value)}
                            returnKeyType="done"
                            value={usingContext.state.childNameStr}
                          />
                        </View_input_width>
                        <View_wrap_radio>
                          <Btn_radio
                            onClick={() => {
                              usingContext.action.setGender(1);
                            }}>
                            <Text_radio gender={usingContext.state.gender} male>
                              남아
                            </Text_radio>
                          </Btn_radio>

                          <Btn_radio
                            onClick={() => {
                              usingContext.action.setGender(2);
                            }}
                          >
                            <Text_radio gender={usingContext.state.gender} female>
                              여아
                            </Text_radio>
                          </Btn_radio>
                        </View_wrap_radio>
                       </View_input_area>
                    </View_input_wrap>
                  </ShadowComponent>
                  <ShadowComponent>
                    <View_input_wrap selectbox>
                      <RNPickerSelectComponent
                        value={usingContext.state.birthValue}
                        items={Util.getBirthItems()}
                        onValueChange={(val) => {
                          usingContext.action.setBirthValue(val);
                        }}
                        style={{
                          placeholder: {
                            color: usingContext.state.birthValue == '' ? '#bbb' : '#000',
                          },
                        }}
                        placeholder={{
                          label: usingContext.state.birthValue == '' ? '2018년생' : usingContext.state.birthValue,
                          value: usingContext.state.birthValue,
                          color: '#ff4784',
                        }}
                      />
                    </View_input_wrap>
                  </ShadowComponent>
                  <ShadowComponent>
                    <View_input_wrap selectbox>
                      <RNPickerSelectComponent
                        value={usingContext.state.groupStr}
                        items={classItems}
                        onValueChange={(value) => {
                          usingContext.action.setGroupStr(value);
                        }}
                        style={{
                          placeholder: {
                            color: usingContext.state.groupStr == '' ? '#bbb' : '#000',
                          },
                        }}
                        placeholder={{
                          label:
                            usingContext.state.groupStr == ''
                              ? '반을 선택해주세요'
                              : usingContext.state.groupStr,
                          value: usingContext.state.groupStr,
                          color: '#ff4784',
                        }}
                      />
                    </View_input_wrap>
                  </ShadowComponent>
                  <Text_info>
                    ※ 내 아이에게 맞는 맞춤형 정보를 추천받을 수 있습니다.
                  </Text_info>
                  </>
                  }
                <div style={{height: Const.getRateHeight(15)}} />
                <ButtonComponent
                  text="저장 후 입장"
                  buttonClick={clickContinueButton}
                />
              </Wrapper_modal>
          </Modal_contents>
        </ModalComponent>
    </div>
  );
};

/**************************************************************************************************
 * '오늘의 온라인 교실' component
 * @param {items.title}   제목
 * @param {items.start}   시작 시간
 * @param {items.end}     종료 시간
 * @param {items.status}  진행중 / 대기중 / 종료 ( LIVE / WAITING / END )
/*************************************************************************************************/
const TodayConferences = ({items, props}) => {
  if (items == null || items == 'undefined') {
    items = ['1번', '2번', '3번', '1번', '2번', '3번'];
  }

  const usingContext = useContext(Context);

  const listItems = items.map((item, index) => (
    <div key={index}>
      <Todayclass status={item.status} key={item.id}>
        <Box_left status={item.status}>
          <Box_left_top>
            <Text_todayclass_title
              status={item.status}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {item.title}
            </Text_todayclass_title>
            <Text_todayclass_live>

              {
                // 오픈중일 때 LIVE 아이콘 삽입
                item.status == ServerRequestManager.ROOM_STATUS_LIVE && (
                  <Img_live
                    src={liveImg}
                    resizeMode={'contain'}
                  />
                )
              }
            </Text_todayclass_live>

          </Box_left_top>

          <Box_left_btm>
            <Img_time src={timeImg} />

            <Text_time status={item.status}>
              {item.start} ~ {item.end}
            </Text_time>
          </Box_left_btm>
        </Box_left>

        <Box_right status={item.status}>
          {item.status == ServerRequestManager.ROOM_STATUS_LIVE ? (
            // 입장
            <div
              onClick={() => {
                let globalData = usingContext.state.globalData;

                let isHaveData = false;

                // 참가자 이름이 있을때
                if( globalData.userData.name ) {
                  let childrenDatas = Util.makeChildrenListFromStr(globalData.userData.children_data);
                  if( childrenDatas.length > 1 ) {
                    isHaveData = true;
                  }
                  else {
                    if( childrenDatas.length == 1 ) {
                      if( childrenDatas[0].name ) {
                        isHaveData = true;
                      }
                    }
                  }
                }

                if( isHaveData ) {
                  /*var data = {};
                  data.screen_type = 1; // webview page
                  data.type = 1; // 생성 타입
                  data.code = 123555; // 생성코드*/

                  let childrenChk = usingContext.state.childrenChk;
                  let childrenItems = usingContext.state.childrenItems;

                  ServerRequestManager.requestJoinRoom((result, data) => {
                    console.log("requestJoinRoom result result : " + result);
                    console.log("requestJoinRoom result data : " + JSON.stringify(data));

                    if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                       // 웹뷰 메시지 전송
                        let roomData = {
                          req_type:'r_join',
                          req_data:{
                            rid:item.data.id,
                            rpw:item.data.pw,
                            name:globalData.userData.name,
                            codec:data.result_data.codec,
                            logo:Util.makeShowImageFromUrl(childrenItems[Number(childrenChk)].logo, ""),
                            rname:item.title
                          }
                        }
                        console.log('roomData = ' + roomData)
                        ServerRequestManager.requestWebViewPostMessage( window, roomData );
                    }
                  }, globalData.loginData.id, globalData.loginData.sess_id, item.data.id + "", null);
                }
                else {
                  usingContext.action.setCurrentRoomData(item);
                  // TODO 추가정보가 있다면은 바로 입장 없다면 추가정보 입력
                  usingContext.action.setIsAddModalVisible(true);
                }
              }}>
              <Img_btn_live
                //source={require('../img/in.png')}
                src={inImg}
                resizeMode={'contain'}
              />
            </div>
          ) : item.status == ServerRequestManager.ROOM_STATUS_PREPARE ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_RESERVATION}>준비중</Text_tim_right>
          ) : item.status == ServerRequestManager.ROOM_STATUS_RESERVATION ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_RESERVATION}>예약</Text_tim_right>
          ) : item.status == ServerRequestManager.ROOM_STATUS_CANCEL ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_END}>취소</Text_tim_right>
          ) : (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_END}>종료</Text_tim_right>
          )}
        </Box_right>
      </Todayclass>
    </div>
  ));
  return listItems;
};

/************************************************************************************************
 * '예약일정' component
 * @param {items.day}     예약된 일정 날짜
 * @param {items.title}   제목
 * @param {items.start}   시작 시간
 * @param {items.end}     종료 시간
 ************************************************************************************************/
function MonthclassConferences({items}){
  if (items == null || items == 'undefined') {
    items = ['1번', '2번', '3번'];
  }

  const listItems = items.map((item, index) => (
    <Monthclass key={index}>
      <View_day>
        <Text_day>{item.day}일</Text_day>
      </View_day>

      <View_monthclass_comment>
        <Box_left_top>
          <Text_monthclass_title numberOfLines={1} ellipsizeMode="tail">
            {item.title}
          </Text_monthclass_title>
        </Box_left_top>

        <Box_left_btm>
          <Img_time src={timeImg} />
          <Text_violet>
            {item.start} ~ {item.end}
          </Text_violet>
        </Box_left_btm>
      </View_monthclass_comment>
    </Monthclass>
  ));
  return listItems;
};


/************************************************************************************************
 * '어린이집 사진' component
 * @param {schoolPhoto}
 ************************************************************************************************/
function ImgSchoolComponent ( {schoolPhoto} ) {

  const listItems = schoolPhoto.map((item, index) => (
    <Img_school
      key={index}
      src={item.img}
      resizeMode={'contain'}
    />
  ));
  return listItems;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/

function AFMainParentScreen(props) {
  console.log("AFMainParentScreen!!!");

  const [childrenItems, setChildrenItems] = useState(childrenArr);    // 최상단 아이선택 아이 정보
  const [childrenChk, setChildrenChk] = useState('0');                // 최상단 아이선택 버튼 활성화용, value:0,1,2 몇번째 아이인지 체크
  
  const [schoolName, setSchoolName] = useState("기본어린이집");
  const [logo, setLogo] = useState(treeImg);                               // 어린이집 로고
  const [schoolPhoto, setSchoolPhoto] = useState([]);           // 어린이집 사진
  const [todayItems, setTodayItems] = useState([]);             // 오늘의 온라인 교실
  const [monthclassItems, setMonthclassItems] = useState([]); // 예약일정
  const [year, setYear] = useState(date.getFullYear());                  // select 년 option
  const [month, setMonth] = useState(date.getMonth() + 1);               // select 월 option
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);     // 추가정보입력 팝업창 show / hide
  const [currentRoomData, setCurrentRoomData] = useState(null);           // 현재 방 데이터

  const [nameStr, setNameStr] = useState('');                 // 가입자 이름
  const [nickNameValue, setNickNameValue] = useState(0);         // 호칭 값
  const [childNameStr, setChildNameStr] = useState('');       // 아이 이름
  const [gender, setGender] = useState(1);                   // 남아, 여아

  let todayDate = new Date();
  const [birthValue, setBirthValue] = useState(todayDate.getFullYear());               // 출생년도
  const [groupStr, setGroupStr] = useState('');               // 현재 선택된 반이름

  const [isOpenMenu, setIsOpenMenu] = useState(false);    // 메뉴 열림 유무
  const [globalData, setGlobalData] = useState(null);     // 글로벌 데이터


  useEffect(() => {
    console.log("AFMainParentScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA);

    ServerRequestManager.requestChangePage( window, "AFMainParentScreen" );

  }, []);

  /*useInterval(() => {
    requestTodayClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num);
  }, Const.ROOM_LIST_REFRESH_DELAY);*/

  const IpcReceiver = (arg) => {
    console.log("AFMainParentScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        console.log("AFMainParentScreen IpcReceiver arg.data : " + JSON.stringify(arg.data) );
        setGlobalData(arg.data);

        let codeCheckResult = arg.data.codeCheckResult;
        let childCareDatas = arg.data.childCareData;
        let userData = arg.data.userData;
        let childrenDatas = Util.makeChildrenListFromStr(arg.data.userData.children_data);

        var childcare_num = null;

        if( userData != null ) {
          setNickNameValue(userData.member_alias);
          setNameStr(userData.name);
        }

        let cItems = reSetChildItems( userData, childrenDatas, childCareDatas );
        if( cItems != null && cItems.length > 0 ) {
          setSchoolElement( cItems[0].childCareData);
          childcare_num = cItems[0].childCareData.num;
        }
        
        requestTodayClassList( arg.data.loginData, childcare_num);
        requestMonthClassList( arg.data.loginData, childcare_num);
      }
    } 
  }

  const reSetChildItems = ( userData, childrenDatas, childCareDatas ) => {
    let newChildrenItems = [];

    for( var i=0; i<childrenDatas.length; i++ ) {
      for( var j=0; j<childCareDatas.length; j++ ) {
        
        if( childrenDatas[i].childcare_num == childCareDatas[j].num ) {

          let item = {
            childrenData:childrenDatas[i],
            childCareData:childCareDatas[j],
            logo:childCareDatas[j].logo_path,
            name:childrenDatas[i].name,
            schoolName:childCareDatas[j].name,
            childcare_num:childCareDatas[j].num
          }
          newChildrenItems.push(item);
          break;
        }
      }
    }

    if( newChildrenItems.length == 0 ) {
      newChildrenItems.push({
        logo: childCareDatas!=null&&childCareDatas.length>0?childCareDatas[0].logo_path:"",
        name: '이름없음',
        schoolName: childCareDatas!=null&&childCareDatas.length>0?childCareDatas[0].name:"어린이집",
        childcare_num:userData.childcare_num?userData.childcare_num.split("::")[1]:0,
        childrenData:childrenDatas!=null&&childrenDatas.length>0?childrenDatas[0]:null,
        childCareData:childCareDatas!=null&&childCareDatas.length>0?childCareDatas[0]:null
      });
      let classes = Util.makeClassList(childCareDatas!=null&&childCareDatas.length>0?childCareDatas[0].class_data:null);
      if( classes != null && classes.length > 0 ) {
        setGroupStr( classes[0].value );
      }
    }
    else {
        setGroupStr( newChildrenItems[0].childrenData.className );
    }


    setChildrenItems(newChildrenItems);
    return newChildrenItems;
  }

  const setSchoolElement = ( childCareData ) => {
    let photoList = [];
    setSchoolName(childCareData.name?childCareData.name:"어린이집");
    
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path1, photo_schoolImg) } );
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path2, photo_schoolImg) } );
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path3, photo_schoolImg) } );
    
    /*
    if( childCareData.img_path1 ) {
      photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path1, photo_schoolImg) } );
    }
    if( childCareData.img_path2 ) {
      photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path2, photo_schoolImg) } );
    }
    if( childCareData.img_path3 ) {
      photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path3, photo_schoolImg) } );
    }*/
    setSchoolPhoto(photoList);
  }


  /* 오늘의 온라인 교실 목록 요청 */
  const requestTodayClassList = ( loginData, childcare_num, callback ) => {
    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let todayDayOfMonth = todayDate.getDate();
    let ym = todayYear + "" + Util.makeTwoNumberStr(todayMonth);
    let ymd = ym + Util.makeTwoNumberStr(todayDayOfMonth);

    ServerRequestManager.requestRoomList((result, data) => {
      console.log("requestRoomList result result : " + result);
      console.log("requestRoomList result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        let todays = [];

        if( data.result_data != null && data.result_data != undefined && data.result_data.length > 0 ) {
          
          for( var i=0; i<data.result_data.length; i++ ) {
            // 오늘일 경우에 추가
            if( data.result_data[i].ymd === ymd ) {
              let title = data.result_data[i].subject;
              let start = data.result_data[i].st_time;
              let end = data.result_data[i].ed_time;
              let status = data.result_data[i].status;
              todays.push({
                data:data.result_data[i],
                title:title,
                start:start,
                end:end,
                status:status
              });
            }
          }
        }

        setTodayItems(todays);
      }

      if( callback ) {
        callback(data.result);
      }
    }, loginData.member_type, loginData.id, loginData.sess_id, ym, childcare_num);
  }

  /* 온라인 교실 목록 요청 */
  const requestMonthClassList = ( loginData, childcare_num, paramYear, paramMonth ) => {

    if( paramYear == null )   paramYear = year;
    if( paramMonth == null )  paramMonth = month;
    let ym = paramYear + "" + Util.makeTwoNumberStr(paramMonth);

    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let todayDayOfMonth = todayDate.getDate();
    let todayIntYmd = todayYear * 10000 + ( todayMonth * 100 ) + todayDayOfMonth

    ServerRequestManager.requestRoomList((result, data) => {
      console.log("requestRoomList result result : " + result);
      console.log("requestRoomList result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        let months = [];

        if( data.result_data != null && data.result_data != undefined && data.result_data.length > 0 ) {
          
          for( var i=0; i<data.result_data.length; i++ ) {
            // 오늘일 경우에 추가 
            let intYmd = 0;
            if( data.result_data[i].ymd ) {
              intYmd = parseInt(data.result_data[i].ymd);
            }

            if( intYmd > todayIntYmd ) {

              let title = data.result_data[i].subject;
              let start = data.result_data[i].st_time;
              let end = data.result_data[i].ed_time;

              let day = 0;
              if( data.result_data[i].ymd ) {
                day = parseInt(data.result_data[i].ymd.substr(6, 2));
              }

              months.push({
                data:data.result_data[i],
                title:title,
                start:start,
                end:end,
                day:day
              });
            }
          }
        }

        setMonthclassItems(months);
      }
    }, loginData.member_type, loginData.id, loginData.sess_id, ym, childcare_num);
  }

  /********************************************************************************
   * ※ Context API 를 이용해서, 자식 컴포넌트 어디에서든지 value를 사용할 수 있게 작업. 
   *    (다른 JS파일로 이동은 불가, 한 파일내에서만 주고받기 가능)
   *  
   * 사용법
   * 1. <Context.Provider>로 전체를 감싸고, value를 아래와 같이 JSON 형태로 던진다.
   * 2. 이 값을 사용하려는 컴포넌트에서 <Context.Consumer>로 받아서 쓰면 끝.
   * 3. 추가로 <Context.Consumer>로 받아와 쓰는 방법외에, useContext()로 변수로 값을 받아와 쓰는 방법도 있음. (이 방법도 파일내부에서 사용중)
   ******************************************************************************/
  return (
    <div>
      {/* '입장' 눌렀을 시 뜨는 '추가정보입력' 팝업창에 값 전달*/}
      <Context.Provider
        value={{
          state: {
            isAddModalVisible,
            nameStr,
            nickNameValue,
            childNameStr,
            birthValue,
            groupStr,
            gender,
            childrenItems,
            childrenChk,
            globalData,
            currentRoomData,
          },
          action: {
            setIsAddModalVisible,
            setNameStr,
            setNickNameValue,
            setChildNameStr,
            setBirthValue,
            setGroupStr,
            setGender,
            setChildrenItems,
            setChildrenChk,
            setGlobalData,
            setCurrentRoomData
          },
        }}
      >
      <div>
        <div>
          <div>
            <Container>
              <Layout_view_top>
                <Text_top_title>온라인교실</Text_top_title>
                <TouchAble_btn_nav onClick={() => setIsOpenMenu(true)}>
                  <Img_top_icon2
                    //source={require('../img/top_icon2.png')}
                    src={top_icon2Img}
                    resizeMode={'contain'}
                  />
                </TouchAble_btn_nav>
              </Layout_view_top>
              <Wrap>
                <Layout_view_user>
                  <ChildrenSelect
                    items={childrenItems}
                    childrenChk={childrenChk}
                    changeChildrenChk={(index) => {
                      setChildrenChk(index);
                      setSchoolElement( childrenItems[index].childCareData);

                      var childcare_num = childrenItems[index].childCareData.num;
                      
                      requestTodayClassList( globalData.loginData, childcare_num);
                      requestMonthClassList( globalData.loginData, childcare_num);
                    }}
                  />
                </Layout_view_user>

                <Line_mid></Line_mid> 

                <Intro_profile>
                  <Box_img_propfile>
                    <Img_profile
                      //source={childrenItems[Number(childrenChk)].logo}
                      src={ Util.makeShowImageFromUrl(childrenItems[Number(childrenChk)].logo, treeImg)}
                      resizeMode={'contain'}
                    />
                  </Box_img_propfile>
                  <Box_text_profile>
                    <Text_school_name>{childrenItems[Number(childrenChk)].schoolName}</Text_school_name>
                    <Text_intro>
                      유아 교육 전용 온라인 교실에<br />오신 것을 환영합니다.
                    </Text_intro>
                  </Box_text_profile>
                </Intro_profile>

                <Box_img_school>
                  <ImgSchoolComponent schoolPhoto={schoolPhoto} />
                </Box_img_school>

                <Line_mid></Line_mid>
                <Wrapper_title_head>
                  <Text_item_title>오늘의 온라인 교실</Text_item_title>
                  <Text_refresh onClick={() => {
                      requestTodayClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num, (result)=> {
                        if( result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                          alert("리스트 새로고침 완료");
                        }
                        else {
                          alert("리스트 새로고침 실패");
                        }
                      })
                    }}>
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </Text_refresh>
                </Wrapper_title_head>
                {
                  // 프로그램 있는지 체크
                  todayItems.length == 0 ? ( // 프로그램 없음
                    <Today_empty>
                      <Img_empty
                        //source={require('../img/empty1.png')}
                        src={empty1Img}
                        resizeMode={'contain'}
                      />
                      <Text_empty>오늘 예정된 프로그램이 없습니다.</Text_empty>
                    </Today_empty>
                  ) : ( // 프로그램 있음
                    <>
                    <TodayConferences items={todayItems} props={props}/>

                    {/* 추가정보입력 팝업창 */}
                    { isAddModalVisible && <AddInfoModal props={props}/>}
                    </>
                  )
                }

              <Line_mid></Line_mid>

                <View_calendar_top>
                  <Text_item_title>예약일정</Text_item_title>
                </View_calendar_top>

                <View_top>
                  <Touchable_prev
                    onClick={() => {
                      console.log("Pre btn!!!! globalData : " + JSON.stringify(globalData) );
                      console.log("Pre - year : " + year + ", month : " + month);

                      let changeYear = year;
                      let changeMonth = month;
                      if (month == 1) {
                        changeYear = years[0].value;
                        changeMonth = year != years[0].value ? 12 : months[0].value;
                        setYear(changeYear);
                        setMonth(changeMonth); // 뒤로가기가 올해라면 12월로 변경
                      } else {
                        changeMonth = changeMonth - 1;
                        setMonth(changeMonth);
                      }
                      requestMonthClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num, changeYear, changeMonth );
                    }}>
                    <Img_calendar
                      //source={require('../img/calendar_left.png')}
                      src={calendar_leftImg}
                      resizeMode={'contain'}
                    />
                  </Touchable_prev>
                  <Wrap_text>
                    {
                    <DropDownPickerComponent
                      items={years}
                      defaultValue={String(year)}
                      itemStyle={{justifyContent: 'flex-start'}}
                      activeItemStyle={{display: 'none'}}
                      arrowStyle={{display: 'none'}}
                      onValueChange={(val) => {
                        setYear(parseInt(val));
                        requestMonthClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num, val, month );
                      }}
                      containerStyle={{height: 40}}
                      globalTextStyle={{fontSize: 22}}
                      style={{backgroundColor: '#fff', width: 80}}
                    />
                    }
                    <Text_cal>년</Text_cal>
                    {
                    <DropDownPickerComponent
                      items={months}
                      defaultValue={month}
                      itemStyle={{justifyContent: 'flex-start'}}
                      activeItemStyle={{display: 'none'}}
                      arrowStyle={{display: 'none'}}
                      onValueChange={(val) => {
                        setMonth(parseInt(val));
                        requestMonthClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num, year, val );
                      }}
                      containerStyle={{height: 40}}
                      style={{backgroundColor: '#fff', width: 60}}
                      globalTextStyle={{fontSize: 22}}
                    />
                    }
                    <Text_cal>월</Text_cal>
                  </Wrap_text>
                  <Touchable_next
                    onClick={() => {
                      console.log("Next - years : " + years + ", month : " + month);

                      let changeYear = year;
                      let changeMonth = month;

                      if (month == 12) {
                        changeYear = years[1].value;
                        changeMonth = year == years[1].value ? 12 : months[0].value; // 마지막 년도의 마지막 달이면 12월로 고정

                        setYear(changeYear);
                        setMonth(changeMonth); 
                      } else {
                        changeMonth = changeMonth + 1;
                        setMonth(changeMonth);
                      }

                      requestMonthClassList( globalData.loginData, childrenItems[Number(childrenChk)].childcare_num, changeYear, changeMonth );
                    }}>
                    <Img_calendar
                      //source={require('../img/calendar_right.png')}
                      src={calendar_rightImg}
                      resizeMode={'contain'}
                    />
                  </Touchable_next>
                </View_top>

                {
                  // 예약일정 있는지 체크
                  monthclassItems.length == 0 ? (
                    // 예약일정 없음
                    <Today_empty>
                      <Img_empty
                        //source={require('../img/empty2.png')}
                        src={empty2Img}
                        resizeMode={'contain'}
                      />
                      <Text_empty>오늘 예정된 프로그램이 없습니다.</Text_empty>
                    </Today_empty>
                  ) : (
                    // 예약일정 있음
                    <>
                      <MonthclassConferences items={monthclassItems} />
                      <div style={{height:30}}/>
                    </>
                  )
                }
              </Wrap>
            </Container>
          </div>
        </div>
        {/* 예약일정 팝업 */}
        {/*</Context.Provider>*/}
        </div>
      </Context.Provider>
      <CustomDrawerContent
        globalData={globalData}
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        {...props}/>
    </div>
  );
}
export default AFMainParentScreen
/************************************************************************************************************************************************
 * Style Start
 ************************************************************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  background-color: #fff;
  height:100%;
  position:relative;
  padding:0 16px;
  box-sizing:border-box;
  padding-bottom:20px;
`;

// 최상단 헤더 영역
const Layout_view_top = styled.div`
  background-color: #fff;
  height: 50px;
  text-align:center;
  line-height:50px;
  margin-bottom:6px;
`;

const Text_top_title = styled.span`
  font-size: 1.25em;
  font-weight: 400;
  color: #000;
`;

const TouchAble_btn_nav = styled.div`
  position: absolute;
  right:0px;
  top:0px;
  width:50px;
  height:50px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const Img_top_icon2 = styled.img`
  height: 24px;
  cursor:pointer;
`;

const Wrap = styled.div`
  padding-top: 0;
`;

// 로그인 유저정보 영역
const Layout_view_user = styled.div`
  position: relative;
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
`;

const Btn_child = styled.div`
  border:1px solid #ddd;
  border-radius:15px;
  width:49%;
  cursor:pointer;
  background:#f1f1f1;
  height:44px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;


  // 버튼 active style
  ${(props) =>
    props.childrenChk == props.index &&
    css`
      background-color: #fff;
    `}

  // 버튼 갯수에 따른 좌우간격 style
  ${(props) =>
    props.childLength != 1 &&
    css`
      width: ${98 / props.childLength + '%'};
    `}
`;

const Img_child_box = styled.div`
  width:40px;
  height:40px;
  border-radius:70%;
  overflow:hidden;
  box-sizing:border-box;
  margin-top:2px;
  border:1px solid #ddd;
  display:inline-block;
`;
const Img_child_logo = styled.img`
  width:100%;
  height: 100%;
  opacity: 0.3;

  // 버튼 active 로고 투명도 조절
  ${(props) =>
    props.childrenChk == props.index &&
    css`
      opacity: 1;
    `}
`;

const Text_child_name = styled.span`
  color: #888;
  font-size: 1em;
  font-weight: 500;
  margin-left:10px;
  vertical-align:top;

  // 아이가 네명일 경우.. 혹시나..
  ${(props) =>
    props.childLength > 3 &&
    css`
      font-size: 15px;
    `}

  // 버튼 active 글자 색상 
  ${(props) =>
    props.childrenChk == props.index &&
    css`
      color: #000;
    `}

  // 아이버튼이 한개일 경우, 로고와 글자 간격
  ${(props) =>
    props.childLength == 1 &&
    css`
      margin-left: ${Const.getRateWidth(10)}px;
    `}
`;

// 어린이집 프로필 영역
const Intro_profile = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top:12px;
  padding:6px 0;
`;

const Box_img_propfile = styled.div`
  width: 70px;
  height: 70px;
  border:1px solid #ccc;
  text-align:center;
  overflow:hidden;
  display:inline-block;
  border-radius:70%;
`;

const Img_profile = styled.img`
  width: 100%;
  height: 100%;
`;

const Box_text_profile = styled.div`
  width:calc(100% - 80px);
  display:inline-block;
  height:69px;
  padding:0 0 0 10px;
  box-sizing:border-box;
`;

const Text_school_name = styled.span`
  font-size: 1.15em;
  font-weight:500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:calc(100% - 70px);
  height:28px;
  line-height:28px;
  overflow:hidden;
`;

const Text_school_name_popup = styled.span`
  font-size: 1.15em;
  font-weight:500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:calc(100% - 70px);
  height:56px;
  line-height:56px;
  vertical-align:top;
`;

const Text_intro = styled.span`
  font-size:0.82em;
  display:inline-block;
  width:100%;
  color:#666;
`;

const Box_img_school = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${Const.getRateHeight(10)}px;
`;

const Img_school = styled.img`
  width: 32%;
  border-radius: 5px;
  height: 70px;
  max-width:120px;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px dotted #ddd;
`;

const Text_item_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  color: #000;
  display:block;
  margin-top:26px;
  margin-bottom:18px;
`;

// 오늘의 온라인 교실 영역
const Todayclass = styled.div`
  background-color: #fffbea;
  border-radius: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top:6px;
  display:flex;
  padding:10px 14px;
  box-sizing:border-box;
  border:1px solid rgba(0,0,0,.05);
  position:relative;

  ${(props) =>
    /* '예약' */
    ( props.status == ServerRequestManager.ROOM_STATUS_PREPARE || 
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION ) &&
    css`
      background-color: #fff;
    `}

  ${(props) =>
    /* '종료' */
    ( props.status == ServerRequestManager.ROOM_STATUS_CANCEL || 
    props.status == ServerRequestManager.ROOM_STATUS_END || 
    props.status == "" ) &&
    css`
      background-color: #f4f4f4;
    `}
`;

const Box_left = styled.div`
  width: 100%;
  line-height:24px;

  // '라이브'
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
    `}
`;

// 왼쪽 타이틀 + 시간 영역
const Box_left_top = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:303px;
`;

const Text_todayclass_title = styled.span`
  font-size: 0.91em;
  font-weight:500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  max-width:calc(100% - 160px);

  // '종료'
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}


  @media only screen and (max-width: 360px) {
    max-width:calc(100% - 130px);
  }
`;
const Text_todayclass_live = styled.div`  
  display:inline-block;
  margin-left:5px;
  vertical-align:top;
`;
const Img_live = styled.img`
  width:33px;
`;

const Box_left_btm = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height:14px;
`;

const Img_time = styled.img`
  width: 15px;
  height:15px;
`;

const Text_time = styled.span`
  font-size: 0.80em;
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;

  // '라이브' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
      color: ${Const.COLOR_PINK};
    `}
  // '대기중' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION &&
    css`
      color: ${Const.COLOR_GREEN};
    `}
  // '종료' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}
`;
const Text_tim_right = styled.span`
  font-size: 0.96em;
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;

  // '라이브' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
      color: ${Const.COLOR_PINK};
    `}
  // '대기중' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION &&
    css`
      color: ${Const.COLOR_GREEN};
    `}
  // '종료' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}
`;

const Box_right = styled.div`
  width: 70px;
  text-align:center;
  margin-right:-10px;
  height:43px;
  line-height:43px;
  position:absolute;
  right:20px;
  top:10px;

  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
    cursor:pointer;
    `}
`;

const Img_btn_live = styled.img`
  width: 70px;
`;

const Today_empty = styled.div`
  align-items: center;
  justify-content: center;
  display:block;
  text-align:center;
  padding:30px 0;
`;

const Img_empty = styled.img`
  width: ${Const.getRateWidth(150)}px;
`;

const Text_empty = styled.span`
  color: #aaa;
  display:block;
  text-align:center;
  margin-top:15px;
`;

// 예약일정 영역
const View_calendar_top = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const View_top = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display:flex;
  margin-bottom:20px;

`;

const Touchable_prev = styled.div`
  width: 40px;
  height: 40px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align:center;
  line-height:40px;
  cursor:pointer;
`;

const Touchable_next = styled.div`
  width: 40px;
  height: 40px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
  text-align:center;
  line-height:40px;
  cursor:pointer;
`;

const Wrap_text = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text_cal = styled.span`
  font-size: 20px;
  margin: 0 20px 0 10px;
`;

const Img_calendar = styled.img`
  width: ${Const.getRateWidth(12)}px;
`;

// 예약일정 내부 리스트박스
const Monthclass = styled.div`
  background-color: #fff;
  border-radius: 15px;
  border:1px solid #ddd;
  padding:0 8px;
  box-sizing:border-box;
  height:68px;
  margin-bottom:5px;
`;

const View_day = styled.div`
  width: 50px;
  height:50px;
  background-color: ${Const.COLOR_VIOLET};
  justify-content: center;
  align-items: center;
  border-radius: 70%;
  display:inline-block;
  text-align:center;
  line-height:48px;
  vertical-align:top;
  margin-top:6px;
`;

const Text_day = styled.span`
  color: #fff;
  font-size: 17px;
`;

const View_monthclass_comment = styled.div`
  padding-left: 10px;
  width:calc(100% - 50px);
  display:inline-block;
  padding-top:12px;
  box-sizing:border-box;
`;

const Text_monthclass_title = styled.span`
  font-size:0.91em;
  font-weight: 500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  max-width:100%;
  height:23px;
`;

const Text_violet = styled.span`
  font-size: 0.80;
  color: ${Const.COLOR_VIOLET};
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;
`;

// '추가정보입력 팝업' 영역

const Modal_contents = styled.div`
  z-index: 1;
  background-color: #fff;
  margin:0 auto;
  top:50%;
  left:50%;
  position:absolute;
  transform:translate(-50%, -50%);
  padding: ${Const.getRateWidth(15)}px ${Const.getRateWidth(15)}px;
  border-radius: 10px;
  width: 80%;
  height:90%;
  overflow-y:auto;
`;


// 팝업창 헤더 영역
const Modal_head = styled.div`
  position: relative;
`;

const Modal_head_top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${Const.getRateHeight(15)}px;
`;

const Img_info = styled.img`
  width: 39px;
  margin-right: 6px;
`;

const Modal_head_top_title = styled.span`
  font-size: 1.6em;
  font-weight:500;
  line-height:38px;
`;

const Text_head_btm = styled.span`
  font-size:0.82em;
  margin-top:10px;
  display:block;
  color:#666;

`;

const Touchable_Modal_close = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const Img_close = styled.img`
  width: ${Const.getRateWidth(20)}px;
`;

const Line_mid2 = styled.div`
  border-top-width: ${Const.BORDER_DEFAULT_WIDTH}px;
  border-color: ${Const.BORDER_DEFAULT_COLOR};
  width: 100%;
  margin-bottom: 26px;
`;

const Wrapper_modal = styled.div`
  padding:0 ${Const.getRateWidth(15)}px;
`;

// 팝업창 메인 컨텐츠 영역
const Modal_body_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  margin-bottom: 15px;
  display:block;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  
  ${(props) =>
    props.selectbox &&
    css`
    box-shadow:none;
    padding:0;

    `}
`;


const View_input_width = styled.div`
  display: inline-block;  
  width:220px;
`;

const View_input_area = styled.div`
    position:relative;
`;

const Input_common = styled.input`
  width: 100%;
  height: 50px;
  font-size: 15px;
  border:none;
`;

const View_select_wrap = styled.div`
   margin-top:8px;
`;

const Text_info = styled.span`
    font-size:0.82em;
    display:block;
    margin-top:10px;
    color:#666;
`;

const Container_school_name = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width:100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
`;


const Container_img_box = styled.div`
  display:inline-block;
  margin-right:10px;
`;

const Img_logo_small = styled.img`
  height: 40px;
  width:40px;
  margin-top:8px;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top:0px;
  right:0px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display:flex;
`;

const Btn_radio = styled.div`
`;

const Text_radio = styled.span`
  width: 60px;
  color: #fff;
  text-align: center;
  height:42px;
  line-height: 42px;
  border-radius: 50px;
  font-size: ${Const.BUTTON_FONT_SIZE}px;
  font-weight: 500;
  margin-left: ${Const.getRateWidth(3)}px;
  background-color: #b7b7b7;
  display:inline-block;

  // 남아 클릭 시
  ${(props) =>
    props.male && // Text_radio 태그 내부에 'male'이라는 속성을 갖고 있을 경우, 추가 css 작성
    css`
      // 태그에 넣은 male 속성을 가진 태그만 가져와서 별도의 css를 넣는다.
      background-color: ${({gender}) => {
        // useState에 담긴 gender 속성 내부 값을 가져온다.
        if (gender == 1) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}

  // 여아 클릭 시
  ${(props) =>
    props.female &&
    css`
      background-color: ${({gender}) => {
        if (gender == 2) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}
`;

const Wrapper_title_head = styled.div`
  position:relative;
`;

const Text_refresh = styled.span`
  position: absolute;
  top: -5px;
  right: 0;
  width: 40px;
  height: 40px;
  margin-top: 0 !important;
  text-align: center;
  line-height: 40px;
  font-size: 1.2em;
  color: #777676;
  cursor: pointer;
`;

/************************************************************************************************************************************************
 * Style end
 ************************************************************************************************************************************************/