import React from 'react';
//import {StyleSheet} from 'react-native';
import Const from '../../util/Const';
//import Shadow from 'shadows-rn'; // TODO 다른대안 필요
import styled, {css} from 'styled-components';

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
 function RNPickerSelectComponent(props) {

  return (
    <SelectCom style={props.parents_style} paddingLeft={props.paddingLeft}onChange={(e)=>{
      console.log("RNPickerSelectComponent onChange : " + e.target.value);
      props.onValueChange(e.target.value);
    }}
    value={props.value}>
      <Options items={props.items}/>
    </SelectCom>
  );/*
  return (
    <div>
      
    </div>
  );*/
}
export default RNPickerSelectComponent

function Options( {items} ) {
    const optionItems = items.map((item, index) =>{
        return(
            <OptionCom value={item.value} key={index}>
                {item.label}
            </OptionCom>
            );
        }
    );
    return optionItems;
}


const SelectCom = styled.select`
  width: 100%;
  height: 56px;
  font-size: 0.91em;
  border-radius:5px;
  border-color:#fff;
  color:#000;
  border:none;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  padding:0 7px;
  box-sizing:border-box;

  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: ${props.paddingLeft}px;
    `}
`;
const OptionCom = styled.option`
${(props) =>
  props.paddingLeft &&
  css`
  
  `}
`;
