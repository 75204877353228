import React, {useState, useEffect, useRef} from 'react';
//import {StatusBar, Keyboard, TouchableWithoutFeedback} from 'react-native';
//import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import Const from '../util/Const';
import Util from '../util/Util';
import Validation from '../util/Validation';
//import RNPickerSelect from 'react-native-picker-select';
import ServerRequestManager from '../util/ServerRequestManager';
import RNPickerSelectComponent from './component/RNPickerSelectComponent';
import styled, {css} from 'styled-components';
import ShadowComponent from './component/ShadowComponent';
import ButtonComponent from './component/ButtonComponent';
import TitleComponent from './component/TitleComponent';
import ShowPopupChildAddComponent from './component/popup/ShowPopupChildAddComponent';
import ShowPopupChildRemoveComponent from './component/popup/ShowPopupChildRemoveComponent';
import ShowPopupCodeComponent from './component/popup/ShowPopupCodeComponent';

import top_icon1_img from '../img/top_icon1.png'
import top_icon2_img from '../img/top_icon2.png'
import noImg from '../img/no_img.png'
import camera_img from '../img/camera.png'
import tree_img from '../img/tree.png'
import plusImg from '../img/plus.png'
import minusImg from '../img/minus.png'

const nickNameItems = [
  {label: '없음', value: 0},
  {label: '부모님', value: 1}
];
const birthItems = [
  {label: '2018년생', value: '2018년생'},
  {label: '2019년생', value: '2019년생'},
  {label: '2020년생', value: '2020년생'},
];
const groupItems = [
  {label: '햇님반1', value: '햇님반1'},
  {label: '햇님반2', value: '햇님반2'},
  {label: '햇님반3', value: '햇님반3'},
];

/**
 * 아이 데이터를 만들어 가져오는 메소드
 * @param {*} code 
 * @param {*} schoolName 
 * @param {*} class_data 
 * @returns 
 */
function getChildData( code, schoolName, class_data ) {
  let todayDate = new Date();
  let year = todayDate.getFullYear();
  let classList = Util.makeClassList(class_data)
  return {
    code:code,                    // 초대코드
    schoolName:schoolName,        // 어린이집 이름
    logo:tree_img,                   // 어린이집 로고
    name:"",                      // 아이 이름
    gender:Const.SEX_TYPE_MALE,   // 성별 => 1:남 , 2:여
    birthday:year,                // 생일년도
    classList:classList,          // 반 데이터 목록
    className:classList[0].value  // 내 아이 반 이름
  }
}


/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/
export default function AFMyPageScreen(props) {
  const [nameStr, setNameStr] = useState(''); // 가입자 이름
  const [nameInputStr, setNameInputStr] = useState(''); // 가입자 이름
  const [nickNameValue, setNickNameValue] = useState(String(nickNameItems[0].value)); // 호칭
  const [imgProfile, setImgProfile] = useState(noImg); // 추가정보 입력 프로필 이미지
  const [gender, setGender] = useState('female'); // 남아 = male, 여아 = female
  const [groupStr, setGroupStr] = useState( String(groupItems[0].value) ); // 반
  const [idStr, setIdStr] = useState('');  
  const [pwStr, setPwStr] = useState('');               // 비밀번호
  const [repwStr, setRePwStr] = useState('');           // 비밀번호 확인

  const [globalData, setGlobalData] = useState(null);       // 글로벌 데이터
  const [childCareData, setChildCareData] = useState(null); // 어린이집 데이터
  const [userData, setUserData] = useState(null);           // 계정 데이터

  const [childDataList, setChildDataList] = useState([]);
  const [childRemovePosition, setChildRemovePosition] = useState(-1); // '자녀정보삭제' 팝업창 show /hide 여부

  const [isVisibleModal, setIsVisibleModal] = useState(false);             // '현재기관에추가' 팝업창 show / hide 여부
  const [isVisibleCodeModal, setIsVisibleCodeModal] = useState(false);     // '초대코드입력' 팝업창 show / hide 여부
  const [inviteCode, setInviteCode] = useState(''); // 초대코드 6자리

  /* 이미지 관련 */
  const profileImgInputFile = useRef(null);
  const [imgProfileBase64, setImgProfileBase64] = useState(null);

  console.log('AFMyPageScreen');

  useEffect(() => {
    console.log("AFmanagementScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA );

    ServerRequestManager.requestChangePage( window, "AFMyPageScreen" );
    
  }, []);

  const IpcReceiver = (arg) => {
    console.log("AFmanagementScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        console.log("AFmanagementScreen IpcReceiver arg.data : " + JSON.stringify(arg.data) );
        setGlobalData(arg.data);

        setRowData(arg.data);
      }
    } 
  }
  
  const setRowData = ( gData ) => {
    let childCareDatas = gData.childCareData;

    if( childCareDatas != null && childCareDatas.length > 0 ) {
      let cCData = childCareDatas[0];
      setChildCareData(cCData);
    }    
    setIdStr(gData.loginData.id);
    setUserData(gData.userData);
    setNameStr(gData.userData.name);
    setNameInputStr(gData.userData.name);
    setNickNameValue(gData.userData.member_alias);
    makeChildData(gData);
    setImgProfile( Util.makeShowImageFromUrl(gData.userData.img_path, noImg));
  }

  const makeChildData = ( gData ) => {
    let childCareDatas = gData.childCareData;
    let childrenDatas = Util.makeChildrenListFromStr(gData.userData.children_data);

    let newChildrenItems = [];

    for( var i=0; i<childrenDatas.length; i++ ) {
      for( var j=0; j<childCareDatas.length; j++ ) {
        
        if( childrenDatas[i].childcare_num == childCareDatas[j].num ) {
          let classList = Util.makeClassList(childCareDatas[j].class_data)
          let logo = Util.makeShowImageFromUrl(childCareDatas[j].logo_path, tree_img);

          let item = {
            code:childCareDatas[j].parent_code,   // 초대코드
            schoolName:childCareDatas[j].name,    // 어린이집 이름
            logo:logo,                            // 어린이집 로고
            name:childrenDatas[i].name,           // 아이 이름
            gender:childrenDatas[i].gender,       // 성별 => 1:남 , 2:여
            birthday:childrenDatas[i].birthday,   // 생일년도
            classList:classList,                  // 반 데이터 목록
            className:childrenDatas[i].className // 내 아이 반 이름
          }
          newChildrenItems.push(item);
          break;
        }
      }
    }

    if( newChildrenItems.length == 0 ) {
      
      let todayDate = new Date();
      let year = todayDate.getFullYear();

      let logo = Util.makeShowImageFromUrl(childCareDatas[0].logo_path, tree_img);
      let classList = Util.makeClassList(childCareDatas[0].class_data)

      newChildrenItems.push({
        code:childCareDatas[0].parent_code, // 초대코드
        schoolName:childCareDatas[0].name,  // 어린이집 이름
        logo:logo,                          // 어린이집 로고
        name:"",                            // 아이 이름
        gender:Const.SEX_TYPE_MALE,         // 성별 => 1:남 , 2:여
        birthday:year,                      // 생일년도
        classList:classList,                // 반 데이터 목록
        className:classList[0].value        // 내 아이 반 이름
      });
    }

    setChildDataList(newChildrenItems);
  }

  const clickAddChildButton = () => {
    // alert('자녀정보입력 추가')
    setIsVisibleModal(true); // 팝업창 오픈
  };

  // 현재 기관으로 내 아이 추가
  const clickAddChildMyCenter = () => {
    // alert('현재 기관에 추가')

    var newList = [...childDataList];
    
    let childData = getChildData( globalData.childCareData[0].parent_code, globalData.childCareData[0].name, globalData.childCareData[0].class_data );
    childData.logo = Util.makeShowImageFromUrl( globalData.childCareData[0].logo_path, tree_img );
    newList.push(childData);
    setChildDataList(newList);

    Util.showToast('자녀정보입력 폼이 추가되었습니다.')
  };

  // 초대코드로 내 아이 추가 팝업창 => '확인'
  const clickCodeButton = () => {
    ServerRequestManager.requestInviteCode( (result, data) => {
      console.log("_requestInviteCode result result : " + result);
      console.log("_requestInviteCode result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        if( data.result_data.member_type === ServerRequestManager.MEMBER_TYPE_TEACHER ) {
          Util.showToast('해당코드는 학부모님 초대 코드가 아닙니다.')
          return;
        }
        var newList = [...childDataList];
        let childData = getChildData( inviteCode, data.result_data.name, data.result_data.class_data )
        childData.logo = Util.makeShowImageFromUrl( data.result_data.logo_path, tree_img );
        newList.push(childData);
        setChildDataList(newList);
        setInviteCode("");
      }
    }, inviteCode);
  };

  // 자녀정보삭제 팝업창 => '삭제'
  const clickChildRemove = () => {

    var newList = [...childDataList];
    newList.splice(childRemovePosition, 1);
    setChildDataList(newList);

    setChildRemovePosition(-1);
  };

  // 자녀정보삭제 팝업창 => '취소'
  const clickRemoveCancel = () => {
    setChildRemovePosition(-1);
  };

  const clickSaveButton = () => {

    console.log(`
      nameInputStr=${nameInputStr},
      nickNameValue=${nickNameValue},
      gender=${gender}
    `)

    let children_data = [];
    
    let CS_TYPE_ABLE = 0;
    let CS_TYPE_NO_HAVE_DATA = 1;
    let CS_TYPE_WRONG_CHILDREN_CARE = 2;

    let childSaveType = CS_TYPE_ABLE;
    if( childDataList != null && childDataList.length > 0 ) {
      for( var i=0; i<childDataList.length; i++ ) {
        let childData = childDataList[i];
        console.log("childData : " + JSON.stringify(childData));
        let rowChildData = {
          code    :childData.code,
          name    :childData.name,
          gender  :childData.gender == Const.SEX_TYPE_MALE?"남":"여",
          birthday:childData.birthday,
          class   :childData.className
        }
        children_data.push( rowChildData );

        if( childData.name == null || childData.name == "" ) { childSaveType = CS_TYPE_NO_HAVE_DATA; break; }
        if( childData.className == "없음" ) { childSaveType = CS_TYPE_WRONG_CHILDREN_CARE; break; }
      }
    }
    else {
      childSaveType = CS_TYPE_NO_HAVE_DATA;
    }

    if( childSaveType == CS_TYPE_NO_HAVE_DATA ) {
      Util.showToast("아이 정보를 입력하세요.");
      return;
    }

    if( childSaveType == CS_TYPE_WRONG_CHILDREN_CARE ) {
      Util.showToast("반 정보가 없는 어린이집은 아이추가가 불가능합니다. 어린이집에 확인해 주세요.");
      return;
    }
    let middleData = {children_data:children_data};

    let pw = null;
    if( pwStr != null && pwStr != "" ) {
      pw = pwStr;
      if( pw != repwStr ) {
        Validation.MsgCheckPasswordConfirm();
        return;
      }
    }

    let member_type = globalData.loginData.member_type;
    let id = globalData.loginData.id;
    let sess_id = globalData.loginData.sess_id;
    let member_img = imgProfileBase64;
    let member_name = nameInputStr;
    let member_alias = nickNameValue;

    ServerRequestManager.requestUpdateUserInfo( (result, data) => {
      console.log("requestUpdateUserInfo result result : " + result);
      console.log("requestUpdateUserInfo result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        Util.showToast("정보 수정 완료");

        var receiveUserInfo = false;
        var receiveChildcareInfo = false;
        var childcare_num = null;

        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

            receiveUserInfo = true;

            if( receiveChildcareInfo == true ) {
              props.history.goBack();
            }
          }
        }, id, sess_id);        

        // 어린이집 정보 요청
        ServerRequestManager.requestChildCareInfo((result, data) => {
          console.log("requestChildCareInfo result result : " + result);
          console.log("requestChildCareInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);
    
            receiveChildcareInfo = true;

            if( receiveUserInfo == true ) {
              props.history.goBack();
            }
          }
        }, id, sess_id, childcare_num);
      }
    }, member_type, id, sess_id, pw, member_img, member_name, member_alias, middleData);
  };

  return (
      <div>
        <div>
          <Container>
            <TitleComponent history={props.history} title={'내 정보 관리'} />
            {/* 프로필 영역 
            <Layout_view_top>
              <TouchAble_btn_goback onClick={() => props.history.goBack()}>
                <Img_top_icon2
                  src={top_icon1_img}
                  resizeMode={'contain'}
                />
              </TouchAble_btn_goback>
              <Text_top_title>내 정보 관리</Text_top_title>
            </Layout_view_top>*/}
            {/* End - 프로필 영역 */}

            <Wrap>
              <View_drawer_head>
                <TouchAble_profile_img onClick={() => {profileImgInputFile.current.click();}}>
                  <Profile>
                    <Img_profile src={imgProfile} resizeMode={'contain'} />
                  </Profile>
                  <Img_camera
                    src={camera_img}
                    resizeMode={'contain'}
                    onClick={()=>{}}
                  />
                </TouchAble_profile_img>

                <Wrap_info>
                  <Wrap_info_top>
                    <Text_name>{nameStr}</Text_name>
                  </Wrap_info_top>
                  <Text_email>{idStr}</Text_email>
                </Wrap_info>
              </View_drawer_head>

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    value={nameInputStr}
                    placeholder="가입자 이름을 입력해주세요"
                    placeholderTextColor={nameInputStr == '' ? '#bbb' : '#000'}
                    onChange={(e) => setNameInputStr(e.target.value)}
                    returnKeyType="next"
                  />
                </View_input_wrap>
              </ShadowComponent>

              <ShadowComponent>
                <View_select_wrap selectbox>
                  <RNPickerSelectComponent
                    placeholder={{
                      label:
                        nickNameValue == '' ? '호칭을 선택해주세요' : nickNameValue,
                      value: nickNameValue,
                      color: '#ff4784',
                    }}
                    items={nickNameItems}
                    onValueChange={(val) => {
                      setNickNameValue(val);
                    }}
                    value={nickNameValue}
                    style={{
                      placeholder: {color: nickNameValue == '' ? '#bbb' : '#000'},
                    }}
                  />
                </View_select_wrap>
              </ShadowComponent>
              <Text_info>
                ※ 온라인 교실 참여 시 다른 사람에게 표시됩니다.
              </Text_info>
              
              <div style={{height: 10}} />

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="비밀번호"
                    placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    onChange={(e) => setPwStr(e.target.value)}
                    returnKeyType="next"
                    type="password"
                    /*onSubmitEditing={() => {
                      rePwTextInput.focus();
                    }}*/// TODO Enter 입력시로 변경
                    value={pwStr}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="비밀번호 확인"
                    placeholderTextColor={repwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    onChange={(e) => setRePwStr(e.target.value)}
                    returnKeyType="done"
                    type="password"
                    /*onSubmitEditing={() => {
                      // nameTextInput.focus();
                    }}*/ // TODO Enter 입력시로 변경
                    value={repwStr}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <Line_mid></Line_mid> 


              <Text_mid_wrap>
                <Text_mid_first>내 아이 정보</Text_mid_first>
                <Btn_img_plus onClick={() => clickAddChildButton()}>
                  <Img_plus
                    src={plusImg}
                    resizeMode={'contain'}
                  />
                </Btn_img_plus>
              </Text_mid_wrap>
              
              {/* 아이 입력 리스트 */}
              <ChildInfoList 
                childDataList={childDataList}
                setChildDataList={setChildDataList}
                setChildRemovePosition={setChildRemovePosition}/>

              <Wrap_text_info>
                <Text_sign>※</Text_sign>
                <Text_info>
                  아이 정보 입력 시, 내 아이의 학급에 맞는 온라인 교실을
                  자동으로 보여주고, 맞춤형 정보를 추천 받을 수 있습니다.
                </Text_info>
              </Wrap_text_info>

              <Margin_box />

              <ButtonComponent text="저장" buttonClick={clickSaveButton} />

              {/* 자녀정보입력 폼추가 클릭시 뜨는 첫번째 팝업창 */}
              <ShowPopupChildAddComponent
                isVisibleModal={isVisibleModal}
                setIsVisibleModal={setIsVisibleModal}
                setIsVisibleCodeModal={setIsVisibleCodeModal}
                clickAddChildMyCenter={clickAddChildMyCenter}
              />

              {/* '초대코드 입력' 팝업창 */}
              <ShowPopupCodeComponent
                setInviteCode={setInviteCode}
                isVisibleCodeModal={isVisibleCodeModal}
                setIsVisibleCodeModal={setIsVisibleCodeModal}
                clickCodeButton={clickCodeButton}
              />

              {/* '자녀정보삭제' 팝업창 */}
              <ShowPopupChildRemoveComponent
                childRemovePosition={childRemovePosition}
                setChildRemovePosition={setChildRemovePosition}
                clickChildRemove={clickChildRemove}
                clickRemoveCancel={clickRemoveCancel}
              />
            </Wrap>
            <input type='file' id='file' ref={profileImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              setImgProfile(previewData);
              setImgProfileBase64(base64Data);
            });
          }}/>
          </Container>
        </div>
      </div>
  );
}

/** 자녀 입력 리스트 */
function ChildInfoList({ childDataList, setChildDataList, setChildRemovePosition}) {

  const Btn_img_minus_component = ({index}) =>
    childDataList.length > 1 && ( // 폼이 한개면 삭제버튼 안보이게 설정
      <Btn_img_minus
        onClick={() => {
          setChildRemovePosition(index);
        }}>
        <Img_minus src={minusImg} resizeMode={'contain'} />
      </Btn_img_minus>
    );


  const listItems = childDataList.map((child, index) => {

    // 테스트용 데이터
    if( child.schoolName == "" ) child.schoolName = "어린이집";

    return (
      <Container_child_info key={index} index={index}>
        <Container_school_name>
          <Img_logo_box>
            <Img_logo_small src={child.logo} resizeMode={'contain'} />
          </Img_logo_box>
          <Text_school_name>{child.schoolName}</Text_school_name>
          <Btn_img_minus_component index={index}/>
        </Container_school_name>

        <ShadowComponent>
          <View_input_wrap>
            <Input_common
              placeholder="아이 이름을 입력해주세요"
              onChange={(e) => {
                let newList = [...childDataList];
                newList[index].name = e.target.value;
                setChildDataList(newList);
              }}
              value={childDataList[index].name}
            />
            <View_wrap_radio>
              <Btn_radio
                onClick={() => {
                  let newList = [...childDataList];
                  newList[index].gender = Const.SEX_TYPE_MALE;
                  setChildDataList(newList);
              }}>
                <Text_radio gender={childDataList[index].gender} male>남아</Text_radio>
              </Btn_radio>
              <Btn_radio
                onClick={() => {
                  let newList = [...childDataList];
                  newList[index].gender = Const.SEX_TYPE_FEMALE;
                  setChildDataList(newList);
              }}>
                <Text_radio gender={childDataList[index].gender} female>여아</Text_radio>
              </Btn_radio>
            </View_wrap_radio>
          </View_input_wrap>
        </ShadowComponent>

        <ShadowComponent>
          <View_input_wrap selectbox>
            <RNPickerSelectComponent
              items={Util.getBirthItems()}
              value={childDataList[index].birthday}
              onValueChange={(val) => {
                let newList = [...childDataList];
                newList[index].birthday = val;
                setChildDataList(newList);
              }}
            />
          </View_input_wrap>
        </ShadowComponent>
        <ShadowComponent>
          <View_input_wrap selectbox>
            <RNPickerSelectComponent
              value={childDataList[index].className}
              items={childDataList[index].classList}
              onValueChange={(val) => {
                console.log("change val : " + val);
                let newList = [...childDataList];
                newList[index].className = val;
                setChildDataList(newList);
              }}
            />
          </View_input_wrap>
        </ShadowComponent>
      </Container_child_info>
    );
  });
  return listItems;
};

/************************************************************************************************************************************************
 * Style Start
 ************************************************************************************************************************************************/
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  font-size:16px;
  padding-bottom:20px;
`;

const Wrap = styled.div`
  padding: 0 ${Const.getRateWidth(20)}px;
`;

// 최상단 헤더 영역

// 프로필 영역
const View_drawer_head = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 96px;
  padding:20px 0;
`;

const Wrap_info = styled.div`
  width: 73%;
  padding-left: 20px;
`;

const Wrap_info_top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

const TouchAble_profile_img = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${Const.getRateWidth(100)}px;
`;

const Profile = styled.div`
  border-radius:70%;
  width:100px;
  height:100px;
  border:1px solid #ddd;
  overflow:hidden;
`;

const Img_profile = styled.img`
  width: 100%;
  height: 100%;
`;

const Img_camera = styled.img`
  position: absolute;
  height: 33px;
  right:0px;
  bottom:0px;
  margin:0 -3px;
  cursor:pointer;
`;

const Text_name = styled.span`
  color: #000;
  font-size: 1.24em;
  font-weight: 500;
  margin-bottom:8px;
  max-width:calc(100% - 20px);
`;

const Text_email = styled.span`
  color: #000;
  font-size: 17px;
  font-weight: 100;
  margin-top: 5px;
  display:inline-block;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width:calc(100% - 50px);
`;

const Text_school_name = styled.span`
  font-size: 1.05em;
  font-weight: 500;
  margin-left:10px;
  box-sizing:border-box;
  vertical-align:top;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  position:relative;
  margin-bottom:10px;

  ${(props) =>
    props.selectbox &&
    css`
      border:none;
      box-shadow:none;
    `}
`;

const Input_common = styled.input`
  width: 100%;
  font-size: 0.92em;
  border:none;
  padding:0 7px;
  box-sizing:border-box;
  border-radius:5px;
`;

const View_select_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  position:relative;
  margin-bottom:10px;

  ${(props) =>
    props.selectbox &&
    css`
      box-shadow:none;
      padding:0;
    `}
`;


const Text_mid_wrap = styled.div`
  position:relative;
  color: #000;
  display:block;
  margin-top:26px;
  margin-bottom:18px;

`;


const Text_mid_first = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  display:block;
`;


const Container_child_info = styled.div`
  border:2px dotted #ccc;
  margin-top:12px;
  border-radius:8px;
  padding:20px 14px 10px;
  box-sizing:border-box;

`;

const Btn_img_plus = styled.div`
  z-index: 1;
  position: absolute;
  right:0;
  top:10px;
  width:51px;
  height:45px;
  text-align:center;
  padding-top:10px;
  box-sizing:border-box;
  margin:-16px -5px 0 0;
`;

const Img_plus = styled.img`
  height: 20px;
  cursor:pointer;
`;

const Btn_img_minus = styled.div`
  position: absolute;
  right:10px;
  top:0;
  width:37px;
  height:50px;
  margin:-3px -2px 0 0;
  text-align:center;
  padding-top:11px;
  box-sizing:border-box;
  cursor:pointer;
`;

const Img_minus = styled.img`
  width:30px;
  height:30px;
`;

const Text_info = styled.span`
  margin-top: 10px;
  display:block;
  font-size:0.82em;
`;

const Line_Mid = styled.div`
  border-top-width: ${Const.BORDER_DEFAULT_WIDTH}px;
  border-color: ${Const.BORDER_DEFAULT_COLOR};
  margin: ${Const.getRateHeight(30)}px 0;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px dotted #ddd;
`;

const Text_subTitle = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  color: #000;
  display:block;
  margin-top:26px;
  margin-bottom:18px;
`;

const Container_school_name = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height:56px;
  margin-bottom:10px;
  line-height:56px;
`;

const Img_logo_box = styled.div`
  width:50px;
  height:50px;
  border:1px solid #ddd;
  border-radius:70%;
  overflow:hidden;
  display:inline-block;
  vertical-align:top;
  margin-top:1px;
`;

const Img_logo_small = styled.img`
  height: 100%;
  width: 100%;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
`;

const Btn_radio = styled.div`
  top: 0px;
  right: 0px;
  display:inline-block;
  cursor:pointer;
`;

const Text_radio = styled.span`
  width: 60px;
  color: #fff;
  text-align: center;
  height:42px;
  line-height: 42px;
  border-radius: 50px;
  font-size: ${Const.BUTTON_FONT_SIZE}px;
  font-weight: 500;
  margin-left: 3px;
  background-color: #b7b7b7;
  display:inline-block;

  // 남아 클릭 시
  ${(props) =>
    props.male &&
    css`
      // 태그에 넣은 male 속성을 가진 태그만 가져와서 별도의 css를 넣는다.
      background-color: ${({gender}) => {
        // useState에 담긴 gender 속성 내부 값을 가져온다.
        if (gender == Const.SEX_TYPE_MALE) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}

  // 여아 클릭 시
    ${(props) =>
    props.female &&
    css`
      background-color: ${({gender}) => {
        if (gender == Const.SEX_TYPE_FEMALE) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}
`;

const Margin_box = styled.div`
  margin-bottom: ${Const.getRateHeight(35)}px;
`;

const Wrap_text_info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Text_sign = styled.span`
  padding-top: ${Const.getRateHeight(10)}px;
  padding-right: ${Const.getRateWidth(5)}px;
`;
/************************************************************************************************************************************************
 * Style end
 ************************************************************************************************************************************************/
