import React from 'react';
//import {StyleSheet} from 'react-native';
import Const from '../../util/Const';
//import Shadow from 'shadows-rn'; // TODO 다른대안 필요
import styled from 'styled-components';

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
 function DropDownPickerComponent(props) {

  return (
    <Select__ 
      style={props.parents_style} 
      onChange={(e)=>{
        console.log("DropDownPickerComponent onChange : " + e.target.value);
        props.onValueChange(e.target.value);
      }}
      value={props.defaultValue}
    >
      {options(props)}
    </Select__>
  );
}
export default DropDownPickerComponent

function options( props ) {
    const optionItems = props.items.map((item, index) =>{
        return(
            <option key={index} value={item.value}>
                {item.value}
            </option>
            );
        }
    );
    return optionItems;
}

const Select__ = styled.select`
  padding:6px 10px;
  box-sizing:border-box;
  font-size:0.91em;
  border:1px solid #ccc;
  border-radius:3px;
`;