import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
/* 
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core';
const theme = unstable_createMuiStrictModeTheme();
 */
ReactDOM.render(
  // <ThemeProvider theme={theme}>
  /* <React.StrictMode> */ // 에러때문에 주석 -> error msg : findDOMNode is deprecated in StrictMode.
  <App />,
  // </React.StrictMode>
  // </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
