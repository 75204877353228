import logo from './logo.svg';
import React, {useEffect, useState} from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Root from "./index_before2.js";
import Util from './util/Util';
import Const from './util/Const';

import HomeScreen from "./screen/HomeScreen";                     // 홈화면 (어플 시작)
import BFLoginScreen from "./screen/BFLoginScreen";               // 로그인 화면
import BFSearchPwdScreen from "./screen/BFSearchPwdScreen";       // 비밀번호 찾기 화면
import BFSelectSignupScreen from "./screen/BFSelectSignupScreen"; // 회원가입 방법 선택 화면
import BFSignupParentScreen from "./screen/BFSignupParentScreen"; // 학부모 회원가입 화면
import BFSignupSchoolScreen from "./screen/BFSignupSchoolScreen"; // 원장 회원가입 화면

import AFMainSchoolScreen from "./screen/AFMainSchoolScreen";     // 학부모 메인화면
import AFMainParentScreen from "./screen/AFMainParentScreen";     // 선생님 메인화면
import AFOpenClassScreen from "./screen/AFOpenClassScreen";       // 온라인 교실 개설 화면
import AFMyPageScreen from "./screen/AFMyPageScreen";             // 내 정보 화면
import AFSettingScreen from "./screen/AFSettingScreen";           // 알림 설정 화면
import AFmanagementScreen from "./screen/AFmanagementScreen";     // 유아 교육 기관 관리 화면

import styled from 'styled-components';
import firebase from 'firebase'; //firebase모듈을 import해줘야 합니다.

import GlobalStyles from './css/GlobalStyles';
import { TransitionGroup, CSSTransition } from "react-transition-group"; // 라우터 화면 이동간 animation(transition) 효과

/***********************************************************************
 * 
 * FireBase 관련
 * 
 **********************************************************************/
/*
 import { ipcRenderer } from 'electron';
 import {
  START_NOTIFICATION_SERVICE,
  NOTIFICATION_SERVICE_STARTED,
  NOTIFICATION_SERVICE_ERROR,
  NOTIFICATION_RECEIVED as ON_NOTIFICATION_RECEIVED,
  TOKEN_UPDATED,
} from 'electron-fcm-push-receiver/src/constants';
 
const senderId = "415885944158";
// Listen for service successfully started
ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
  console.log("NOTIFICATION_SERVICE_STARTED token" + token);
}// do something
);
// Handle notification errors
ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
  console.log("NOTIFICATION_SERVICE_ERROR error" + error);
}// do something
);
// Send FCM token to backend
ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
  console.log("TOKEN_UPDATED token" + token);
}// Send token
);
// Display notification
ipcRenderer.on(ON_NOTIFICATION_RECEIVED, (_, notification) => {
  console.log("ON_NOTIFICATION_RECEIVED notification" + notification);
}// display notification
);
// Start service
ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId);*/



function initFcm() {
  const { ipcRenderer } = window.require ('electron')
  console.log("initFcm");

  const {
    START_NOTIFICATION_SERVICE,
    NOTIFICATION_SERVICE_STARTED,
    NOTIFICATION_SERVICE_ERROR,
    NOTIFICATION_RECEIVED,
    TOKEN_UPDATED,
  } = window.require ('electron-push-receiver/src/constants')
  
  // Listen for service successfully started
  ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
    console.log('service successfully started : ', token)

    Util.setGlobalData(window, Const.GLOBAL_DATA_TYPE__FCM_TOKEN, token );
  })
  
  // Handle notification errors
  ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
    console.log('notification error', error)
  })
  
  // Send FCM token to backend
  ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
    console.log('token updated', token)
    
    Util.setGlobalData(window, Const.GLOBAL_DATA_TYPE__FCM_TOKEN, token );
  })
  
  // Display notification
  ipcRenderer.on(NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
    // check to see if payload contains a body string, if it doesn't consider it a silent push
    if (serverNotificationPayload.notification.body){
      // payload has a body, so show it to the user
      console.log('display notification', serverNotificationPayload)
      let myNotification = new Notification(serverNotificationPayload.notification.title, {
        body: serverNotificationPayload.notification.body
      })
      
      myNotification.onclick = () => {
        console.log('Notification clicked')
      }  
    } else {
      // payload has no body, so consider it silent (and just consider the data portion)
      console.log('do something with the key/value pairs in the data', serverNotificationPayload.data)
    }
  })
  
  // Start service
  const senderId = '254017446964' // <-- replace with FCM sender ID from FCM web admin under Settings->Cloud Messaging
  console.log('starting service and registering a client')
  ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId)
}








 /*
 const config  = {
  apiKey: "AIzaSyBAgcwchCnCYdxMFPHySmCMN-7MgDw6SFE",
  authDomain: "tv-fcm-48a10.firebaseapp.com",
  databaseURL: "https://tv-fcm-48a10.firebaseio.com",
  projectId: "tv-fcm-48a10",
  storageBucket: "tv-fcm-48a10.appspot.com",
  messagingSenderId: "415885944158",
  254017446964
  appId: "1:415885944158:web:9f1a2784e47895085cdbcf",
  measurementId: "G-7K9NRQ39MZ"
};
firebase.initializeApp(config); 
const messaging = firebase.messaging();
//허가를 요청합니다!
messaging.requestPermission()
.then(function() {
	console.log('허가!');
  return messaging.getToken(); //토큰을 받는 함수를 추가!
})
.then(function(token) {
	console.log(token); //토큰을 출력!
})
.catch(function(err) {
	console.log('fcm에러 : ', err);
})
messaging.onMessage(function(payload){
	console.log(payload.notification.title);
	console.log(payload.notification.body);
})*/

function getDefaultGlobalData() {
  const gData = {
    codeCheckResult:null,   // 코드확인 결과
    loginData:null,         // 로그인 데이터
    userData:null,          // 내 정보 데이터
    childCareData:null,     // 어린이집 데이터
    fcmToken:null           // FCM 토큰
  }
  return gData;
}

let globalData = null;

function App() {
  //const [globalData, setGlobalData] = useState(getDefaultGlobalData());

  //Util.callIpcReceiver(arg); 
  console.log("App!!!!!");
  useEffect(() => {
    /*console.log("window.location.href : " + window.location.href);
    console.log("indexOf : " + window.location.href.indexOf("admin"));
    if( window.location.href.indexOf("admin") > -1 ) {
      console.log("have");
    }*/
      console.log("App useEffect!!!!!");

      globalData = getDefaultGlobalData();

      

      // initFcm();
      
      /* const { ipcRenderer } = window.require("electron");
      ipcRenderer.on('ipc_window', (event, arg) => { // 통신 receiver 등록
        Util.callIpcReceiver(arg); 
        //receiver(arg);
      }); */
      Util.setGlobalDataHandler(globalDataHandler);
  }, []);

  const globalDataHandler = (data) => {
    if( data.data_type == Const.IPC_DATA_TYPE_SET_DATA || data.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
        controllGlobalData(data);
    }
  }

  
  /**
   * 글로벌 데이터 동작
   * @param {*} arg 
   */
  function controllGlobalData( arg ) {
      // 데이터 설정
      if( arg.data_type == Const.IPC_DATA_TYPE_SET_DATA ) {
          if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
              //setGlobalData(getDefaultGlobalData());
              globalData = getDefaultGlobalData();
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT ) {
              
            globalData.codeCheckResult = arg.data;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__LOGIN_DATA ) {
            globalData.loginData = arg.data;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__USER_DATA ) {
            globalData.userData = arg.data;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA ) {
            globalData.childCareData = arg.data;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__FCM_TOKEN ) {
            globalData.fcmToken = arg.data;
          }
      }
      // 데이터 가져오기
      else if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
          var sendData = {
              data_type : arg.data_type,
              global_data_type : arg.global_data_type
          }

          if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
              sendData["data"] = globalData;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT ) {
              sendData["data"] = globalData.codeCheckResult;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__LOGIN_DATA ) {
              sendData["data"] = globalData.loginData;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__USER_DATA ) {
              sendData["data"] = globalData.userData;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA ) {
              sendData["data"] = globalData.childCareData;
          }
          else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__FCM_TOKEN ) {
              sendData["data"] = globalData.fcmToken;
          }
          
          //mainWindow.send(Const.IPC_CHANNEL_WINDOW, sendData );

          Util.callIpcReceiver(sendData);
      }
  }

  /**
   * 글로벌 데이터 컨트롤 함수
   * @param {*} controllType 
   * @param {*} data_type 
   * @param {*} data 
   * @returns 
   */



  return (
    <BrowserRouter>
      <GlobalStyles />
      <MiddleDiv>
        <Route
          render={({ location }) => {
            // console.log(location);
            return (
              <TransitionGroup>
                <CSSTransition key={location.key} timeout={250} classNames="fade">
                  <Switch location={location}>
                    <Route exact path="/"               render={(props) => <HomeScreen {...props} />}/>
                    <Route path="/BFLoginScreen"        render={(props) => <BFLoginScreen {...props} />}/>
                    <Route path="/BFSearchPwdScreen"    render={(props) => <BFSearchPwdScreen {...props} />}/>
                    <Route path="/BFSelectSignupScreen" render={(props) => <BFSelectSignupScreen {...props} />}/>
                    <Route path="/BFSignupParentScreen" render={(props) => <BFSignupParentScreen {...props} />}/>
                    <Route path="/BFSignupSchoolScreen" render={(props) => <BFSignupSchoolScreen {...props} />}/>
                    <Route path="/AFMainParentScreen"   render={(props) => <AFMainParentScreen {...props} />}/>
                    <Route path="/AFMainSchoolScreen"   render={(props) => <AFMainSchoolScreen {...props} />}/>
                    <Route path="/AFOpenClassScreen"    render={(props) => <AFOpenClassScreen {...props} />}/>    
                    <Route path="/AFMyPageScreen"       render={(props) => <AFMyPageScreen {...props} />}/>    
                    <Route path="/AFSettingScreen"      render={(props) => <AFSettingScreen {...props} />}/>    
                    <Route path="/AFmanagementScreen"   render={(props) => <AFmanagementScreen {...props} />}/>   
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            );
          }}>
        </Route>
      </MiddleDiv>
    </BrowserRouter>
  );
}

export default App;

const MiddleDiv = styled.div`
  height:100%;
`;
