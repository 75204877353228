import React, {useEffect, useState} from 'react';
import Const from '../util/Const';
import styled, {css, keyframes} from 'styled-components';
//import {ScrollView} from 'react-native-gesture-handler';
import ServerRequestManager from '../util/ServerRequestManager';

import close_wh_img from '../img/close_wh.png'
import tree_img from '../img/tree.png'
import noImg from '../img/no_img.png'
import mu1_img from '../img/mu1.png'
import mu2_img from '../img/mu2.png'
import mu3_img from '../img/mu3.png'
import bullet4_img from '../img/bullet4.png'
import Util from '../util/Util';
import ModalComponent from './component/ModalComponent';



const img_bullet = bullet4_img;

const NavLinkList = ({menuList, props}) => {
  const listItems = menuList.map((items, index) => (
    <Touchable_naviLink
      onClick={() => {
        //props.history.navigate(items.componentName)

        if( index == 2 ) {
          if( props.globalData && props.globalData.loginData ) {
            var id = props.globalData.loginData.id;
            var sess_id = props.globalData.loginData.sess_id;

            ServerRequestManager.requestLogout( (result, data) => {
              console.log("requestLogout result result : " + result);
              console.log("requestLogout result data : " + JSON.stringify(data));
              
              if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                Util.setGlobalData(window, Const.GLOBAL_DATA_TYPE__ALL_DATA, null);
                
                console.log("requestSignUp props.history.length : " + props.history.length);
                //props.history.go(1);

                props.history.replace("/");

                // 웹뷰 메시지 전송
                let userData = {
                  req_type:'logout',
                  req_data:{
                    id:id,
                  }
                }
                ServerRequestManager.requestWebViewPostMessage( window, userData );
              }
            }, id, sess_id );
          }
          else {

          }
        }
        else {
          props.history.push({
            pathname:items.componentName
          })
        }
      }}
      key={index}>
      <View_routeName>
        <Img_icon src={items.iconUrl} resizeMode={'contain'} />
        <Text_routeName>{items.title}</Text_routeName>
      </View_routeName>
      <Img_bullet src={img_bullet} resizeMode={'contain'} />
    </Touchable_naviLink>
  ));
  return listItems;
};

/************************************************************************************
 *
 * drawer 컨텐츠 화면
 *
 *************************************************************************************/
export default function CustomDrawerContent(props) {
  //const [menuList, setMenuList] = useState([]);

  const menuList = [
    {
      idx: 0,
      title: '내 정보 관리',
      componentName: '/AFMyPageScreen',
      iconUrl: mu1_img,
    },
    {
      idx: 1,
      title: '알림설정',
      componentName: '/AFSettingScreen',
      iconUrl: mu2_img,
    },
    {
      idx: 2,
      title: '로그아웃',
      componentName: '/BFCodeScreen',
      iconUrl: mu3_img,
    },
  ];

  if( props.globalData ) {
    if( props.globalData.loginData.member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      menuList[0].title = "유아 교육 기관 관리";
      menuList[0].componentName = "/AFmanagementScreen";
    }
  }

  useEffect(() => {
    console.log("CustomDrawerContent useEffect");
  }, []);
/* 
  const Logout = () => {
    alert(logout);
  };
 */
  const getNickName = () => {
    if( props.globalData ) {
      if( props.globalData.userData.member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
        if( props.globalData.userData.member_alias == ServerRequestManager.TEACHER_TYPE_PRESIDENT ) {
          return "원장선생님";
        }
        else if( props.globalData.userData.member_alias == ServerRequestManager.TEACHER_TYPE_TEACHER ) {
          return "선생님";
          
        }
        return ".";
      }
      else {
        if( props.globalData.userData.member_alias == ServerRequestManager.PARENTS_TYPE_PARENTS ) {
          return "학부모님";
        }
        return ".";
      }
    }
    return ".";
  }

  return (
    <ModalComponent
      isVisible={props.isOpenMenu}
      backdropOpacity={0.6}
      backdropTransitionInTiming={300}
      backdropTransitionOutTiming={10}>
      <View_drawer_wrap>
        {/* 프로필 영역 */}
        <View_drawer_head>
          <Touchable_close_btn onClick={() => props.setIsOpenMenu(false)}>
            <Img_close_wh
              src={close_wh_img}
            />
          </Touchable_close_btn>

          <Wrap_img_profile>
            <Img_profile
              src={Util.makeShowImageFromUrl(props.globalData?props.globalData.userData.img_path:"", noImg)}
            />
          </Wrap_img_profile>

          <Wrap_info>
            <Wrap_info_top>
              <Text_name>{props.globalData&&props.globalData.userData.name?props.globalData.userData.name:"이름없음"}</Text_name>
              <Text_job>{getNickName()}
              </Text_job>
            </Wrap_info_top>
            <Line_mid></Line_mid> 
            <Text_email>{props.globalData?props.globalData.loginData.id:""}</Text_email>
          </Wrap_info>
        </View_drawer_head>
        {/* End - 프로필 영역 */}

        {/* 내비게이션 링크 영역 */}
        <View_drawer_body>
          <NavLinkList menuList={menuList} props={props} />
        </View_drawer_body>
        {/* End - 내비게이션 링크 영역 */}
      </View_drawer_wrap>
    </ModalComponent>
  );
}

/************************************************************************************************************************************************
 * Style Start
 ************************************************************************************************************************************************/
/**
 * start - animation
 */
const aniSliding = keyframes`
  0% {
    left:100%;
  }
  100% {
    left:10%;
  }
`;
/**
 * End - animation
 */

const View_drawer_wrap = styled.div`  
  display:block;
  width:90%;
  position:absolute;
  right:0;
  top:0;
  height:100%;
  background:#fff;

  animation:${aniSliding} 0.4s ease-in-out;
`;

// 프로필 영역
const View_drawer_head = styled.div`
  width: 100%;
  height: 100px;
  padding:20px;
  background: #ff4785;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const View_drawer_body = styled.div`
        
`;

const Touchable_close_btn = styled.div`
  position: absolute;
  right: 40px;
  top:0;
  width:50px;
  height:50px;
  justify-content:flex-start;
  align-items:flex-end;
  text-align:center;
  line-height:40px;
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const Img_close_wh = styled.img`
  width: 16px;
`;

const Wrap_img_profile = styled.div`
  width: 70px;
  height:70px;
  background: #fff;
  border-radius: 70%;
  overflow:hidden;
`;

const Img_profile = styled.img`
  width: 100%;
  height: 100%;
  object-fit:cover;
`;

const Wrap_info = styled.div`
  margin-left: 10px;
  width: calc(100% - 80px);
  margin-bottom:4px;
`;

const Wrap_info_top = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
`;

const Text_name = styled.span`
  color: #fff;
  font-size: 1.15em;
`;

const Text_job = styled.span`
  font-size: 0.77em;
  color: rgba(255,255,255,.7);
  margin-left:6px;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:8px;
  margin-bottom:6px;
  border-top:1px solid #f43474;
  border-bottom:1px solid #ff578f;
  width:200px;
`;

const Text_email = styled.span`
  color: rgba(255,255,255,.7);
  font-size: 0.82em;
`;


const Touchable_naviLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding:4px 15px 4px 25px;
  border-bottom:1px solid #eee;
  box-sizing:border-box;
  cursor:pointer;
`;

const View_routeName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:100%;
  height:100%;
`;

const Img_icon = styled.img`
  width:30px;
  margin-right:10px;
`;

const Text_routeName = styled.span`
  font-size: 0.92em;  
`;

const Img_bullet = styled.img`
  width: 11px;
`;
/************************************************************************************************************************************************
 * Style End
 ************************************************************************************************************************************************/
