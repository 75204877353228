
var Const = new function() {
  this.TEST = "test";
  this.HOME_SCREEN_TYPE_INTRO = 0;
  this.HOME_SCREEN_TYPE_BEFORE_LOGIN = 1;
  this.HOME_SCREEN_TYPE_AFTER_LOGIN = 2;

  this.ROOM_LIST_REFRESH_DELAY = 10 * 1000;

  this.IPC_CHANNEL_WINDOW = "ipc_window";
  this.IPC_DATA_TYPE_SIZE_CHANGE = "ipc_type_size_change";

  this.IPC_DATA_TYPE_RESIZE = "ipc_type_resize";
  this.IPC_DATA_TYPE_SET_DATA = "ipc_type_set_data";
  this.IPC_DATA_TYPE_SET_DATA_RESULT = "ipc_type_set_data_result";
  this.IPC_DATA_TYPE_GET_DATA = "ipc_type_get_data";
  this.IPC_DATA_TYPE_OPEN_DEV_TOOL = "ipc_type_open_dev_tool";
  this.IPC_DATA_TYPE_WINDOW_MINIMIZE = "ipc_type_window_minimize";
  this.IPC_DATA_TYPE_WINDOW_MAXIMIZE = "ipc_type_window_maximize";
  this.IPC_DATA_TYPE_SET_RESIZE_ABLE = "ipc_type_set_resize_able";

  // 글로벌 데이터 타입
  this.GLOBAL_DATA_TYPE__ALL_DATA           = "global_data_type__all";                // 글로벌 데이터 전체
  this.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT  = "global_data_type__code_check_result";  // 코드 확인 결과
  this.GLOBAL_DATA_TYPE__LOGIN_DATA         = "global_data_type__login";              // 로그인 데이터
  this.GLOBAL_DATA_TYPE__USER_DATA          = "global_data_type__user";               // 내 정보
  this.GLOBAL_DATA_TYPE__CHILD_CARE_DATA    = "global_data_type__child_care";         // 어린이집 정보
  this.GLOBAL_DATA_TYPE__FCM_TOKEN          = "global_data_type__fcm_toke";           // FCM 토큰

  // 스크린 크기
  this.screenHeight = 800;
  this.screenWidth = 456;

  // 기준크기 ( 노트 9 )
  this.STANDARD_HEIGHT = 798;
  this.STANDARD_WIDTH = 411;

  this.STANDARD_PIXEL_HEIGHT = 2560;
  this.STANDARD_PIXEL_WIDTH = 1440;

  // 비율별 사이즈
  this.getRateHeight = getRateHeight;
  this.getRateWidth = getRateWidth;
  this.getGlobalStyles = getGlobalStyles;

  this.COLOR_TRANSFER = '#00000000';
  this.COLOR_BUTTON_DEFAULT_BG = '#ff4784';
  this.COLOR_BUTTON_DEFAULT_TEXT = '#fff';
  this.COLOR_BUTTON_WHITE_GRAY_BG = '#eee';
  this.COLOR_BUTTON_SECOND_BG = '#73C4A7';

  /** 통신 결과 */
  this.REQUEST_RESULT_SUCCESS = 'SUCCESS'; // 통신 성공
  this.REQUEST_RESULT_SUCCESS = 'FAIL'; // 통신 실패

  /* member_type 학부모/선생님 구분 */
  this.MEMBER_TYPE_TEACHER = 1;
  this.MEMBER_TYPE_PARENT = 2;

  /*
   * common styles
   */
  // 상하좌우 여백 ( 사용 페이지 : 로그인, 초대코드, 비밀번호 설정 )
  this.PADDING_COMMON = 30;
  this.BUTTON_FONT_SIZE = 15;
  this.LINE_MID_COLOR = '#aaa';

  /**
   *  색상
   */
  this.COLOR_PINK = '#fd407f';
  this.COLOR_GREEN = '#62BC9C';
  this.COLOR_VIOLET = '#be6bd3';

  // 보더
  this.BORDER_DEFAULT_COLOR = '#ccc';
  this.BORDER_DEFAULT_WIDTH = 0.5;

  // 애니메이션 통일 !
  this.ANIMATION_NAME = 'zoomIn';
  this.ANIMATION_DURATION = 800;

  // 기기 타입
  this.DEVICE_TYPE_VIDEO_INPUT = "videoinput";  // 기기타입 비디오

  // 성별
  this.SEX_TYPE_MALE = 1;   // 남자
  this.SEX_TYPE_FEMALE = 2; // 여자
}

module.exports = Const;

/**
 * 높이 비율로 사이즈를 가져오는 메소드
 * @param {*} size
 */
function getRateHeight(size) {
  var RATE_HEIGHT = Const.screenHeight / Const.STANDARD_HEIGHT;
  return Math.round(size * RATE_HEIGHT);
}

/**
 * 너비 비율로 사이즈를 가져오는 메소드
 * @param {} size
 */
function getRateWidth(size) {
  var RATE_WIDTH = Const.screenWidth / Const.STANDARD_WIDTH;
  return Math.round(size * RATE_WIDTH);
}

function getGlobalStyles() {
  const SCREEN_HEIGHT = Const.screenHeight;
  const SCREEN_WIDTH = Const.screenWidth;
 
  return StyleSheet.create({
    /**
     * 타이틀
     */
    container_title_bar: {
      position: 'relative',
      height: 70,
      borderColor: '#777',
      borderBottomWidth: 1,
      flexDirection: 'row',
    },
    container_title_text_layout: {
      position: 'absolute',
      height: 70,
      width: SCREEN_WIDTH,
      alignItems: 'center',
      justifyContent: 'center',
    },
    container_title_text: {
      fontSize: 20,
      fontWeight: 'bold',
      color: Const.COLOR_BUTTON_DEFAULT_TEXT,
    },
    title_touchableButton: {
      position: 'absolute',
      backgroundColor: Const.COLOR_TRANSFER,
      height: 70,
      width: 70,
      alignItems: 'center',
      justifyContent: 'center',
    },

    title_touchableButtonText: {
      fontSize: 30,
      color: Const.COLOR_BUTTON_DEFAULT_TEXT,
    },
    /** End - 타이틀 */
  });
}
