import React, {useState, useEffect} from 'react';

import Const from '../util/Const';
import Util, { loadImageFileBase64 } from '../util/Util';

import { withRouter } from "react-router-dom";

import IntroScreen from './IntroScreen'; // 로그인전 코드입력 화면
import BFCodeScreen from './BFCodeScreen'; // 로그인전 코드입력 화면
import ServerRequestManager from '../util/ServerRequestManager';
import LoadingComponent from './component/LoadingComponent';

/*******************************************************************************************************
 *
 * 메인 스크린 ( 표시할 뷰를 설정 )
 *
 *******************************************************************************************************/
function HomeScreen({history}) {
  const [isLoading, setIsLoading] = useState(true);

  console.log("HomeScreen!!!!!");
  if( history == undefined ) {
    console.log("HomeScreen!! history == undefined");
  }
  else {
    console.log("HomeScreen!! history is not undefined");
  }


  // 로딩타이밍 임시 작업..
  setTimeout(() => {
    setIsLoading(false);
  }, 3000);


  function checkMobile(){

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if ( varUA.indexOf('android') > -1) {
        //안드로이드
        return "android";
    } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
        //IOS
        return "ios";
    } else {
        //아이폰, 안드로이드 외
        return "other";
    }
    
}



  const webviewFn = (cData) => {
    var id = cData.req_data.id;
    var member_type = cData.req_data.member_type;
    var sess_id = cData.req_data.sess_id;
    var childcare_num = null;
    var receiveUserInfo = false;
    var receiveChildcareInfo = false;
    var childcare_num = null;

    // 유저 정보 요청
    ServerRequestManager.requestGetUserInfo((result, data) => {
      console.log("requestGetUserInfo result result : " + result);
      console.log("requestGetUserInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

        receiveUserInfo = true;

        if( receiveChildcareInfo == true ) {
          moveScreen(member_type);
        }
      }
    }, id, sess_id);        

    // 어린이집 정보 요청
    ServerRequestManager.requestChildCareInfo((result, data) => {
      console.log("requestChildCareInfo result result : " + result);
      console.log("requestChildCareInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);

        receiveChildcareInfo = true;

        if( receiveUserInfo == true ) {
          moveScreen(member_type);
        }
      }
    }, id, sess_id, childcare_num);
  }

  useEffect(() => {
    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA);

    ServerRequestManager.requestChangePage( window, "HomeScreen" );
    
    // 웹뷰 메시지 받음
/* 
    window.__WEBVIEW_BRIDGE__ = {
      init: function() {
        try {
          
        } catch (err) {
          console.error(err);
        }
      }  
    };
  
    window.__WEBVIEW_BRIDGE__.init();

 */
  try{
    if( checkMobile() == "ios" ) {
      /* document.addEventListener("message", e=>{
        receiveData(e);
      }); */
      
      window.addEventListener("message", e=>{
        receiveData(e);
      });
    }
    else {
      document.addEventListener("message", e=>{
        receiveData(e);
      });
    }
  }
  catch(e) {
    alert("error : " + e.message);
  }
  
}, []);

  const receiveData = ( e )  => {

    // e.data
    //alert('message ==================' + e.data);

    let cData = JSON.parse(e.data);
    var id = cData.req_data.id;
    var pw = cData.req_data.pw;

    if( cData.req_type == 'r_out' ){
      Util.setGlobalData(window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA, cData.req_data);
      
      // alert( "r_out : " + JSON.stringify(cData) );
      webviewFn(cData);
    }else if( cData.req_type == 'auto_login' ){
      var id_type = ServerRequestManager.ID_TYPE_NONE;
      // var fcm_token = cData.fcmToken;

      ServerRequestManager.requestLogin((result, data) => {
        console.log("requestLogin result result : " + result);
        console.log("requestLogin result data : " + JSON.stringify(data));
      
        // alert( "requestLogin : " + JSON.stringify(data) );
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          data.result_data.id = id;
          data.result_data.pw = pw;
          
          Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA, data.result_data);
          
          cData.req_data.member_type = data.result_data.member_type;
          cData.req_data.sess_id = data.result_data.sess_id;


          // 웹뷰 메시지 전송
          let userData = {
            req_type:'login',
            req_data:{
              id:id,
              pw:pw,
              member_type:data.result_data.member_type,
              sess_id:data.result_data.sess_id
            }
          }

          ServerRequestManager.requestWebViewPostMessage( window, userData );

          webviewFn(cData);
        }
      }, id_type, id, pw, 
        // fcm_token
      );
    } 
    else {
      setIsLoading(false);
    }
  }


  const moveScreen = (member_type) => {
    if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
      history.push({ pathname:"/AFMainParentScreen" })
    }
    else if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      history.push({ pathname:"/AFMainSchoolScreen" })
    }
    // setIsLoading(false);
  }

  const IpcReceiver = (arg) => {
    console.log("HomeScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__LOGIN_DATA ) {
        if( arg.data ) {
          moveScreen(arg.data.member_type);
        }
      }
    } 
  }

  // 네비게이션의 모든 스택을 비우는 메소드
  const allPopInNavigation = () => {
    console.log('HomeScreen allPopInNavigation');
    /*while (true) {
      if (navigation.canGoBack()) {
        console.log('HomeScreen canGoBack');
        navigation.dispatch(StackActions.pop(1));
      } else {
        break;
      }
    }*/

    // console.log("HomeScreen pop end");

    //navigation.popToTop() // => 스택 최상단까지 pop을하는 메소드 ( 여기서 하니 에러남 )
  };

  return (
    <>
      { isLoading == true && 
        <LoadingComponent />
      }

      { isLoading == false && 
        <BFCodeScreen
          history={history}
        />
      }
    </>
  );
  
  /**
   * 화면 타입 변경
   * @param {*} type
   */
  /*const changeScreenType = (type) => {
    //allPopInNavigation(); // 네비게이션에서 모든 스택을 비워냄
    if( loginData == null ) {
      setScreenType(type); // 화면 타입 설정
    }
  };

  // 화면타입이 인트로일 경우
  if (screenType == Const.HOME_SCREEN_TYPE_INTRO) {
    //navigation.setOptions({headerShown:true}); // 함수 내부에서 호출할수 없음
    return (
      <IntroScreen
        history={history}
        changeScreenType={changeScreenType}
      />
    );
  }
  // 화면타입이 로그인전일 경우
  else if (screenType == Const.HOME_SCREEN_TYPE_BEFORE_LOGIN) {
    //navigation.setOptions({headerShown:false});
    
  }*/
}
export default withRouter(HomeScreen);
/*******************************************************************************************************
 * End - 메인 스크린 ( 표시할 뷰를 설정 )
 *******************************************************************************************************/
