import React, {useState, useEffect} from 'react';
//import {View, Text} from 'react-native';

// Android , IOS NativeModule과의 인터페이스 선언
//const { ReactNativeInterface } = NativeModules;

//import PreferenceInterface from '../util/PreferenceInterface'; // 모바일 프리퍼런스 인터페이스
import Const from '../util/Const';
import Util from '../util/Util';

//import * as Progress from 'react-native-progress'; // TODO 다른거 대체 필요

import splash1_img from '../img/splash1.png'
import splash2_img from '../img/splash2.png'
import splash3_img from '../img/splash3.png'
import splash4_img from '../img/splash4.png'

/*******************************************************************************************************
 *
 * 인트로 스크린
 *
 *******************************************************************************************************/
function IntroScreen({history, route, changeScreenType}) {
  //const { type, roomInfo, userName } = route.params;
  console.log('IntroScreen');
  
  setTimeout(function () {
    console.log('IntroScreen setTimeout');

    changeScreenType(Const.HOME_SCREEN_TYPE_BEFORE_LOGIN);
  }, 1000);

  return (
    <div style={{width:"100%",height:"100%", position:'relative'}}>
      <div style={{width:"100%",height:"100%", position:'absolute'}}>
        <img src={splash2_img}/>
        <img src={splash3_img}/>
        <img src={splash4_img}/>
      </div>
    </div>
  );
}
export default IntroScreen;
/*******************************************************************************************************
 * End - 메인 스크린 ( 표시할 뷰를 설정 )
 *******************************************************************************************************/
