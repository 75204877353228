// index.js
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";

function Root(){
    
    console.log("Root!!!!!");

    return( 
        <div>
            Test
        </div>
    )
}
export default Root;