import React, {useState, useEffect, useRef} from 'react';

import Const from '../util/Const';
import Util from '../util/Util';
import ButtonComponent from './component/ButtonComponent';
import TitleComponent from './component/TitleComponent';
import ShadowComponent from './component/ShadowComponent';
//import RNPickerSelect from 'react-native-picker-select';
import RNPickerSelectComponent from './component/RNPickerSelectComponent';
import styled, {css, keyframes} from 'styled-components';
import Validation from '../util/Validation';
import ServerRequestManager from '../util/ServerRequestManager';
import {useLocation} from "react-router";

import plus_img from '../img/plus.png';
import minus_img from '../img/minus.png';
import no_img from '../img/no_img.png';
import camera_img from '../img/camera.png';
import tree_img from '../img/tree.png'


// 호칭
const nickNameItems = [
  {label: '없음', value: 0},
  {label: '원장', value: 1},
  {label: '선생님', value: 2},
];

// 학급정보
const classItems = [
  {value: '꽃님반'},
  {value: '햇님반'},
  {value: '달님반'},
];

function ClassList({items, setClassInfo} ) {
  const listItems = items.map((item, index) => {
    return(
      <Container_school_name key={index}>
        <Input_school_name
        
          placeholder="반이름"
          onChange={(e) => {
            var newItems = [...items];
            newItems[index].value = e.target.value;
            setClassInfo(newItems);
          }}
          value={item.value}
        />
        <Btn_img_minus onClick={() => {
          var newItems = [...items];
          newItems.splice(index,1);
          setClassInfo(newItems);
        }}>
          <Img_minus src={minus_img} resizeMode={'contain'} />
        </Btn_img_minus>
      </Container_school_name>
    );
  })
  return listItems;
}

/**
 * 어린이집 이미지 등록 리스트들
 * @param {*} param0 
 * @returns 
 */
function FileBoxs({items, setChildCareimgList}) {
  console.log("FileBoxs tems.length : " + items.length);
  const listItems = items.map((item, index) => {
    console.log("FileBoxs " + index + "-" + item.name);
    return(
      <File_box key={index}>
        <Text_File_name>{item.name}</Text_File_name>
        <Btn_file_del onClick={() => {
          var newChildCareimgList = [...items];
          newChildCareimgList.splice( index, 1 );
          setChildCareimgList(newChildCareimgList);
        }}>
          <span>삭제</span>
        </Btn_file_del>
      </File_box>
    );
  })
  return listItems;
}

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
export default function BFSignupSchoolScreen(props) {

  // 필수입력
  const [idStr, setIdStr] = useState(''); // 아이디(이메일)
  const [viewIdStr, setViewIdStr] = useState(''); // 아이디(이메일) 화면용
  const [snsToken, setSnsToken] = useState(props.location.state.id_token); // sns 토큰
  const [certiNumStr, setCertiNumStr] = useState(''); // 인증번호 입력
  const [pwStr, setPwStr] = useState(''); // 비밀번호
  const [repwStr, setRePwStr] = useState(''); // 비밀번호 확인

  const [idType, setIdType] = useState(props.location.state.id_type); // 아이디 타입

  // 비밀번호 입력시 화면에서 비밀번호 보이지 않게 숨김
  const [passwordSecured, setPasswordSecured] = useState(true);
  const [passwordConfirmSecured, setPasswordConfirmSecured] = useState(true);

  // 선생님 정보
  const [nameStr, setNameStr] = useState(''); // 가입자 이름               `
  const [nickNameValue, setNickNameValue] = useState(0); // 호칭 값
  const [imgProfile, setImgProfile] = useState(no_img); // 추가정보 입력 프로필 이미지

  const [schoolNameStr, setSchoolNameStr] = useState(''); // 유아교육기관 정보
  const [logoName, setLogoName] = useState('logo.png'); // 대표 이미지 등록 (로고)
  const [classInfo, setClassInfo] = useState([]); // 학급정보
  const [photoStr, setPhotoStr] = useState(''); // 대표 사진 등록
  const [childNameStr, setChildNameStr] = useState('');
  const [codeResultData, setCodeResultData] = useState(null);
  const [checkIdStr, setCheckIdStr] = useState("");   // 중복체크된 아이디
  const [fcmToken, setFcmToken] = useState("");

  /* 이미지 관련 */
  const profileImgInputFile = useRef(null);
  const logoImgInputFile = useRef(null);
  const childCareimg1InputFile = useRef(null);

  const [imgProfileBase64, setImgProfileBase64] = useState(null);
  const [logoImgBase64, setLogoImgBase64] = useState(null);
  const [childCareimgList, setChildCareimgList] = useState([]);
  
  const location = useLocation();

  const classWrapRef = useRef();

  useEffect(() => {
    console.log("BFSignupSchoolScreen useEffect");

    setIdType(location.state.id_type);
    // SNS 타입일경우 idStr 설정
    if( location.state.id_type !== ServerRequestManager.ID_TYPE_NONE ) {
      setIdStr(String(location.state.id));

      if( location.state.id_type==='naver' )  setViewIdStr('네이버 ID 사용');
      if( location.state.id_type==='kakao' )  setViewIdStr('카카오 ID 사용');
      if( location.state.id_type==='google' ) setViewIdStr('구글 ID 사용');
    }
    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT);

    ServerRequestManager.requestChangePage( window, "BFSignupSchoolScreen" );
  }, []);

  const IpcReceiver = (arg) => {
    console.log("BFSignupSchoolScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT ) {
        setCodeResultData(arg.data);

        if( arg.data.name ) {
          setSchoolNameStr( arg.data.name );
        }

        if( arg.data.class_data ) {
          var newInfo = [];
          let classNames = arg.data.class_data.split("::");
          if( classNames != null && classNames.length > 2 ) {
            for( var i=1; i<classNames.length-1; i++ ) {
              newInfo.push({value:classNames[i]});
            }
          }
          setClassInfo(newInfo);
        }
      }
      else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__FCM_TOKEN ) {
        console.log("BFLoginScreen IpcReceiver token : " + arg.data );
        setFcmToken(arg.data);
      }
    }
  }

  const clickCheckId = () => {
    // 아이디 체크 요청
    ServerRequestManager.requestCheckId( (result, data) => {
      console.log("requestCheckId result result : " + result);
      console.log("requestCheckId result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {

        if( data.result_data.id ) {
          Util.showToast("이미 가입된 아이디 입니다.");
        }
        else {
          // 일반 회원가입일 경우 인증코드 요청해야함
          if( idType === ServerRequestManager.ID_TYPE_NONE ) {
            ServerRequestManager.requestSendEmailAuth((result, data) => {
              console.log("requestSendEmailAuth result result : " + result);
              console.log("requestSendEmailAuth result data : " + JSON.stringify(data));
              
              if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                setCheckIdStr(idStr);
                Util.showToast('가입 가능한 아이디 입니다. 입력한 이메일로 인증번호를 보냈습니다.');
              }
            }, idStr );
          }
          else {
            setCheckIdStr(idStr);
            Util.showToast('가입 가능한 계정입니다.');
          }
        }
      }
    }, idType, idStr );
  };

  const clickLogoRegister = () => {
    // alert('대표 로고 이미지 등록')
    logoImgInputFile.current.click();
  };

  const clickLogoRemove = () => {
    // alert('대표 로고 이미지 삭제')

    setLogoName("");
    setLogoImgBase64(null);
  };

  const clickClassAdd = () => {
    var newInfo = [...classInfo];
    newInfo.push({value:""});
    setClassInfo(newInfo);

  };

  /**
   * 대표 사진등록 버튼
   */
  const clickPhotoRegister = () => {
    if( childCareimgList.length < 3 ) {
      childCareimg1InputFile.current.click();
    }
    else {
      Util.showToast("대표사진은 최대 3개까지만 등록할수 있습니다.");
    }
  };

  const clickPhotoRemove = () => {
    // alert('대표 사진 삭제')
  };

  // 회원가입 클릭
  const clickSignupButton = () => {

    // 필수입력
    if( idType == ServerRequestManager.ID_TYPE_NONE ){
      if( Validation.CheckMust(idStr,pwStr,repwStr) === false ){
        Validation.MsgCheckMust();
        return;
      }
      // 아이디(이메일)
      if( Validation.CheckEmail(idStr) === false ) {
        Validation.MsgCheckEmail();
        return;
      }
      // 비밀번호
      if( Validation.CheckPassword(pwStr) === false ) {
        Validation.MsgCheckPassword();
        return;
      }
      // 비밀번호 확인
      if( Validation.CheckPasswordConfirm(pwStr, repwStr) === false ) {
        Validation.MsgCheckPasswordConfirm();
        return;
      }
    }

    console.log(`
      idStr=${idStr},
      snsToken=${snsToken},
      pwStr=${pwStr},
      repwStr=${repwStr},
      nameStr=${nameStr},
      idType=${idType},
      nickNameValue=${nickNameValue},
      schoolNameStr=${schoolNameStr},
      logoName=${logoName},
      classInfo=${classInfo},
      photoStr=${photoStr},
      childNameStr=${childNameStr},
    `)
    
    var code = codeResultData.code;
    var id = idStr;
    var pw = pwStr;
    var member_img = null;
    var id_token = snsToken;

    if( imgProfileBase64 ) {
      member_img = imgProfileBase64;
    }
    var member_name = nameStr;
    var member_alias = nickNameValue;

    var reqData = {};

    if( nickNameValue == ServerRequestManager.TEACHER_TYPE_PRESIDENT ) {

      // 어린이집명
      reqData["childcare_name"] = schoolNameStr;

      // 어린이집 학급정보 - 콤마로 구문 ex) 햇님반, 별빛반
      if( classInfo.length > 0 ) {
        let classNames = "";
        for( var i=0; i<classInfo.length; i++ ) {
          if( i > 0 ) {
            classNames = classNames + ",";
          }
          classNames = classNames + classInfo[i].value;
        }
        reqData["childcare_class"] = classNames;
      }

      // 어린이집 로고 이미지
      if( logoImgBase64 != null ) {
        reqData["childcare_logo"] = logoImgBase64;
      }

      // 어린이집 이미지
      if( childCareimgList.length > 0 ) {
        for( var i=0; i<childCareimgList.length; i++ ) {
          if( i == 0 ) {
            reqData["childcare_img1"] = childCareimgList[i].base64Data;
          }
          else if( i == 1 ) {
            reqData["childcare_img2"] = childCareimgList[i].base64Data;
          }
          else if( i == 2 ) {
            reqData["childcare_img3"] = childCareimgList[i].base64Data;
          }
        }
      }
    }

    if( idType == ServerRequestManager.ID_TYPE_NONE ) {
      // 인증코드 확인
      ServerRequestManager.requestCheckEmailAuth( (result, data) => {
        console.log("requestCheckEmailAuth result result : " + result);
        console.log("requestCheckEmailAuth result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          // 회원가입
          ServerRequestManager.requestSignUp( (result, data) => {
            console.log("requestSignUp result result : " + result);
            console.log("requestSignUp result data : " + JSON.stringify(data));
            
            if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
              moveMainScreenAfterLogin();
            }
          }, code, idType, id, pw, member_img, member_name, member_alias, reqData, null);
        }
      }, id, certiNumStr );
    }
    else {
      // 회원가입
      ServerRequestManager.requestSignUp( (result, data) => {
        console.log("requestSignUp result result : " + result);
        console.log("requestSignUp result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          moveMainScreenAfterLogin();
        }
      }, code, idType, id, pw, member_img, member_name, member_alias, reqData, id_token );
    }
  };
  // End - 회원가입 클릭

  const moveMainScreenAfterLogin = () => {
    
    ServerRequestManager.requestLogin((result, data) => {
      console.log("requestLogin result result : " + result);
      console.log("requestLogin result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        data.result_data.code = codeResultData.code;
        data.result_data.id = idStr;
        data.result_data.pw = pwStr;
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA, data.result_data);
        
        var member_type = data.result_data.member_type;
        var sess_id = data.result_data.sess_id;

        var receiveUserInfo = false;
        var receiveChildcareInfo = false;
        var childcare_num = null;

        // 웹뷰 메시지 전송
        let userData = {
          req_type:'login',
          req_data:{
            id:idStr,
            pw:pwStr,
            member_type:member_type,
            sess_id:sess_id,
          }
        }
        ServerRequestManager.requestWebViewPostMessage( window, userData );
        

        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

            receiveUserInfo = true;

            if( receiveChildcareInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, idStr, sess_id);        

        // 어린이집 정보 요청
        ServerRequestManager.requestChildCareInfo((result, data) => {
          console.log("requestChildCareInfo result result : " + result);
          console.log("requestChildCareInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);
    
            receiveChildcareInfo = true;

            if( receiveUserInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, idStr, sess_id, childcare_num);
      }
    }, idType, idStr, pwStr, fcmToken, snsToken );
  }

  const moveScreen = (member_type) => {
    if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
      props.history.push({ pathname:"/AFMainParentScreen" })
    }
    else if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      props.history.push({ pathname:"/AFMainSchoolScreen" })
    }
  }

  var nameTextInput = null;
  var pwTextInput = null;
  var rePwTextInput = null;

  return (
    <div>
      <div>
        <Container>
          <TitleComponent history={props.history} title={'회원가입'} />

          <Wrap>
            {/* 인풋 필수정보입력 */}
            <ShadowComponent>
              <View_input_wrap>
                <Input_common
                  placeholder="아이디(이메일)"
                  placeholderTextColor={viewIdStr == '' ? '#bbb' : '#000'}
                  keyboardType="email-address"
                  onChange={(e) => {
                    setIdStr(e.target.value);
                    setViewIdStr(e.target.value);
                  }}
                  returnKeyType="next"
                  disabled={idType !== ServerRequestManager.ID_TYPE_NONE }
                  /*onSubmitEditing={() => {
                    nameTextInput.focus();
                  }}*/ // TODO Enter 입력시 이벤트로 변경
                  value={viewIdStr}
                />
                <Dot_must>*</Dot_must>
                <Btn_id_check onClick={() => clickCheckId()}>
                  <Toggle_btn_text>{ checkIdStr === "" ?"중복확인":"재 확인"}</Toggle_btn_text>
                </Btn_id_check>
              </View_input_wrap>
            </ShadowComponent>

            { idType === ServerRequestManager.ID_TYPE_NONE && checkIdStr !== "" && 
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="인증번호"
                    placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    secureTextEntry={passwordSecured}
                    onChange={(e) => setCertiNumStr(e.target.value)}
                    /*onSubmitEditing={() => {
                      pwTextInput.focus();
                    }}*/ // TODO Enter 입력시 이벤트로 변경
                    value={certiNumStr}
                  />
                  <Dot_must>*</Dot_must>
                </View_input_wrap>
              </ShadowComponent>
            }

            {
              idType == ServerRequestManager.ID_TYPE_NONE &&
              <>
                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="비밀번호"
                      placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                      textContentType="password"
                      secureTextEntry={passwordSecured}
                      onChange={(e) => setPwStr(e.target.value)}
                      returnKeyType="next"
                      type="password"
                      /*onSubmitEditing={() => {
                        pwTextInput.focus();
                      }}*/ // TODO Enter 입력시 이벤트로 변경
                      value={pwStr}
                      ref={(input) => {
                        pwTextInput = input;
                      }}
                    />
                    <Dot_must>*</Dot_must>
                  </View_input_wrap>
                </ShadowComponent>

                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="비밀번호 확인"
                      placeholderTextColor={repwStr == '' ? '#bbb' : '#000'}
                      textContentType="password"
                      secureTextEntry={passwordConfirmSecured}
                      onChange={(e) => setRePwStr(e.target.value)}
                      returnKeyType="next"
                      type="password"
                      /*onSubmitEditing={() => {
                        pwTextInput.focus();
                      }}*/ // TODO Enter 입력시 이벤트로 변경
                      value={repwStr}
                      ref={(input) => {
                        rePwTextInput = input;
                      }}
                    />
                    <Dot_must>*</Dot_must>
                  </View_input_wrap>
                </ShadowComponent>
                <Text_info red>* 필수입력사항입니다.</Text_info>
              </>
            }
            
            {/* END 인풋 필수정보입력 */}

            <Line_mid></Line_mid>

            {/* 인풋 추가정보입력 */}
            <Text_mid_first>선생님 정보</Text_mid_first>
            <Container_info_add>
              <TouchAble_profile_img onClick={() => {profileImgInputFile.current.click();}}>
                <Profile>
                  <Img_profile src={imgProfile} resizeMode={'contain'} />
                </Profile>
                <Img_camera
                  src={camera_img}
                  resizeMode={'contain'}
                  onClick={()=>{}}
                />
              </TouchAble_profile_img>
              <Box_profile_input>
                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="가입자 이름을 입력해주세요"
                      placeholderTextColor={nameStr == '' ? '#bbb' : '#000'}
                      onChange={(e) => setNameStr(e.target.value)}
                      returnKeyType="next"
                      /*onSubmitEditing={() => {
                        pwTextInput.focus();
                      }}*/ // TODO Enter 입력시 이벤트로 변경
                      value={nameStr}
                    />
                  </View_input_wrap>
                </ShadowComponent>
                <ShadowComponent>
                  <View_select_wrap>
                    <RNPickerSelectComponent
                      placeholder={{
                        label:
                        nickNameValue == ''
                            ? '호칭을 선택해주세요'
                            : nickNameItems[nickNameValue].label,
                        value: nickNameValue,
                        color: '#ff4784',
                      }}
                      items={nickNameItems}
                      onValueChange={(value) => {
                        console.log("onValueChange value : " + value);
                        setNickNameValue(value);
                      }}
                      value={nickNameValue}
                      style={{
                        placeholder: {
                          color: nickNameValue == 0 ? '#bbb' : '#000',
                        },
                      }}
                    />
                  </View_select_wrap>
                </ShadowComponent>
              </Box_profile_input>
            </Container_info_add>
            {/* END 인풋 추가정보입력 */}
            { nickNameValue == 1 &&
            <>
              <Line_mid></Line_mid>

              <Text_mid_first>유아교육기관 정보</Text_mid_first>

              <Text_info pink>
                입력된 내용으로 학부모님들께 우리 기관을 소개할 수 있습니다.
              </Text_info>

              <div style={{height: 20}} />
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="유아 교육 기관 명을 입력 해주세요"
                    placeholderTextColor={schoolNameStr == '' ? '#bbb' : '#000'}
                    onChange={(e) => setSchoolNameStr(e.target.value)}
                    returnKeyType="done"
                    value={schoolNameStr}
                  />
                  {/*<View_wrap_radio>
                    <Btn_box onClick={() => {}}>
                      <Text_btn>수정</Text_btn>
                    </Btn_box>
                  </View_wrap_radio>*/}
                </View_input_wrap>
              </ShadowComponent>

              <Text_info>
                ※ 등록된 유아 교육 기관명이 다르면 수정할 수 있습니다.
              </Text_info>

              <Line_mid></Line_mid>

              <Text_mid_first>대표 이미지 등록(로고)</Text_mid_first>
              <div style={{height: 20}} />
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    disabled={true} // readonly
                    placeholder="jpg, png, bmp 파일만 가능합니다."
                    placeholderTextColor={'#bbb'}
                    returnKeyType="done"
                    /*onChange={e => setChildNameStr(e.target.value)}
                    value={childNameStr}*/
                  />
                  <View_wrap_radio>
                    <Btn_box onClick={() => clickLogoRegister()}>
                      <Text_btn active>등록</Text_btn>
                    </Btn_box>
                  </View_wrap_radio>
                </View_input_wrap>
              </ShadowComponent>
              <div style={{height: 10}} />

              { logoImgBase64 != null && 
                <File_box>
                  <Text_File_name>{logoName}</Text_File_name>
                  <Btn_file_del onClick={() => clickLogoRemove()}>
                    <span>삭제</span>
                  </Btn_file_del>
                </File_box>
              }

              <Line_mid></Line_mid>

              <ClassWrap ref={classWrapRef}>
                <div>
                  <Text_mid_first>학급정보</Text_mid_first>
                  <Btn_img_plus onClick={() => {
                      clickClassAdd();
                    }}
                  >
                    <Img_plus
                      src={plus_img}
                      resizeMode={'contain'}
                    />
                  </Btn_img_plus>
                </div>

                <div style={{height: 20}} />

                <ClassList 
                  items={classInfo} 
                  setClassInfo={(items)=>{setClassInfo(items)}}
                />
              </ClassWrap>
              <div style={{height: 50}} />

              <Text_mid_first>대표 사진 등록</Text_mid_first>
              <Text_info>
                유아 교육 기관의 사진을 등록해주세요. 최대 3장까지 등록할 수
                있습니다.
              </Text_info>

              <div style={{height: 20}} />
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    disabled={true} // readonly
                    placeholder="jpg, png, bmp 파일만 가능합니다."
                    placeholderTextColor={'#bbb'}
                    returnKeyType="done"
                  />
                  <View_wrap_radio>
                    <Btn_box onClick={() => clickPhotoRegister()}>
                      <Text_btn active>등록</Text_btn>
                    </Btn_box>
                  </View_wrap_radio>
                </View_input_wrap>
              </ShadowComponent>

              <FileBoxs 
                items={childCareimgList}
                setChildCareimgList={(list)=>setChildCareimgList(list)}/>

              {/* 
              <File_box>
                <Text_File_name>logo.png</Text_File_name>
                <Btn_file_del onClick={() => clickPhotoRemove()}>
                  <span>삭제</span>
                </Btn_file_del>
              </File_box>

              <File_box>
                <Text_File_name>logo.png</Text_File_name>
                <Btn_file_del onClick={() => clickPhotoRemove()}>
                  <span>삭제</span>
                </Btn_file_del>
              </File_box>*/}

              <div style={{height: 30}} />

            </>}
              <ButtonComponent
                text="회원가입"
                buttonClick={clickSignupButton}
              />
          </Wrap>

          <input type='file' id='file' ref={profileImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              setImgProfile(previewData);
              setImgProfileBase64(base64Data);
            });
          }}/>
          <input type='file' id='file' ref={logoImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              //setImgProfile(previewData);
              setLogoName(name);
              setLogoImgBase64(base64Data);
            });
          }}/>
          <input type='file' id='file' ref={childCareimg1InputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              //setImgProfile(previewData);
              
              console.log("loadImageFileBase64");
              var newChildCareimgList = [... childCareimgList];

              var childCareimg = {
                name:name,
                base64Data:base64Data
              }
              newChildCareimgList.push(childCareimg);
              console.log("beforeChildCareimgList size : " + newChildCareimgList.length);
              setChildCareimgList(newChildCareimgList);
              console.log("beforeChildCareimgList 22222");
            });
          }}/>
          {/*
          <input type='file' id='file' ref={childCareimg2ImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              //setImgProfile(previewData);
              setChildCareimg2Base64(base64Data);
            });
          }}/>
          <input type='file' id='file' ref={childCareimg3ImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              //setImgProfile(previewData);
              setChildCareimg3Base64(base64Data);
            });
          }}/> */}
        </Container>
      </div>
    </div>
  );
}

/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/
/**
 * start - animation
 */
const aniClassName = keyframes`
    0% {
      opacity:0;
    }
    100% {
      opacity:100%;
    }
`;
/**
 * End - animation
 */

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  padding-bottom: ${Const.getRateHeight(50)}px;
`;

const ClassWrap = styled.div`
  position:relative;
`;

// 정보 입력 영역
const Wrap = styled.div`
  padding:0 26px;
  box-sizing:border-box;
  padding-top:20px;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height: 56px;
  position:relative;
  margin-bottom:8px;

  ${(props) =>
    props.selectbox &&
    css`
      padding-top: ${Const.getRateHeight(5)}px;
    `}
`;

const Input_common = styled.input`
  width: 100%;
  height: 56px;
  font-size: 0.91em;
  border-radius:5px;
  border-color:#fff;
  color:#000;
  border:none;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  padding:0 2%;
  box-sizing:border-box;
`;

const Dot_must = styled.span`
  position: absolute;
  top: 2px;
  left: 5px;
  color: ${Const.COLOR_PINK};
`;

const Btn_id_check = styled.div`
  width:83px;
  height: 42px;
  line-height:42px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right:7px;
  border-radius: 50px;
  background-color: ${Const.COLOR_BUTTON_SECOND_BG};
  line-height:42px;
  text-align:center;
  cursor:pointer;
`;

const Toggle_btn_text = styled.span`
  color: #fff;
  font-size:0.91em;
  font-weight: 400;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top: 30px;
  margin-bottom:32px;
  border-bottom:1px dotted #ddd;
`;

// 인풋 추가정보입력
const Text_mid_first = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  display:block;
`;

const Text_info = styled.span`
  color: #000;
  margin-top:10px;
  display:block;
  font-size:0.82em;

  ${(props) =>
    props.red &&
    css`
      color: red;
      padding: 0;
      margin-top:20px;
      font-size:0.82em;
      display:block;
    `}

  ${(props) =>
    props.pink &&
    css`
      color: ${Const.COLOR_PINK};
    `}
`;

const Container_info_add = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position:relative;
  margin:20px 0;
`;

const TouchAble_profile_img = styled.div`
  justify-content: center;
  align-items: center;
  display:inline-block;
  width:120px;
  position:relative;
  margin-right:10px;
`;

const Profile = styled.div`
  border-radius:70%;
  width:100px;
  height:100px;
  border:1px solid #ddd;
  overflow:hidden;
  margin-right:10px;
  position:relative;
`;

const Img_profile = styled.img`
  width:100%;
  height:100%;
  position:relative;
`;

const Img_camera = styled.img`
  height: 30px;
  position:absolute;
  bottom:0px;
  right:5px;
  cursor:pointer;
  `;

const Box_profile_input = styled.div`
  width:calc(100% - 120px);
  display:inline-block;
`;

const View_select_wrap = styled.div`
  height: 56px;
  width: 100%;
`;

const Text_mid_second2 = styled.span`
  color: #000;
  letter-spacing: -1px;
  padding-top: ${Const.getRateHeight(10)}px;
  padding-bottom: ${Const.getRateHeight(30)}px;
`;

const Btn_img_plus = styled.div`
  z-index: 1;
  position: absolute;
  right:0;
  top:0;
  width:51px;
  height:45px;
  text-align:center;
  padding-top:10px;
  box-sizing:border-box;
  margin:-8px -5px 0 0;
  cursor:pointer;
`;

const Img_plus = styled.img`
  height: ${Const.getRateHeight(20)}px;
`;

const Container_school_name = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius:10px;
  height:56px;
  margin-bottom:11px;
  border:1px solid #ddd;
  line-height:56px;

  animation:${aniClassName} 0.4s ease-in-out;
`;
const Input_school_name = styled.input`
  font-size: 1em;
  padding-left:2%;
  border:none;
`;

const Btn_img_minus = styled.div`
  position: absolute;
  right: 0;
  top:0;
  display:inline-block;
  width:50px;
  height:56px;
`;

const Img_minus = styled.img`
  height:26px;
  position:absolute;
  right:8px;
  top:14px;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top:7px;
  right: 7px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Btn_box = styled.div`
  top: ${Const.getRateWidth(5)}px;
  right: ${Const.getRateWidth(30)}px;
  cursor:pointer;
`;

const Text_btn = styled.span`
  width: 64px;
  color: #fff;
  text-align: center;
  height:42px;
  line-height:42px;
  border-radius: 50px;
  font-size: ${Const.BUTTON_FONT_SIZE}px;
  font-weight: 400;
  margin-left: ${Const.getRateWidth(3)}px;
  background-color: #b7b7b7;
  display:inline-block;

  ${(props) =>
    props.active &&
    css`
      background-color: #73c4a7;
    `}
`;

const File_box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom:6px;
`;

const Text_File_name = styled.span`
  font-size: 0.92em;
  padding-bottom: 6px;
  max-width:317px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
`;

const Btn_file_del = styled.div`
  border-width: 1px;
  border-radius: 20px;
  margin-left:15px;
  border:1px solid #666;
  font-size:0.82em;
  width:42px;
  height:22px;
  text-align:center;
  line-height:22px;
  font-weight:500;
`;

/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/
