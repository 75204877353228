var SnsLogin = new (function(){
    this.NAVER_CLIENT_ID = 'ltq34YSytFgcwIGQUgyE';
    this.KAKAO_CLIENT_ID = '779654e079b4b066dd3e9f2e8e621361';
    this.GOOGLE_CLIENT_ID = '953679557354-a0f7h938n5rbv0l89lutb29nd9145b6a.apps.googleusercontent.com';
})();
export default SnsLogin;

/*********************************************************************************
 *
 * Kakao
 *
 *********************************************************************************/
// 참고 : https://velog.io/@seize/React-%EC%B9%B4%EC%B9%B4%EC%98%A4-%EC%86%8C%EC%85%9C-%EB%A1%9C%EA%B7%B8%EC%9D%B8#1-%EC%B9%B4%EC%B9%B4%EC%98%A4-%EA%B0%9C%EB%B0%9C%EC%9E%90-%EC%82%AC%EC%9D%B4%ED%8A%B8%EC%97%90%EC%84%9C-%EB%82%B4-%EC%95%A0%ED%94%8C%EB%A6%AC%EC%BC%80%EC%9D%B4%EC%85%98-%EC%B6%94%EA%B0%80httpsdeveloperskakaocom
// 참고2 : https://wonism.github.io/react-kakao-login/?path=/story/kakaologin--default
// 토큰 갱신 에러 : https://kangprog.tistory.com/m/99
function loginKakao(){ }

/*********************************************************************************
 *
 * Naver
 *
 * 참고 : 
 * https://velog.io/@taylorkwon92/TIL-%EB%84%A4%EC%9D%B4%EB%B2%84-%EB%A1%9C%EA%B7%B8%EC%9D%B8-API-in-React-TypeScript#3-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EA%B8%B0%EB%8A%A5-%EC%B6%94%EA%B0%80%ED%95%98%EA%B8%B0
 * https://github.com/peoplefund-tech/react-naver-login
 *********************************************************************************/
function loginNaver(){ }

/*********************************************************************************
 *
 * Google
 *
 * 참고 : 
 * http://tlog.tammolo.com/blog/google-login-2c066a0a-281c-4b18-98c6-338a35a817ef/
 * 
 *********************************************************************************/
function loginGoogle(){ }
