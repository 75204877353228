import React, {useState, useEffect, useRef} from 'react';
import Const from '../util/Const';
import Util from '../util/Util';
import styled, {css} from 'styled-components';
import ShadowComponent from '../screen/component/ShadowComponent';
import ButtonComponent from '../screen/component/ButtonComponent';
import RNPickerSelectComponent from './component/RNPickerSelectComponent';
//import RNPickerSelect from 'react-native-picker-select';
import Validation from '../util/Validation';

import time2_img from '../img/time2.png'
import alim_icon_img from '../img/alim_icon.png'
import top_icon1_img from '../img/top_icon1.png'
import top_icon2_img from '../img/top_icon2.png'
import calendar_ov_img from '../img/calendar_ov.png'
import TitleComponent from './component/TitleComponent';
import ServerRequestManager from '../util/ServerRequestManager';
import { useLocation } from "react-router-dom";
//import TimePicker from 'react-gradient-timepicker';
import TimePicker from 'react-times';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';
import 'react-times/css/classic/default.css';
import "../css/react-times-custom.css";
import "../css/react-datepicker-custom.css";
import moment from 'moment';


registerLocale('ko', ko)

const date = new Date();

const groupItems = [
  {label: '참여자를 지정하세요.', value: 0},
  {label: '1:1 원격수업', value: 1},
  {label: '참관수업', value: 2}
];

const codecItems = [
  {label: '코덱을 지정하세요.', value: 0},
  {label: 'vp8', value: 'vp8'},
  {label: 'h264', value: 'h264'}
];


const noticeItems = [
  {label: '알리지않음',    value: 0   },
  {label: '1시간 전 알림',   value: 1  },
  {label: '10분전 알림', value: 2},
];


function getCurrentHour() {
  let date = new Date();
  let hour = date.getHours();
  let minute = date.getMinutes();
  if( minute > 55 ) {
    hour++;
  }
  if( hour == 24 ) {
    hour = 0;
  }
  return hour;
}

function getCurrentMinute() {
  let date = new Date();
  let currentMinute = date.getMinutes();

  let plusNum = 5 - (currentMinute%5);
  if( plusNum == 5 ) plusNum = 0;
  currentMinute = currentMinute + plusNum;

  if( currentMinute == 60 ) currentMinute = 0;
  return currentMinute;
}

/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/
export default function AFOpenClassScreen(props) {
  const [roomData, setRoomData] = useState(null);

  const [classNameStr, setClassNameStr] = useState(''); // 온라인 교실 제목
  const [groupValue, setGroupValue] = useState(''); // 참여자
  const [startDate, setStartDate] = useState(new Date()); // 시작 날짜

  const [startTime, setStartTime] = useState( getCurrentHour() + ":" + getCurrentMinute() ); // 시작시간
  const [endTime, setEndTime] = useState( getCurrentHour() + ":" + getCurrentMinute() );     // 종료 시간

  // 날짜 선택
  const [openDate, setOpenDate] = useState(''); // 날짜 선택

  // 개설 시간
  const [startHour, setStartHour] = useState(getCurrentHour());       // 시작 시간 : 시
  const [startMinute, setStartMinute] = useState(getCurrentMinute()); // 시작 시간 : 분
  const [endHour, setEndHour] = useState(getCurrentHour());           // 종료 시간 : 시
  const [endMinute, setEndMinute] = useState(getCurrentMinute());     // 종료 시간 : 분

  const [isStartTimeVisible, setIsStartTimeVisible] = useState(false);
  const [isEndTimeVisible, setIsEndTimeVisible] = useState(false);
  const [startTimeColor, setStartTimeColor] = useState(false);
  const [endTimeColor, setEndTimeColor] = useState(false);
  const [globalData, setGlobalData] = useState(null);

  // 온라인 교실 시작 전 알림
  const [noticeValue, setNoticeValue] = useState(0);
  const [timeMark, setTimeMark] = useState(0);

  // 비디오 코덱 선택
  const [codec, setCodec] = useState('');


  const location = useLocation();

  let datePickerRef = useRef(null);
  let startTimePickerRef = useRef(null);
  let endTimePickerRef = useRef(null);

  
  useEffect(() => {
    console.log("AFOpenClassScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA);

    ServerRequestManager.requestChangePage( window, "AFOpenClassScreen" );
    
    if( location.state && location.state.roomData ) {
      let rData = location.state.roomData;
      console.log("AFOpenClassScreen roomData : " + JSON.stringify(rData));

      setRoomData(rData);
      setClassNameStr(rData.subject);

      let year = rData.ymd / 10000;
      let month = ( rData.ymd % 10000) / 100 - 1;
      let day = rData.ymd % 100;

      let date = new Date();
      date.setFullYear(year);
      date.setMonth(month)
      date.setDate(day);
      setStartDate(date);
      //setGroupValue(rData.);

      setStartTime(rData.st_time);
      setStartHour(rData.st_time.split(":")[0]);
      setStartMinute(rData.st_time.split(":")[1]);

      setEndTime(rData.ed_time);
      setEndHour(rData.ed_time.split(":")[0]);
      setEndMinute(rData.ed_time.split(":")[1]);

      setNoticeValue(rData.alarm);
      setGroupValue(rData.class_type);
      setCodec(rData.codec);
    }
  }, []);

  const IpcReceiver = (arg) => {
    console.log("AFMainSchoolScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        setGlobalData(arg.data);
      }
    } 
  }


  // 개설 시간 - 시작
  const showStartTime = () => {
    setIsStartTimeVisible(true);
  };
  const hideStartTime = () => {
    setIsStartTimeVisible(false);
  };
  const ConfirmStartTime = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();
    setStartHour(hour);
    setStartMinute(minute);
    setStartTime(hour + ':' + minute);
    setStartTimeColor(true);
    // console.warn("A date has been picked: ", minute);
    hideStartTime();
  };

  // 개설 시간 - 종료
  const showEndTime = () => {
    setIsEndTimeVisible(true);
  };
  const hideEndTime = () => {
    setIsEndTimeVisible(false);
  };
  const ConfirmEndTime = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();
    setEndHour(hour);
    setEndMinute(minute);
    setEndTime(hour + ':' + minute);
    setEndTimeColor(true);
    // console.warn("A date has been picked: ", minute);
    hideEndTime();
  };

  const clickOpenButton = () => {
    // console.log(startTime)
    
    // 제목
    if( Validation.CheckClassName(classNameStr) === false ){
      Validation.MsgCheckClassName();
      return;
    }
    
    // 개설 시작시간
    if( Validation.CheckStartTime(startTime) === false ){
      Validation.MsgCheckDate();
      return;
    }
    
    // 개설 종료시간
    if( Validation.CheckEndTime(endTime) === false ){
      Validation.MsgCheckEndTime();
      return;
    }

    if( groupValue == 0 ) {
      Util.showToast("참여자를 지정하세요.");
      return;
    }

    if( codec == 0 ) {
      Util.showToast("코덱을 지정하세요.");
      return;
    }

    if( checkCreateAble() == false ) {
      return;
    }
    
    
    // 참여자 지정, 기획 미확정
    // 온라인 교실 시작 전 알림도 체크할지 ? 일단 보류


    let id = globalData.loginData.id;
    let sess_id = globalData.loginData.sess_id;
    let subject = classNameStr;
    let ymd = Util.makeDateFormatyyyyMMdd(startDate);
    let st_date = startHour + "" + startMinute;
    let ed_date = endHour + "" + endMinute;
    let class_type = groupValue;
    let alarm = noticeValue;

    if( roomData ) {
      let middleData = {
        subject:subject,
        ymd:ymd,
        st_date:st_date,
        ed_date:ed_date,
        class_type:class_type,
        alarm:alarm,
        codec:codec
      };
      ServerRequestManager.requestEditRoom((result, data) => {
        console.log("requestJoinRoom result result : " + result);
        console.log("requestJoinRoom result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          alert('온라인 교실 수정에 성공했습니다.');
          props.history.goBack();
        }
      }, id, sess_id, roomData.id, middleData);
    }
    else {
      ServerRequestManager.requestCreateRoom((result, data) => {
        console.log("requestJoinRoom result result : " + result);
        console.log("requestJoinRoom result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          alert('온라인 교실 개설에 성공했습니다.');
          props.history.goBack();
        }
      }, id, sess_id, subject, ymd, st_date, ed_date, class_type, alarm, codec);
    }
  };

  const checkCreateAble = () => {
    let currentDate = new Date();
    
    let toastMessageBeforeDay = "현재 이후의 시간으로 만들어주세요.";
    let toastMessageCrossTime = "종료시간이 시작시간보다 이전입니다.";
    
    let isSameDay = false;
    if( currentDate.getFullYear() > startDate.getFullYear() ) {
      Util.showToast(toastMessageBeforeDay);
      return false;
    }
    else if( currentDate.getFullYear() == startDate.getFullYear() ) {
      if( currentDate.getMonth() > startDate.getMonth() ) {
        Util.showToast(toastMessageBeforeDay);
        return false;
      }
      else if( currentDate.getMonth() == startDate.getMonth() ) {
        if( currentDate.getDate() > startDate.getDate() ) {
          Util.showToast(toastMessageBeforeDay);
          return false;
        }
        else if( currentDate.getDate() == startDate.getDate() ) {
          isSameDay = true;
        }
      }
    }

    if( isSameDay == true ) {
      if( currentDate.getHours() > startHour ) {
        Util.showToast(toastMessageBeforeDay);
        return false;
      }
      else if( currentDate.getHours() == startHour ) {
        if( currentDate.getMinutes() >= startMinute ) {
          Util.showToast(toastMessageBeforeDay);
          return false;
        }
      }
    }
    
    if( startHour > endHour ) {
      Util.showToast(toastMessageCrossTime);
      return false;
    }
    else if( startHour == endHour ) {
      if( startMinute >= endMinute ) {
        Util.showToast(toastMessageCrossTime);
        return false;
      }
    }

    return true;
  }

  const handleFocusedChange = ( isStartTime ) => {
    if( isStartTime ) {
      startTimePickerRef.onFocus();
    }
    else {
      endTimePickerRef.onFocus();
    }
  }


  return (
    <>
      <div>
        <div>
          <Container>
            
            <TitleComponent history={props.history} title={'온라인 교실 개설'} />
            {/* 프로필 영역 
            <Layout_view_top>
              <TouchAble_btn_goback onClick={() => props.history.goBack()}>
                <Img_top_icon2
                  src={top_icon1_img}
                  resizeMode={'contain'}
                />
              </TouchAble_btn_goback>
              <Text_top_title>온라인 교실 개설</Text_top_title>
            </Layout_view_top>*/}
            {/* End - 프로필 영역 */}

            <Wrap>
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="온라인 교실 제목을 입력해주세요"
                    placeholderTextColor={classNameStr == '' ? '#bbb' : '#000'}
                    onChange={(e) => setClassNameStr(e.target.value)}
                    returnKeyType="done"
                    /*onSubmitEditing={() => {
                      pwTextInput.focus();
                    }}*/ // TODO Enter 입력시 이벤트 발생
                    value={classNameStr}
                    titleInput
                  />
                </View_input_wrap>
              </ShadowComponent>


              <ShadowComponent>
                <View_input_wrap>
                  <Btn_open_date>
                    {/*<Input_common
                      editable={false}
                      placeholder={
                        openDate == '' ? '날짜를 선택해주세요' : openDate
                      }
                      placeholderTextColor={openDate == '' ? '#bbb' : '#000'}
                      onChange={(e) => setOpenDate(e.target.value)}
                      returnKeyType="next"
                      
                      value={openDate}
                    />*/}
                    <DatePicker 
                      // inputmode="none"
                      disabled={false}
                      customInput={
                        <DatePickerUICustom
                          // placeholder={ openDate == '' ? '날짜를 선택해주세요' : openDate }
                          // placeholderTextColor={openDate == '' ? '#bbb' : '#000'}
                          /*    onChange={(e) => {
                            console.log(e.target.value)
                            setOpenDate(e.target.value);
                          }} */
                          onFocus={(e) => {
                            console.log(e.target.value)
                          }}
                          datePickerInput={false}
                        >
                          {openDate == '' ? '개설 날짜를 선택해주세요.' : openDate}
                        </DatePickerUICustom>
                      }
                      dateFormat={"yyyy-MM-dd"}
                      ref={(datepicker) => { datePickerRef = datepicker }} 
                      locale="ko" 
                      selected={startDate} 
                      onChange={date => {
                        setStartDate(date);
                        setOpenDate(moment(date).format('YYYY-MM-DD'));
                      }}
                    />
                      <Img_calendar_ov
                        src={calendar_ov_img}
                        resizeMode={'contain'}
                        onClick={()=>{
                          datePickerRef.setFocus();
                        }}
                      />
                  </Btn_open_date>
                </View_input_wrap>
              </ShadowComponent>

              <div style={{height: Const.getRateHeight(5)}} />
              <Wrap_timer>
                <Timer_outer>
                  <ShadowComponent>
                    <Timer_inner>
                      <Img_time2
                        src={time2_img}
                        resizeMode={'contain'}
                      />
                      <Touchable_btn_time onClick={()=>{handleFocusedChange(true)}}>
                        <Text_time textColor={startTimeColor}>
                          {startHour}
                        </Text_time>
                        <Text_colon>:</Text_colon>
                        <Text_time textColor={startTimeColor}>
                          {startMinute}
                        </Text_time>
                      </Touchable_btn_time>
                    </Timer_inner>
                  </ShadowComponent>
                </Timer_outer>

                <Text_wave>~</Text_wave>

                <Timer_outer>
                  <ShadowComponent>
                    <Timer_inner>
                      <Img_time2
                        src={time2_img}
                        resizeMode={'contain'}
                      />
                      <Touchable_btn_time onClick={()=>{handleFocusedChange(false)}}>
                        <Text_time textColor={endTimeColor}>
                          {endHour}
                        </Text_time>
                        <Text_colon>:</Text_colon>
                        <Text_time textColor={endTimeColor}>
                          {endMinute}
                        </Text_time>
                      </Touchable_btn_time>
                    </Timer_inner>
                  </ShadowComponent>
                </Timer_outer>
              </Wrap_timer>
              
              <Timer_inner style={{marginLeft:10, height:0}}>
                <TimePicker
                  ref={(ref)=>{startTimePickerRef=ref}}
                  onFocusChange={()=>{}}
                  withoutIcon
                  onTimeChange={(val)=>{ 
                    console.log("val : " + JSON.stringify(val));
                    setStartTime(val.hour + ":" + Util.makeTwoNumberStr(val.minute));
                    setStartHour(val.hour);
                    setStartMinute(val.minute);
                  }}
                  timeMode="24"
                  theme="material"
                  time={startTime}
                  trigger={(
                    <Time_picker_trigger
                      onClick={ () => {
                        handleFocusedChange(true)
                      }}>
                    </Time_picker_trigger>
                  )}
                />
              </Timer_inner>
              <Timer_inner style={{marginLeft:10, height:0}}>
                <TimePicker
                  ref={(ref)=>{endTimePickerRef=ref}}
                  onFocusChange={()=>{}}
                  withoutIcon
                  onTimeChange={(val)=>{ 
                    console.log("val : " + JSON.stringify(val));
                    setEndTime(val.hour + ":" + Util.makeTwoNumberStr(val.minute));
                    setEndHour(val.hour);
                    setEndMinute(val.minute);
                  }}
                  timeMode="24"
                  theme="material"
                  time={endTime}
                  trigger={(
                    <Time_picker_trigger
                      onClick={ () => {
                        handleFocusedChange(false)
                      }}>
                    </Time_picker_trigger>
                  )}
                />
              </Timer_inner>

              <ShadowComponent>
                <View_input_wrap selectbox>
                  <RNPickerSelectComponent
                    value={groupValue}
                    items={groupItems}
                    onValueChange={(value) => {
                      setGroupValue(value);
                    }}
                    style={{
                      placeholder: {color: groupValue == 0 ? '#bbb' : '#000'},
                    }}
                    placeholder={{
                      label: groupValue == '' ? '참여자를 지정합니다.' : groupValue,
                      value: groupValue,
                      color: '#ff4784',
                    }}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <ShadowComponent>
                <View_input_wrap selectbox>
                  <RNPickerSelectComponent
                    value={codec}
                    items={codecItems}
                    onValueChange={(value) => {
                      setCodec(value);
                    }}
                    style={{
                      placeholder: {color: codec == 0 ? '#bbb' : '#000'},
                    }}
                    placeholder={{
                      label: codec == '' ? '코덱을 지정합니다.' : codec,
                      value: codec,
                      color: '#ff4784',
                    }}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <Line_mid></Line_mid>

              <Text_title>온라인 교실 시작 전 알림</Text_title>

              <ShadowComponent>
                <View_input_wrap selectbox startBefore>
                  <RNPickerSelectComponent
                    value={noticeValue}
                    items={noticeItems}
                    paddingLeft={50}
                    onValueChange={(value) => {
                      setNoticeValue(value);
                    }}
                    style={{
                      placeholder: {color: noticeValue == 0 ? '#bbb' : '#000'},
                    }}
                  />
                  <Img_alim_icon
                    src={alim_icon_img}
                    resizeMode={'contain'}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <div style={{height: Const.getRateHeight(20)}} />

              <Text_Info>
                ※ 온라인 교실 시작을 학부모님의 스마트폰에 알려줍니다.
              </Text_Info>

              <div style={{height: Const.getRateHeight(40)}} />

              <ButtonComponent
                text="온라인 교실 개설"
                buttonClick={clickOpenButton}
              />
            </Wrap>
          </Container>
        </div>
      </div>
    </>
  );
}

/*******************************************************************************************************
 * Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  padding-bottom: 50px;
`;

const Wrap = styled.div`
  padding: 0 16px;
  box-sizing:border-box;
`;

// 최상단 헤더 영역
const Line_mid = styled.div`
  height: 1px;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px dotted #ddd;
`;

const Text_Info = styled.span`
  font-size:0.82em;

`;

const Text_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  margin:28px 0 20px 0;
  display:block;
`;

const Wrap_timer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Img_time2 = styled.img`
  width: 36px;
  height: 36px;
`;

const Time_picker_trigger = styled.div`
  width: 150px;
  height:0px;
  margin-top:30px;
  margin-left:150px;
  background:#000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Touchable_btn_time = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Timer_outer = styled.div`
  width: 46%;
  position: relative;
  height: 56px;
  padding:0 7px;
  box-sizing:border-box;
  border-radius:5px;
  margin-top:8px;
  line-height:56px;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
`;

const Timer_inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-right: ${Const.getRateWidth(10)}px;
`;

const Text_wave = styled.span`
  font-size: 14px;
  width: 8%;
  text-align: center;
`;

const Text_colon = styled.span`
  color: #000;
  font-size: 20px;
  margin: 0 10px;
`;

const Text_time = styled.span`
  color: #000;
  font-size:1.15em;
  letter-spacing: 1px;

  ${(props) =>
    props.textColor == true &&
    css`
      color: #000;
      font-weight: 500;
    `}
`;

const View_input_wrap = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  padding:0 7px;
  box-sizing:border-box;
  border-radius:5px;
  margin-top:8px;
  line-height:56px;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  

  ${(props) =>
    props.selectbox &&
    css`
      border:none;
      box-shadow:none;
      padding:0 ;
    `}

  ${(props) =>
    props.startBefore &&
    css`
    
    `}
`;

const Img_alim_icon = styled.img`
  width: 40px;
  height:40px;
  position: absolute;
  top:8px;
  left: 0px;
`;

const Input_common = styled.input`
  width: 100%;
  /* height:56px; */
  /* line-height:56px; */
  font-size: 0.91em;
  border:none;

  ${(props) => props.titleInput && css`
    width:95%
  `}
`;


const DatePickerUICustom = styled.div`
  width: 100%;
  font-size: 0.91em;
  border:none;
  height:40px;
  color:#000;
  
  ${(props) => props.datePickerInput == false && css`
    color:#767676;
  `}
`;


const Btn_open_date = styled.div``;

const Img_calendar_ov = styled.img`
  z-index: 1;
  position: absolute;
  width:44px;
  height:44px;
  right: 7px;
  top: 4px;
`;

/*******************************************************************************************************
 * Style end
 *******************************************************************************************************/