import React from 'react';
//import HttpsRequester from './HttpsRequester';
import _requestHttpsPost from './HttpsPostRequester';
import axios from 'axios';
import Util from "./Util";

var ServerRequestManager = new function() {
  // 함수
  this._requestGet = _requestGet; 
  this._requestPost = _requestPost;
  this._requestPostWithImg = _requestPostWithImg;

  this.requestInviteCode      = requestInviteCode;      // 초대 코드 확인하는 메소드
  this.requestCheckId         = requestCheckId ;        // 아이디 체크
  this.requestSendEmailAuth   = requestSendEmailAuth ;  // 인증코드 발송
  this.requestCheckEmailAuth  = requestCheckEmailAuth ; // 인증코드 검증
  this.requestSignUp          = requestSignUp ;         // 회원가입
  this.requestLogin           = requestLogin ;          // 로그인
  this.requestLogout          = requestLogout ;         // 로그아웃
  this.requestChangePw        = requestChangePw ;       // 비밀번호 변경
  this.requestGetUserInfo     = requestGetUserInfo;     // 내 정보
  this.requestUpdateUserInfo  = requestUpdateUserInfo ; // 정보 수정
  this.requestUpdateAlarmInfo = requestUpdateAlarmInfo; // 알람 정보 수정
  this.requestChildCareInfo   = requestChildCareInfo;   // 어린이집 정보 요청
  this.requestCreateRoom      = requestCreateRoom ;     // 온라인 교실 개설
  this.requestRoomList        = requestRoomList ;       // 온라인 교실 목록
  this.requestJoinRoom        = requestJoinRoom ;       // 온라인 교실 입장
  this.requestLeaveRoom       = requestLeaveRoom;       // 온라인 교실 퇴장
  this.requestParticipantList = requestParticipantList; // 참여자 리스트
  this.requestCloseRoom       = requestCloseRoom ;      // 방 종료 요청 메소드
  this.requestCancelRoom      = requestCancelRoom ;     // 개설 취소

  this.requestEditRoom        = requestEditRoom ;       // 개설 수정
  this.requestNaverId         = requestNaverId;

  this.requestWebViewPostMessage = requestWebViewPostMessage;
  this.requestChangePage = requestChangePage;
  
  // 변수
  this.URL_REQ = 'https://ntm.nanoit.kr/meet/api/ex.php';
  this.URL_IMG_PATH = 'https://ntm.nanoit.kr/upload/'; // 이미지 경로

  /**
   * 각종 타입들
   */
  this.IO_SUCCESS = 0;
  this.IO_ERROR = -1;

  /* 요청결과 */
  this.REQ_RESULT_SUCCESS = "+OK";  // 요청 성공
  this.REQ_RESULT_FAIL = "-ERR";    // 요청 실패

  /* 회원타입 */
  this.MEMBER_TYPE_TEACHER = 1;  // 원장 / 선생님
  this.MEMBER_TYPE_PARENTS = 2;  // 학부모

  /* 선생님 타입 */
  this.TEACHER_TYPE_NONE = 0;      // 없음
  this.TEACHER_TYPE_PRESIDENT = 1; // 원장
  this.TEACHER_TYPE_TEACHER = 2;   // 선생님

  /* 학부모 타입 */
  this.PARENTS_TYPE_NONE = 0;      // 없음
  this.PARENTS_TYPE_PARENTS = 1;   // 부모님

   /* 아이디 타입 */
  this.ID_TYPE_NONE = "none";
  this.ID_TYPE_KAKAO = "kakao";
  this.ID_TYPE_NAVER = "naver";
  this.ID_TYPE_GOOGLE = "google";

  /* 방 상태 */
  this.ROOM_STATUS_RESERVATION = "예약";
  this.ROOM_STATUS_PREPARE     = "준비중";
  this.ROOM_STATUS_LIVE        = "입장";
  this.ROOM_STATUS_END         = "종료";
  this.ROOM_STATUS_CANCEL      = "취소";

   /* 알람 상태 */
   this.ALARM_ON = 1;   // 사용
   this.ALARM_OFF = 2;  // 미사용
};
export default ServerRequestManager

/* ************************************************************ */


// GET 요청
function _requestGet(data, callback) {
  return axios({
    method: 'get',
    url: ServerRequestManager.URL_REQ,
    data:JSON.stringify(data)
  })
  .then(res => {
    callback( ServerRequestManager.IO_SUCCESS, res.data);
  })
  .catch(error => {
    callback( ServerRequestManager.IO_ERROR, error);
  }); 
}

// POST 요청
function _requestPost(callback, data) {

  console.log("_requestPost : " + JSON.stringify(data));
  return axios({
    method: 'post',
    url: ServerRequestManager.URL_REQ,
    data:data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
  .then(res => {
    res.data.req_type = data.req_type;

    //console.log("io success result data : " + JSON.stringify(res.data));

    if( res.data.result == ServerRequestManager.REQ_RESULT_FAIL ) {
      Util.showToast(res.data.result_msg);
    }

    callback( ServerRequestManager.IO_SUCCESS, res.data);
  })
  .catch(error => {
    console.log("io failed error : " + error);

    if( error === "Network Error" ) {
      Util.showNetworkErrorToast();
    }
    //callback( ServerRequestManager.IO_ERROR, error);
  });
}

function _requestPostWithImg( callback, data ) {
  return axios({
    method: 'post',
    url: ServerRequestManager.URL_REQ,
    data:data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
  .then(res => {
    res.data.req_type = data.req_type;

    //console.log("io success result data : " + JSON.stringify(res.data));

    if( res.data.result == ServerRequestManager.REQ_RESULT_FAIL ) {
      Util.showToast(res.data.result_msg);
    }

    callback( ServerRequestManager.IO_SUCCESS, res.data);
  })
  .catch(error => {
    console.log("io failed error : " + error);

    if( error === "Network Error" ) {
      Util.showNetworkErrorToast();
    }
    //callback( ServerRequestManager.IO_ERROR, error);
  });
}

function _makeJsonData( req_type, req_data ) {
  const data = {
    req_type:req_type,
    req_data:req_data
  }
  return data;
}

/***********************************************************************
 * 
 * 각 요청 메소드
 *
 ***********************************************************************/

/**
 * 초대 코드 확인하는 메소드
 * @param {*} callback 
 * @param {*} code 
 */
function requestInviteCode( callback, code ) {
  const json_data = _makeJsonData( "code_ck", 
    { 
      code:code // 참여코드
    }
  ); 
  _requestPost( callback, json_data );
}

/**
 * 아이디 체크
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 */
function requestCheckId( callback, id_type, id ) {
  const json_data = _makeJsonData( "id_ck", 
    { 
      id_type:id_type,          // 아이디 타입 : none, kakao, naver, google
      id:id                     // 아이디(이메일형식)
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 인증코드 발송
 * @param {*} callback 
 * @param {*} id 
 */
function requestSendEmailAuth( callback, id ) {
  const json_data = _makeJsonData( "email_auth", 
    { 
      proc:"send",  // 타입
      id:id         // 아이디(이메일형식)
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 인증코드 검증
 * @param {*} callback 
 * @param {*} id 
 * @param {*} auth_code 
 */
function requestCheckEmailAuth( callback, id, auth_code ) {
  const json_data = _makeJsonData( "email_auth", 
    { 
      proc:"check",       // 타입
      id:id,              // 아이디(이메일형식)
      auth_code:auth_code // 인증코드
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 회원가입
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} code 
 * @param {*} id 
 * @param {*} pw 
 * @param {*} data 
 */
function requestSignUp( callback, code, id_type, id, pw, member_img, member_name, member_alias, data, id_token ){

  if(id_type=='none') {
    var jsonData = {
      code:code,                // 참여 코드
      id_type:id_type,          // 아이디 타입 : none, kakao, naver, google
      id:id,                    // 아이디(이메일형식)
      pw:pw,                    // 비밀번호
      member_name:member_name,  // 이름
      member_alias:member_alias,// 원장/선생님 인경우 : 0=없음, 1=원장, 2=선생님 , 학무모 인경우 : 0=없음, 1=부모님
      id_token:id_token
    }
  }else{
    var jsonData = {
      code:code,                // 참여 코드
      id_type:id_type,          // 아이디 타입 : none, kakao, naver, google
      id:id,                    // 아이디(이메일형식)
      member_name:member_name,  // 이름
      member_alias:member_alias,// 원장/선생님 인경우 : 0=없음, 1=원장, 2=선생님 , 학무모 인경우 : 0=없음, 1=부모님
      id_token:id_token
    }
  }

  

  if( member_img ) {
    jsonData['member_img'] = member_img;
  }

  if( data ) {
    console.log("in data");
    let keys = Object.keys(data);
    console.log("in keys length : " + keys.length);
    for( var i=0; i<keys.length; i++) {
      console.log("key : " + keys[i]);
      jsonData[keys[i]] = data[keys[i]];
    }
  }

  /*
  // 원장/선생일 경우
  if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
    jsonData["childcare_name"] = data.childcare_name;   // 어린이집명
    jsonData["childcare_class"] = data.childcare_class; // 어린이집 학급정보 - 콤마로 구문 ex) 햇님반,별빛반
    jsonData["childcare_logo"] = data.childcare_logo;   // 어린이집 로고 이미지
    jsonData["childcare_img1"] = data.childcare_img1;   // 어린이집 이미지1
    jsonData["childcare_img2"] = data.childcare_img2;   // 어린이집 이미지2
    jsonData["childcare_img3"] = data.childcare_img3;   // 어린이집 이미지3
  }
  // 학무모일 경우
  else if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
    jsonData["children_data"] = data.children_data; // 자녀 정보 - array
    // ex) 
    //  array('code'=>'FNPKBY', 'name'=>'홍길동1', 'gender'=>'남', 'birthday'=>'2018', 'class'=>'햇님반'),
    //  array('code'=>'7O69YR', 'name'=>'홍길동2', 'gender'=>'여', 'birthday'=>'', 'class'=>'')
  }
  */
  
  const json_data = _makeJsonData( "join", jsonData);
  _requestPost( callback, json_data );
}

/**
 * 로그인
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} pw 
 * @param {*} fcm_token 
 */
function requestLogin( callback, id_type, id, pw, fcm_token, snsToken ) {

  if( fcm_token == null || fcm_token == "" ) fcm_token = "none_token";

  if(snsToken){
    var json_data = _makeJsonData( "login", 
      { 
        id_type:id_type,          // 아이디 타입 : none, kakao, naver, google
        id:id,                    // 아이디(이메일형식)
        device:"pc",              // 로그인 디바이스 
        fcm_token:fcm_token,      // FCM 토큰 - PUSH 알림용
        id_token:snsToken,        // sns access token
      }
    );
  }else{ 
    var json_data = _makeJsonData( "login", 
      { 
        id_type:id_type,          // 아이디 타입 : none, kakao, naver, google
        id:id,                    // 아이디(이메일형식)
        pw:pw,                    // 비밀번호
        device:"pc",              // 로그인 디바이스 
        fcm_token:fcm_token,      // FCM 토큰 - PUSH 알림용
      }
    );
  }
  _requestPost( callback, json_data );
}

/**
 * 로그아웃
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 */
function requestLogout( callback, id, sess_id ) {
  const json_data = _makeJsonData( "logout", 
    { 
      id:id,                    // 아이디(이메일형식)
      sess_id:sess_id           // 로그인 세션 ID
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 비밀번호 변경
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} pw 
 */
function requestChangePw( callback, id, pw ) {
  const json_data = _makeJsonData( "pw_update", 
    { 
      //member_type:member_type,  // 회원 타입
      id:id,                    // 아이디(이메일형식)
      pw:pw                     // 비밀번호(8~20자리, 영문,숫자,특수문자 포함)
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 내정보
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} data 
 */
function requestGetUserInfo( callback, id, sess_id ){
  var params = {
    id:id,                    // 아이디(이메일형식)
    sess_id:sess_id           // 로그인 세션 ID
  }
  
  const json_data = _makeJsonData( "get_me", params);
  _requestPost( callback, json_data );
}

/**
 * 정보 수정
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} data 
 */
function requestUpdateUserInfo( callback, member_type, id, sess_id, pw, member_img, member_name, member_alias, data ){

  var params = {
    member_type:member_type,  // 회원 타입
    id:id,                    // 아이디(이메일형식)
    sess_id:sess_id,          // 로그인 세션 ID
    member_name:member_name,  // 이름
    member_alias:member_alias // 원장/선생님 인경우 : 0=없음, 1=원장, 2=선생님 , 학무모 인경우 : 0=없음, 1=부모님
  }

  if( member_img != null && member_img != "" ) {
    params['member_img'] = member_img;
  }

  if( pw != null && pw != "" ) {
    params['pw'] = pw;
  }

  // 원장/선생일 경우
  if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER && data != null ) {
    if( data.childcare_name != null )   params["childcare_name"] = data.childcare_name;   // 어린이집명
    if( data.childcare_class != null )  params["childcare_class"] = data.childcare_class; // 어린이집 학급정보 - 콤마로 구문 ex) 햇님반, 별빛반
    if( data.childcare_logo != null )   params["childcare_logo"] = data.childcare_logo;   // 어린이집 로고 이미지
    if( data.childcare_img1 != null )   params["childcare_img1"] = data.childcare_img1;   // 어린이집 이미지1
    if( data.childcare_img2 != null )   params["childcare_img2"] = data.childcare_img2;   // 어린이집 이미지2
    if( data.childcare_img3 != null )   params["childcare_img3"] = data.childcare_img3;   // 어린이집 이미지3
    /*params["childcare_img1"] = data.childcare_img1;   // 어린이집 이미지1
    params["childcare_img2"] = data.childcare_img2;   // 어린이집 이미지2
    params["childcare_img3"] = data.childcare_img3;   // 어린이집 이미지3*/
  }
  // 학무모일 경우
  else if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS && data != null ) {
    if( data.children_data != null ) params["children_data"] = data.children_data; // 자녀 정보 - array
    // ex) 
    //  array('code'=>'FNPKBY', 'name'=>'홍길동1', 'gender'=>'남', 'birthday'=>'2018', 'class'=>'햇님반'),
    //  array('code'=>'7O69YR', 'name'=>'홍길동2', 'gender'=>'여', 'birthday'=>'', 'class'=>'')
  }
  
  const json_data = _makeJsonData( "mod", params);
  _requestPost( callback, json_data );
}

/**
 * 알람 정보 수정
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} alarm 
 * @param {*} alarm_disable_time 
 * @param {*} alarm_disable_st 
 * @param {*} alarm_disable_ed 
 */
function requestUpdateAlarmInfo( callback, member_type, id, sess_id, alarm, alarm_disable_time, alarm_disable_st, alarm_disable_ed ){

  var params = {
    member_type:member_type,  // 회원 타입
    id:id,                    // 아이디(이메일형식)
    sess_id:sess_id,          // 로그인 세션 ID
    alarm:alarm,                            // 알림여부 1:사용, 2:미사용
    alarm_disable_time:alarm_disable_time,  // 알림 비활성화 시간 사용 여부 1:사용, 2:미사용
    alarm_disable_st:alarm_disable_st,      // 알림 비활성화 시작시간 - ex) 22:00
    alarm_disable_ed:alarm_disable_ed       // 알림 비활성화 종료시간 - ex) 08:00
  }
  
  const json_data = _makeJsonData( "mod", params);
  _requestPost( callback, json_data );
}

/**
 * 어린이집 정보 요청
 * 
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} childcare_num 
 */
function requestChildCareInfo( callback, id, sess_id, childcare_num ) {
  var params = {
    id            :id,            // 아이디(이메일형식)
    sess_id       :sess_id        // 로그인 세션 ID
  }

  if( childcare_num ) {
    params["childcare_num"] = childcare_num; // 어린이집 고유번호 ( 없는경우 array 로 전체를 return)
  }

  const json_data = _makeJsonData( "get_childcare", params );
  _requestPost( callback, json_data );
}

/**
 * 온라인 교실 개설
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} subject 
 * @param {*} ymd 
 * @param {*} st_date 
 * @param {*} ed_date 
 * @param {*} class_type 
 * @param {*} alarm 
 */
function requestCreateRoom( callback, id, sess_id, subject, ymd, st_date, ed_date, class_type, alarm, codec ) {
  const json_data = _makeJsonData( "r_create", 
    { 
      id          :id,          // 아이디(이메일형식)
      sess_id     :sess_id,     // 로그인 세션 ID
      subject     :subject,     // 제목
      ymd         :ymd,         // 날짜 ex) 20210408
      st_date     :st_date,     // 시작시간 ex) 1800
      ed_date     :ed_date,     // 종료시간 ex) 1800
      class_type  :class_type,  // 수업종류 => 1= 1:1 원격수업, 2=참관수업
      alarm       :alarm,       // 시작전알림 => 0=미사용, 1=1시간전, 2=10분전
      codec       :codec        // 코덱
    }
  );
  _requestPost( callback, json_data );
}

/**
 * 온라인 교실 목록
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} ym 
 * @param {*} childcare_num 
 */
function requestRoomList( callback, member_type, id, sess_id, ym, childcare_num, ) {
  var params = {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id      // 로그인 세션 ID
  }
  
  if( ym != undefined && ym != null && ym != "") {
    params["ym"] = ym;
  }

  if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
    params["childcare_num"] = childcare_num;
  }

  const json_data = _makeJsonData( "r_list", params);
  _requestPost( callback, json_data );
}

/**
 * 방입장
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 * @param {입장 후 두번째 요청시에만 포함} socket_id 
 */
function requestJoinRoom( callback, id, sess_id, rid, socket_id ) {
  var params = {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 로그인 세션 ID
    rid         :rid         // room.id
  }
  
  if( socket_id != undefined && socket_id != null && socket_id != "") {
    params["socket_id"] = socket_id;
  }

  const json_data = _makeJsonData( "r_join", params);
  _requestPost( callback, json_data );
}

/**
 * 방 퇴장
 * @param {*} callback 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 */
function requestLeaveRoom( callback, id, sess_id, rid ) {
  const json_data = _makeJsonData( "r_out", 
  {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 로그인 세션 ID
    rid         :rid          // 방 번호
  });
  _requestPost( callback, json_data );
}

/**
 * 참여자 리스트
 * @param {*} callback 
 * @param {*} member_type 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 * @param {*} socket_id 
 */
function requestParticipantList( callback, id, sess_id, rid ) {
  const json_data = _makeJsonData( "r_participant", 
  {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 세션 아이디
    rid         :rid         // room.id
  });
  _requestPost( callback, json_data );
}

/**
 * 방 종료 요청 메소드
 * @param {*} callback 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 */
function requestCloseRoom( callback, id, sess_id, rid ) {
  const json_data = _makeJsonData( "r_delete", 
  {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 세션 아이디
    rid         :rid         // room.id
  });
  _requestPost( callback, json_data );
}

/**
 * 개설 취소
 * @param {*} callback 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 */
function requestCancelRoom( callback, id, sess_id, rid ) {
  const json_data = _makeJsonData( "r_cancel", 
  {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 세션 아이디
    rid         :rid         // room.id
  });
  _requestPost( callback, json_data );
}

/**
 * 개설 수정
 * @param {*} callback 
 * @param {*} id 
 * @param {*} sess_id 
 * @param {*} rid 
 * @param {*} data 
 */
function requestEditRoom( callback, id, sess_id, rid, data ) {

  var jsonData = {
    id          :id,          // 아이디(이메일형식)
    sess_id     :sess_id,     // 세션 아이디
    rid         :rid         // room.id
  }

  if( data ) {
    console.log("in data");
    let keys = Object.keys(data);
    console.log("in keys length : " + keys.length);
    for( var i=0; i<keys.length; i++) {
      console.log("key : " + keys[i]);
      jsonData[keys[i]] = data[keys[i]];
    }
  }

  const json_data = _makeJsonData( "r_mod", jsonData);
  _requestPost( callback, json_data );
}


/**
 * 웹뷰 메시지 전송
 * @param {*} data 
 * @returns 
 */
function requestWebViewPostMessage(window, data){


  //let wReactNativeWebView = ReactNativeWebView == undefined ? 'undefined' : window.ReactNativeWebView;
  if (typeof window.ReactNativeWebView == 'undefined') {
    console.log("ReactNativeWebView is null");
  } else {
    console.log("ReactNativeWebView postMessage send");
    window.ReactNativeWebView.postMessage( JSON.stringify(data) );
  }
}



function requestChangePage( window, pageName ) {
  let userData = {
    req_type:'c_page',
    req_data:{
      page:pageName
    }
  }
  ServerRequestManager.requestWebViewPostMessage( window, userData );
}


/**
 * access token 토큰 재발급 안내 : https://developers.naver.com/forum/posts/1171
 * 로그인 API 명세 : https://developers.naver.com/docs/login/api/api.md
 * 회원정보조회 문서 : https://developers.naver.com/docs/login/api/api.md
 */

function requestNaverId(token){ 
  /* console.log("🚀 ~ file: ServerRequestManager.js ~ line 689 ~ requestNaverId ~ token", token)
  let api_url = 'https://openapi.naver.com/v1/nid/me';
  let header = "Bearer " + encodeURIComponent(token); // Bearer 다음에 공백 추가

  return axios({
    method: 'get',
    url: api_url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': header,
    }
  })
  .then(res => {
    console.log("🚀 ~ file: ServerRequestManager.js ~ line 698 ~ requestNaverId ~ res", res)
  })
  .catch(error => {
    console.log("io failed error : " + error);
  }); */
}