import React, {useState, useEffect} from 'react';
/*import {
  TouchableOpacity,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';*/

import Const from '../util/Const';
// import SnsLogin from '../util/SnsLogin';
import ButtonComponent from '../screen/component/ButtonComponent';
//import Shadow from 'shadows-rn'; 
import ShadowComponent from './component/ShadowComponent'; 
import styled from 'styled-components';
import Util from '../util/Util';
import Validation from '../util/Validation';
import KeyChainStore from '../util/KeyChainStore';

import { withRouter } from "react-router-dom";

import loginImg from '../img/login_img.png';
import moreImg from '../img/more1.png';
import naverImg from '../img/naver1.png';
import kakaoImg from '../img/kakao1.png';
import googleImg from '../img/google1.png';
import ServerRequestManager from '../util/ServerRequestManager';
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core';
import SnsLogin from '../util/SnsLogin';
import NaverLogin from 'react-naver-login';
import KakaoLogin from 'react-kakao-login';
import GoogleLogin from 'react-google-login';
/*******************************************************************************************************
 *
 * 로그인 화면
 *
*******************************************************************************************************/


function BFLoginScreen(props) {
  const [idStr, setIdStr] = useState('');
  const [pwStr, setPwStr] = useState('');
  const [passwordSecured, setPasswordSecured] = useState(true);
  const [fcmToken, setFcmToken] = useState("");
  
  // console.log("Call BFLoginScreen");

  React.useEffect(() => {
    //SnsLogin.init();
    
    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__FCM_TOKEN );
    
    ServerRequestManager.requestChangePage( window, "BFLoginScreen" );

    Util.getToken_setCookie_forNaverLogin();
  }, []);

  const IpcReceiver = (arg) => {
    console.log("BFLoginScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__FCM_TOKEN ) {
        console.log("BFLoginScreen IpcReceiver token : " + arg.data );
        setFcmToken(arg.data);
      }
    } 
  }

  const moveScreen = (member_type) => {
    if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
      props.history.push({ pathname:"/AFMainParentScreen" })
    }
    else if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      props.history.push({ pathname:"/AFMainSchoolScreen" })
    }
  }

  const _clickLoginButton = (snsType, snsId, snsToken) => {
    // alert('이메일 로그인');    
    //아이디 비번 체크 
    if(snsType === ServerRequestManager.ID_TYPE_NONE){
      if( Validation.CheckEmail(idStr) === false ) {
        Validation.MsgCheckEmail();
        return;
      }

      if( Validation.CheckPassword(pwStr) === false ) {
        Validation.MsgCheckPassword();
        return;
      }
    }


    let id_type = snsType == null ? ServerRequestManager.ID_TYPE_NONE : snsType;
    let id = snsId == null ? idStr : snsId;
    let pw = pwStr;
    let fcm_token = fcmToken;
    let sns_token = snsToken;
    
    // 로그인 요청
    ServerRequestManager.requestLogin((result, data) => {
      console.log("requestLogin result result : " + result);
      console.log("requestLogin result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        data.result_data.id = id;
        data.result_data.pw = pw;
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA, data.result_data);
        
        var member_type = data.result_data.member_type;
        var sess_id = data.result_data.sess_id;

        var receiveUserInfo = false;
        var receiveChildcareInfo = false;
        var childcare_num = null;

        // 웹뷰 메시지 전송
        let userData = {
          req_type:'login',
          req_data:{
            id:id,
            pw:pw,
            member_type:member_type,
            sess_id:sess_id
          }
        }
        ServerRequestManager.requestWebViewPostMessage( window, userData );

        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

            receiveUserInfo = true;

            if( receiveChildcareInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, id, sess_id);        

        // 어린이집 정보 요청
        ServerRequestManager.requestChildCareInfo((result, data) => {
          console.log("requestChildCareInfo result result : " + result);
          console.log("requestChildCareInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);
    
            receiveChildcareInfo = true;

            if( receiveUserInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, id, sess_id, childcare_num);
      }
    }, id_type, id, pw, fcm_token, sns_token);
  };



  const _clickSnsLoginKakao = (e) => {
    const { id } = e.profile;
    const { access_token } = e.response;
    
    _clickLoginButton(ServerRequestManager.ID_TYPE_KAKAO, id, access_token);
  };

  const _clickSnsLoginNaver = (e) => {
    const { id } = e;
    const get_naver_token = Util.getCookie('access_token');

    _clickLoginButton(ServerRequestManager.ID_TYPE_NAVER, id, get_naver_token);

    Util.deleteCookie('access_token');
  };
  
  const _clickSnsLoginGoogle = (e) => {
    const google_id = e.Ft.NT;
    const access_token = e.accessToken;
    // console.log("🚀 ~ file: BFLoginScreen.js ~ line 172 ~ BFLoginScreen ~ googleid", e)

    _clickLoginButton(ServerRequestManager.ID_TYPE_GOOGLE, google_id, access_token);
  };



  /* 
  const login = (snsType) => {
    if (snsType != null) {
      SnsLogin.login(snsType);
      
      Util.showToast("SNS 로그인은 아직 준비중입니다.");
      props.history.push({
        pathname:"/AFMainSchoolScreen"
      })
    }
  };
  */

  const _clickFindUserData = () => {
    props.history.push({
      pathname:"/BFSearchPwdScreen"
    })

    //navigation.navigate('BFSearchPwdScreen');
    // console.log(navigation);
  };

  var pwTextInput = null;


  return (
    <div style={{backgroundColor:"#fff"}}>
      <Container>
        <Container_top>
          <Text_top_title>반갑습니다.</Text_top_title>
          <Text_top_subTitle>
            유아 교육 전용 온라인 교실<br></br> {'\n'}e 코앤코에 오신 것을 환영합니다.
          </Text_top_subTitle>
        </Container_top>

        <Container_mid>
          <Img_login_img
            src={loginImg}
            resizeMode={'contain'}
          />
          <ShadowComponent
            shadows={[{offsetX: 0, offsetY: 0, radius: 20, color: '#eee'}]}
            inset={false}
          >
            <ShadowInner>
              <Input_id
                placeholder="아이디(이메일)"
                placeholderTextColor="#777"
                keyboardType="email-address"
                onChange={(e) => setIdStr(e.target.value)}
                /*onSubmitEditing={() => { // TODO 완료버튼 선택시 => Enter 선택시로 재 구현
                  pwTextInput.focus();
                }}*/
                value={idStr}
                //autoFocus={true}
                />

              <Line_mid></Line_mid>

              <Input_pw
                placeholder="비밀번호"
                placeholderTextColor="#777"
                textContentType="password"
                type="password"
                // secureTextEntry={passwordSecured} // 이건 뭔지 파악안됨
                onChange={(e) => setPwStr(e.target.value)}
                // onSubmitEditing={_clickLoginButton} // TODO 완료버튼 선택시 => Enter 선택시로 재 구현
                value={pwStr}
                onKeyUp={(e)=>{
                  if( e.keyCode == 13) { // 키보드 Enter 클릭
                    _clickLoginButton();
                  }
                }}
              />
            </ShadowInner>
          </ShadowComponent>
          <div style={{height: 30}} />
          <ButtonComponent
            text="이메일 로그인"
            buttonClick={_clickLoginButton}
          />
        </Container_mid>

        
        <Container_btm>
          <Touchable_btn onClick={_clickFindUserData}>
            <Touchable_btn_text>계정 정보를 잊으셨나요?</Touchable_btn_text>
            <Img_more1
              src={moreImg}
              resizeMode={'contain'}
            />
          </Touchable_btn>
          <Container_btm_sns>
            <NaverLogin 
              clientId={SnsLogin.NAVER_CLIENT_ID}
              callbackUrl={window.location.href}
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}><Img_sns src={naverImg} resizeMode={'contain'} /></div>
              )}
              onSuccess={(e) => _clickSnsLoginNaver(e)}
              onFailure={(result) => console.error(result)}
            />

            <KakaoLogin
              token={SnsLogin.KAKAO_CLIENT_ID}
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}><Img_sns src={kakaoImg} resizeMode={'contain'} /></div>
              )}
              onSuccess={(e) => _clickSnsLoginKakao(e)}
              onFail={console.log}
              onLogout={console.log}
            />
            <GoogleLogin
              clientId={SnsLogin.GOOGLE_CLIENT_ID}
              buttonText="Login"
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}><Img_sns src={googleImg} resizeMode={'contain'} /></div>
              )}
              onSuccess={(e) => _clickSnsLoginGoogle(e)}
              onFailure={console.log}
              cookiePolicy={'single_host_origin'}
            />
              
          </Container_btm_sns>
        </Container_btm>
      </Container>
    </div>
  );
}
export default withRouter(BFLoginScreen)

/*******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  background-color: #fff;
  padding:0 26px;
  box-sizing:border-box;
  padding-bottom:50px;
`;

// 상단 영역
const Container_top = styled.div`
  padding-top: 60px;
  padding-bottom: 0;
`;

const Text_top_title = styled.span`
  font-size:1.7em;
  font-weight: 500;
  display:block;
`;

const Text_top_subTitle = styled.span`
  font-size: 0.94em;
  margin-top:16px;
  display:block;
  line-height:23px;
  color:#666;
`;

// 중간 영역
const Container_mid = styled.div`
  position: relative;
  top: -40px;
`;

const Img_login_img = styled.img`
  width: 100%;
  margin-top:50px;
`;

const Input_id = styled.input`
  height: 56px;
  width: 100%;
  border:none;
  padding:0 2%;
  box-sizing:border-box;
  font-size:1.05em;
`;

const Input_pw = styled.input`
  height: 56px;
  width: 100%;
  border:none;
  padding:0 2%;
  box-sizing:border-box;
  font-size:1.05em;
`;

const Touchable_btn = styled.div`
  margin:40px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align:center;
  cursor:pointer;
`;

// 하단 영역
const Container_btm = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top:-40px;
`;

const Touchable_btn_text = styled.span`
  font-size: 0.91em;
`;

const Img_more1 = styled.img`
  width: 30px;
  margin-left: 20px;
`;

const Container_btm_sns = styled.div`
  width: 90%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display:flex;
  justify-content:space-around;
  margin:0 auto;
`;

const Img_sns = styled.img`
  width: 60px;
  height:60px;
  cursor:pointer;
  
`;

const ShadowInner = styled.div`
  width: 100%;
  height: 120px;
  font-size: 0.91em;
  border-radius:10px;
  border-color:#fff;
  color:#000;
  border:none;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  padding:0 2%;
  box-sizing:border-box;
`;
const Line_mid = styled.div`
  height: 1px;
  margin-top:1px;
  margin-bottom:1px;
  border-bottom:1px dotted #ddd;
`;

/*******************************************************************************************************
 *  Style End
 *******************************************************************************************************/