import React, {useState, useEffect, useRef} from 'react';
import Util from '../util/Util';
import Const from '../util/Const';
 
import styled, {css} from 'styled-components';
import ShadowComponent from './component/ShadowComponent';
import SwitchComponent from './component/SwitchComponent';
//import Shadow from 'shadows-rn'; // boxShadow style npm
//import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DateTimePickerModalComponent from './component/DateTimePickerModalComponent';
import TitleComponent from './component/TitleComponent';
import TimePicker from 'react-times';
import time2_img from '../img/time2.png'
import top_icon1_img from '../img/top_icon1.png'
import top_icon2_img from '../img/top_icon2.png'
import ServerRequestManager from '../util/ServerRequestManager';
import "../css/react-times-custom.css";

/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/
export default function AFSettingScreen(props) {
  // 알림 받기 true / false
  const [isEnabledAll, setIsEnabledAll] = useState(false); // 전체 알림 받기
  const [isDisableTime, setIsDisableTime] = useState(false); // 전체 알림 받기
  /*const [isEnabledRegister, setIsEnabledRegister] = useState(false); // 등록 알림
  const [isEnabledBefore, setIsEnabledBefore] = useState(false); // 시작 전 알림*/

  // 알림 비활성화 시간
  const [startTime, setStartTime] = useState( "22:00" ); // 시작시간
  const [endTime, setEndTime] = useState( "10:00" );     // 종료 시간
  
  const [startHour, setStartHour] = useState(22);       // 시작 시간 : 시
  const [startMinute, setStartMinute] = useState(0); // 시작 시간 : 분
  const [endHour, setEndHour] = useState(10);           // 종료 시간 : 시
  const [endMinute, setEndMinute] = useState(0);     // 종료 시간 : 분

  const [isStartTimeVisible, setIsStartTimeVisible] = useState(false);
  const [isEndTimeVisible, setIsEndTimeVisible] = useState(false);
  const [startTimeColor, setStartTimeColor] = useState(false);
  const [endTimeColor, setEndTimeColor] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loginData, setLoginData] = useState(null);

  let startTimePickerRef = useRef(null);
  let endTimePickerRef = useRef(null);

  useEffect(() => {
    console.log("AFmanagementScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA );

    ServerRequestManager.requestChangePage( window, "AFSettingScreen" );
    
  }, []);

  const IpcReceiver = (arg) => {
    console.log("AFmanagementScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        console.log("AFmanagementScreen IpcReceiver arg.data : " + JSON.stringify(arg.data) );
        let globalData = arg.data;
        let lData = globalData.loginData;
        let uData = globalData.userData;

        setLoginData(lData);
        setUserData(uData);

        setIsEnabledAll(uData.alarm==ServerRequestManager.ALARM_ON);
        setIsDisableTime(uData.alarm_disable_time==ServerRequestManager.ALARM_ON);
        if( uData.alarm_disable_st ) {
          setStartTime(uData.alarm_disable_st);
          setStartHour(uData.alarm_disable_st.split(":")[0]);
          setStartMinute(uData.alarm_disable_st.split(":")[1]);
        }
        if( uData.alarm_disable_ed ) {
          setEndTime(uData.alarm_disable_ed);
          setEndHour(uData.alarm_disable_ed.split(":")[0]);
          setEndMinute(uData.alarm_disable_ed.split(":")[1]);
        }
      }
    } 
  }

  const reqChangeUserAlarmData = ( enabledAll, disableTime, startTime, endTime ) => {

    let member_type = loginData.member_type;
    let id = loginData.id;
    let sess_id = loginData.sess_id;
    let alarm = enabledAll?ServerRequestManager.ALARM_ON:ServerRequestManager.ALARM_OFF;
    let alarm_disable_time = disableTime?ServerRequestManager.ALARM_ON:ServerRequestManager.ALARM_OFF;
    let alarm_disable_st = startTime;
    let alarm_disable_ed = endTime;
    
    ServerRequestManager.requestUpdateAlarmInfo( (result, data) => {
      console.log("requestUpdateAlarmInfo result result : " + result);
      console.log("requestUpdateAlarmInfo result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        
        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);
          }
        }, id, sess_id);  
      }
    }, member_type, id, sess_id, alarm, alarm_disable_time, alarm_disable_st, alarm_disable_ed );
  }

  // 비활성화 시간 - 시작
  const showStartTime = () => {
    setIsStartTimeVisible(true);
  };
  const hideStartTime = () => {
    setIsStartTimeVisible(false);
  };
  const ConfirmStartTime = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();
    setStartHour(hour);
    setStartTime(minute);
    setStartTimeColor(true);
    // console.warn("A date has been picked: ", minute);
    hideStartTime();
  };

  // 비활성화 시간 - 종료
  const showEndTime = () => {
    setIsEndTimeVisible(true);
  };
  const hideEndTime = () => {
    setIsEndTimeVisible(false);
  };
  const ConfirmEndTime = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();
    // setEndhour(hour);
    setEndMinute(minute);
    setEndTimeColor(true);
    // console.warn("A date has been picked: ", minute);
    hideEndTime();
  };

  const handleFocusedChange = ( isStartTime ) => {
    if( isStartTime ) {
      startTimePickerRef.onFocus();
    }
    else {
      endTimePickerRef.onFocus();
    }
  }

  return (
      <div>
        <div>
          <Container>
            <TitleComponent history={props.history} title={'설정'} />
            {/* 프로필 영역
            <Layout_view_top>
              <TouchAble_btn_goback onClick={() => props.history.goBack()}>
                <Img_top_icon2
                  src={top_icon1_img}
                  resizeMode={'contain'}
                />
              </TouchAble_btn_goback>
              <Text_top_title>설정</Text_top_title>
            </Layout_view_top> */}
            {/* End - 프로필 영역 */}


            <Timer_inner_popup style={{marginLeft:10, height:0}}>
              <TimePicker
                ref={(ref)=>{startTimePickerRef=ref}}
                onFocusChange={()=>{}}
                withoutIcon
                onTimeChange={(val)=>{ 
                  console.log("val : " + JSON.stringify(val));
                  let timeStr = Util.makeTwoNumberStr(val.hour) + ":" + Util.makeTwoNumberStr(val.minute);
                  setStartTime(timeStr);
                  setStartHour(val.hour);
                  setStartMinute(val.minute);

                  reqChangeUserAlarmData( isEnabledAll, isDisableTime, timeStr, endTime );
                }}
                timeMode="24"
                theme="material"
                time={startTime}
                trigger={(
                  <Time_picker_trigger
                    onClick={ () => {
                      handleFocusedChange(true)
                    }}>
                  </Time_picker_trigger>
                )}
              />
            </Timer_inner_popup>
            <Timer_inner_popup style={{marginLeft:10, height:0}}>
              <TimePicker
                ref={(ref)=>{endTimePickerRef=ref}}
                onFocusChange={()=>{}}
                withoutIcon
                onTimeChange={(val)=>{ 
                  console.log("val : " + JSON.stringify(val));
                  let timeStr = Util.makeTwoNumberStr(val.hour) + ":" + Util.makeTwoNumberStr(val.minute);
                  setEndTime(timeStr);
                  setEndHour(val.hour);
                  setEndMinute(val.minute);

                  reqChangeUserAlarmData( isEnabledAll, isDisableTime, startTime, timeStr );
                }}
                timeMode="24"
                theme="material"
                time={endTime}
                trigger={(
                  <Time_picker_trigger
                    onClick={ () => {
                      handleFocusedChange(false)
                    }}>
                  </Time_picker_trigger>
                )}
              />
            </Timer_inner_popup>

            <Wrap>
              {/* 알림 버튼 영역 */}
              <ShadowComponent>
                <Wrap_notice>
                  <Switch_img_box>
                    <Text_notice>온라인 교실 시작 전 알림</Text_notice>
                    <SwitchComponent
                      isEnabled={isEnabledAll}
                      setIsEnabled={setIsEnabledAll}
                      onChange={(state)=>{
                        reqChangeUserAlarmData( state, isDisableTime, startTime, endTime );
                      }}
                    />
                  </Switch_img_box>
                </Wrap_notice>
              </ShadowComponent>
              
              <ShadowComponent>
                <Wrap_notice>
                  <Switch_img_box>
                    <Text_notice>알림 비활성화 시간 사용</Text_notice>
                    <SwitchComponent
                      isEnabled={isDisableTime}
                      setIsEnabled={setIsDisableTime}
                      onChange={(state)=>{
                        reqChangeUserAlarmData( isEnabledAll, state, startTime, endTime );
                      }}
                    />
                  </Switch_img_box>
                </Wrap_notice>
              </ShadowComponent>
              {/* 
              <ShadowComponent
                inset={false}
                shadows={[{offsetX: 0, offsetY: 0, radius: 20, color: '#eee'}]}
                style={{
                  height: Const.getRateHeight(140),
                  borderRadius: 15,
                  backgroundColor: '#fff',
                  marginTop: Const.getRateHeight(10),
                  marginBottom: Const.getRateHeight(20),
                }}>
                <Wrap_notice otherHeight>
                  <Switch_img_box>
                    <Text_notice>온라인 교실 등록 알림</Text_notice>
                    <SwitchComponent
                      isEnabled={isEnabledRegister}
                      setIsEnabled={setIsEnabledRegister}
                    />
                  </Switch_img_box>
                </Wrap_notice>

                <Line_md />

                <Wrap_notice otherHeight>
                <Switch_img_box>
                    <Text_notice>온라인 교실 시작 전 알림</Text_notice>
                    <SwitchComponent
                      isEnabled={isEnabledBefore}
                      setIsEnabled={setIsEnabledBefore}
                    />
                  </Switch_img_box>
                </Wrap_notice>
              </ShadowComponent>
              // 알림 버튼 영역 */}

              <Text_Info>
                ※ 온라인 교실 시작전 푸시 알림 서비스
              </Text_Info>

              <Line_mid></Line_mid>

              {/* 비활성화 시간 선택 영역 */}
              <Text_title>알림 비활성화 시간</Text_title>
              <Wrap_timer>
                <Timer_outer>
                  <ShadowComponent>
                    <Timer_inner>
                      <Img_time2
                        src={time2_img}
                        resizeMode={'contain'}
                      />
                      <Touchable_btn_time  onClick={()=>{handleFocusedChange(true)}}>
                        <Text_time textColor={startTimeColor}>
                          {Util.makeTwoNumberStr(startHour)}
                        </Text_time>
                        <Text_colon>:</Text_colon>
                        <Text_time textColor={startTimeColor}>
                          {Util.makeTwoNumberStr(startMinute)}
                        </Text_time>
                      </Touchable_btn_time>
                      <DateTimePickerModalComponent
                        mode="time"
                        isVisible={isStartTimeVisible}
                        onConfirm={ConfirmStartTime}
                        onCancel={hideStartTime}
                      />
                    </Timer_inner>
                  </ShadowComponent>
                </Timer_outer>

                <Text_wave>~</Text_wave>

                <Timer_outer>
                  <ShadowComponent>
                    <Timer_inner>
                      <Img_time2
                        src={time2_img}
                        resizeMode={'contain'}
                      />
                      <Touchable_btn_time  onClick={()=>{handleFocusedChange(false)}}>
                        <Text_time textColor={endTimeColor}>
                          {Util.makeTwoNumberStr(endHour)}
                        </Text_time>
                        <Text_colon>:</Text_colon>
                        <Text_time textColor={endTimeColor}>
                          {Util.makeTwoNumberStr(endMinute)}
                        </Text_time>
                      </Touchable_btn_time>
                      <DateTimePickerModalComponent
                        mode="time"
                        isVisible={isEndTimeVisible}
                        onConfirm={ConfirmEndTime}
                        onCancel={hideEndTime}
                      />
                    </Timer_inner>
                  </ShadowComponent>
                </Timer_outer>
              </Wrap_timer>

              <Text_Info>
                ※ 설정한 시간동안 알림을 무음으로 표시합니다.
              </Text_Info>
              {/* End - 비활성화 시간 선택 영역 */}
            </Wrap>
          </Container>
        </div>
      </div>
  );
}

/************************************************************************************************************************************************
 * Style Start
 ************************************************************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  padding-bottom: ${Const.getRateHeight(50)}px;
  min-height: ${SCREEN_HEIGHT}px;
`;

const Wrap = styled.div`
  padding:0 16px;
`;

// 최상단 헤더 영역

const Wrap_notice = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;

  ${(props) =>
    props.otherHeight &&
    css`
      height: 50%;
    `}
`;

const Switch_img_box = styled.div`
  position:relative;
  
`;

const Text_notice = styled.span`
  font-size: 0.92em;
`;

const Text_Info = styled.span`
  display:block;
  margin-top:20px;
  display:block;
  font-size:0.82em;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px dotted #ddd;
`;

const Text_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  color: #000;
  display:block;
  margin-top:26px;
  margin-bottom:18px;
`;

const Wrap_timer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Const.getRateHeight(20)}px;
`;

const Img_time2 = styled.img`
  width:33px;
  height:33px;
  position:absolute;
  top:11px;
  left:6px;
`;

const Time_picker_trigger = styled.div`
  width: 150px;
  height:0px;
  margin-top:30px;
  margin-left:150px;
  background:#000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Touchable_btn_time = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @media only screen and (max-width: 360px) {
    justify-content: flex-end;
  }
`;
const Timer_outer = styled.div`
  width: 46%;
`;

const Timer_inner = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  position:relative;
`;

const Timer_inner_popup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-right: ${Const.getRateWidth(10)}px;
`;


const Text_wave = styled.span`
  font-size: 14px;
  width: 8%;
  text-align: center;
`;

const Text_colon = styled.span`
  color: #ccc;
  font-size: 20px;
  margin: 0 10px;
`;

const Text_time = styled.span`
  color: #ccc;
  font-size:1.6em;

  ${(props) =>
    props.textColor == true &&
    css`
      color: #000;
      font-weight: 500;
    `}
`;
/************************************************************************************************************************************************
 * Style end
 ************************************************************************************************************************************************/
