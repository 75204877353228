import React, {useState, useEffect ,useRef} from 'react';
/*import {
  View,
  StatusBar,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';*/

import Const from '../util/Const';
import Util from '../util/Util';
import ServerRequestManager from '../util/ServerRequestManager';
import ButtonComponent from './component/ButtonComponent';
import ShadowComponent from './component/ShadowComponent';
import TitleComponent from './component/TitleComponent';
import ShowPopupChildAddComponent from './component/popup/ShowPopupChildAddComponent';
import ShowPopupChildRemoveComponent from './component/popup/ShowPopupChildRemoveComponent';
import ShowPopupCodeComponent from './component/popup/ShowPopupCodeComponent';
import RNPickerSelectComponent from './component/RNPickerSelectComponent';
import Validation from '../util/Validation';
import styled, {css} from 'styled-components';
import ImageSelector from '../util/ImageSelector';

import {useLocation} from "react-router";

import minusImg from '../img/minus.png'
import noImg from '../img/no_img.png'
import cameraImg from '../img/camera.png'
import plusImg from '../img/plus.png'
import tree_img from '../img/tree.png'

const nickNameItems = [
  {label: '없음', value: 0},
  {label: '부모님', value: 1}
];

const groupItems = [
  {id: 0, label: '햇님반1', value: '햇님반1'},
  {id: 1, label: '햇님반2', value: '햇님반2'},
  {id: 2, label: '햇님반3', value: '햇님반3'},
];

const birthItems = [
  {id: 0, label: '2018년생', value: 2018},
  {id: 1, label: '2019년생', value: 2019},
  {id: 2, label: '2020년생', value: 2020}
];

/*******************************************************************************************************
 * 자녀정보입력 폼 추가, 삭제
 *
 * ※ 동적으로 폼을 추가 / 삭제해야 하는 문제 발생.
 *    1. myChild로 폼 개수 체크해서 뿌림.
 *    2. 각 파라미터별로 배열로 여러개의 인풋값을 담아 저장.
 *
 * @param {childDataList}     아이 데이터 리스트
 *******************************************************************************************************/
const ChildInfoList = ({
  childDataList,
  setChildDataList,
  setChildRemovePosition,

}) => {
  const Btn_img_minus_component = ({index}) =>
    childDataList.length > 1 && ( // 폼이 한개면 삭제버튼 안보이게 설정
      <Btn_img_minus
        onClick={() => {
          setChildRemovePosition(index);
        }}>
        <Img_minus src={minusImg} resizeMode={'contain'} />
      </Btn_img_minus>
    );

  const listItems = childDataList.map((child, index) => {

    // 테스트용 데이터
    if( child.schoolName == "" ) child.schoolName = "어린이집";

    return (
      <Container_child_info key={index} index={index}>
        <Container_school_name>
          <Img_logo_box>
            <Img_logo_small src={child.logo} resizeMode={'contain'} />
          </Img_logo_box>
          <Text_school_name>{child.schoolName}</Text_school_name>
          <Btn_img_minus_component 
            index={index}/>
        </Container_school_name>

        <ShadowComponent>
          <View_input_wrap>
            <Input_common
              placeholder="아이 이름을 입력해주세요"
              placeholderTextColor={'#bbb'}
              returnKeyType="next"
              onChange={(e) => {
                let newList = [...childDataList];
                newList[index].name = e.target.value;
                setChildDataList(newList);
              }}
              value={childDataList[index].name}
            />
            <View_wrap_radio>
              <Btn_radio
                onClick={() => {
                  let newList = [...childDataList];
                  newList[index].gender = Const.SEX_TYPE_MALE;
                  setChildDataList(newList);
              }}>
                <Text_radio gender={childDataList[index].gender} male>남아</Text_radio>
              </Btn_radio>
              <Btn_radio
                onClick={() => {
                  let newList = [...childDataList];
                  newList[index].gender = Const.SEX_TYPE_FEMALE;
                  setChildDataList(newList);
              }}>
                <Text_radio gender={childDataList[index].gender} female>여아</Text_radio>
              </Btn_radio>
            </View_wrap_radio>
          </View_input_wrap>
        </ShadowComponent>

        <ShadowComponent>
          <View_input_wrap selectbox>
            <RNPickerSelectComponent
              //key={index}
              items={Util.getBirthItems()}
              value={childDataList[index].birthday}
              onValueChange={(val) => {
                
                let newList = [...childDataList];
                newList[index].birthday = val;
                setChildDataList(newList);
              }}
              parents_style={{
                placeholder: {color: childDataList[index].birthday === 0 ? '#bbb' : '#000'},
              }}
              placeholder={{
                label:
                  childDataList[index].birthday === 0 ? (
                    '출생년도를 선택해주세요'
                  ) : (
                    childDataList[index].birthday + '년생'
                  ),
                value: childDataList[index].birthday,
                color: '#ff4784',
              }}
            />
          </View_input_wrap>
        </ShadowComponent>
        <ShadowComponent>
          <View_input_wrap selectbox>
            <RNPickerSelectComponent
              //key={index}
              items={childDataList[index].classList}
              value={childDataList[index].className}
              onValueChange={(val) => {
                console.log("change val : " + val);
                let newList = [...childDataList];
                newList[index].className = val;
                setChildDataList(newList);
              }}
              parents_style={{
                placeholder: {color: childDataList[index].class === '' ? '#bbb' : '#000'},
              }}
              placeholder={{
                label:
                  childDataList[index].class === '' ? (
                    '반을 선택해주세요'
                  ) : (
                    childDataList[index].class
                  ),
                value: childDataList[index].class,
                color: '#ff4784',
              }}
            />
          </View_input_wrap>
        </ShadowComponent>
      </Container_child_info>
    );
  });
  return listItems;
};

/**
 * 아이 데이터를 만들어 가져오는 메소드
 * @param {*} code 
 * @param {*} schoolName 
 * @param {*} class_data 
 * @returns 
 */
function getChildData( code, schoolName, class_data ) {
  let todayDate = new Date();
  let year = todayDate.getFullYear();
  let classList = Util.makeClassList(class_data)
  return {
    code:code,                    // 초대코드
    schoolName:schoolName,        // 어린이집 이름
    logo:tree_img,                // 어린이집 로고
    name:"",                      // 아이 이름
    gender:1,                     // 성별 => 1:남 , 2:여
    birthday:year,                // 생일년도
    classList:classList,          // 반 데이터 목록
    className:classList[0].value, // 내 아이 반 이름
    classIndex:""                 // 내 아이 반 인덱스 ( 선택된 반 인덱스 )
  }
}

/**
 * 기본 아이 데이터 리스트를 만드는 메소드
 * @returns 
 */
function getDefaultChildDataList() {
  var list = [];
  list.push(getChildData( "", "", ""));
  return list;
}


/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
function BFSignupParentScreen( props ) {

  const [idStr, setIdStr] = useState('');               // 아이디(이메일)
  const [viewIdStr, setViewIdStr] = useState(''); // 아이디(이메일) 화면용
  const [snsToken, setSnsToken] = useState(props.location.state.id_token); // sns 토큰
  const [certiNumStr, setCertiNumStr] = useState(''); // 인증번호 입력
  const [pwStr, setPwStr] = useState('');               // 비밀번호
  const [repwStr, setRePwStr] = useState('');           // 비밀번호 확인
  const [nameStr, setNameStr] = useState('');           // 가입자 이름
  const [nickNameValue, setNickNameValue] = useState('');   // 호칭 선택
  const [imgLogo, setImgLogo] = useState(tree_img);       // 최상단 로고 이미지
  const [imgProfile, setImgProfile] = useState(noImg);  // 추가정보 입력 프로필 이미지
  const [hasImgProfile, setHasImgProfile] = useState(false);  // 추가정보 입력 프로필 이미지안에 이미지가 있는지 체크
  const [codeResultData, setCodeResultData] = useState(null);
  const [checkIdStr, setCheckIdStr] = useState("");   // 중복체크된 아이디

  const [idType, setIdType] = useState(props.location.state.id_type); // 아이디 타입

  // 비밀번호 입력시 화면에서 비밀번호 보이지 않게 숨김
  const [passwordSecured, setPasswordSecured] = useState(true);
  const [passwordConfirmSecured, setPasswordConfirmSecured] = useState(true);

  /* 이미지 관련 */
  const profileImgInputFile = useRef(null);
  const [imgProfileBase64, setImgProfileBase64] = useState(null);

  /************************************************
   * 자녀 정보 입력
   ************************************************/
  const [myChild, setMyChild] = useState([{id: 0}]); // 폼 개수
  const [schoolLogo, setSchoolLogo] = useState([
    {id: 0, url: noImg},
  ]); // 어린이집 로고
  const [childDataList, setChildDataList] = useState(getDefaultChildDataList());

  const [isVisibleModal, setIsVisibleModal] = useState(false);             // '현재기관에추가' 팝업창 show / hide 여부
  const [isVisibleCodeModal, setIsVisibleCodeModal] = useState(false);     // '초대코드입력' 팝업창 show / hide 여부
  const [inviteCode, setInviteCode] = useState(''); // 초대코드 6자리
  const [childRemovePosition, setChildRemovePosition] = useState(-1); // '자녀정보삭제' 팝업창 show /hide 여부
  const [fcmToken, setFcmToken] = useState("");
  

  const location = useLocation();

  useEffect(() => {
    console.log("BFSignupParentScreen useEffect");

    setIdType(location.state.id_type);
    // SNS 타입일경우 idStr 설정
    if( location.state.id_type !== ServerRequestManager.ID_TYPE_NONE ) {
      setIdStr(String(location.state.id));

      if( location.state.id_type==='naver' ) setViewIdStr('네이버 ID 사용');
      if( location.state.id_type==='kakao' ) setViewIdStr('카카오 ID 사용');
      if( location.state.id_type==='google' ) setViewIdStr('구글 ID 사용');
    }

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__FCM_TOKEN);

    ServerRequestManager.requestChangePage( window, "BFSignupParentScreen" );
  }, []);

  const IpcReceiver = (arg) => {
    console.log("BFSignupParentScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT ) {
        setCodeResultData(arg.data);
        setImgLogo(Util.makeShowImageFromUrl( arg.data.logo_path, tree_img));
        
        let classList = Util.makeClassList(arg.data.class_data);
        var newList = [...childDataList];
        newList[0].code = arg.data.code;
        newList[0].schoolName = arg.data.name;
        newList[0].classList = classList;
        newList[0].className = classList[0].value;
        newList[0].logo = Util.makeShowImageFromUrl( arg.data.logo_path, tree_img );
        setChildDataList(newList);
      }
      else if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__FCM_TOKEN ) {
        console.log("BFSignupParentScreen IpcReceiver token : " + arg.data );
        setFcmToken(arg.data);
      }
    } 
  }

  const clickAddChildButton = () => {
    // alert('자녀정보입력 추가')
    setIsVisibleModal(true); // 팝업창 오픈
  };

  // 현재 기관으로 내 아이 추가
  const clickAddChildMyCenter = () => {
    // alert('현재 기관에 추가')

    var newList = [...childDataList];
    let childData = getChildData( codeResultData.code, codeResultData.name, codeResultData.class_data );
    childData.logo = Util.makeShowImageFromUrl( codeResultData.logo_path, tree_img );
    newList.push(childData);
    setChildDataList(newList);

    Util.showToast('자녀정보입력 폼이 추가되었습니다.')
  };

  // 초대코드로 내 아이 추가 팝업창 => '확인'
  const clickCodeButton = () => {
    ServerRequestManager.requestInviteCode( (result, data) => {
      console.log("_requestInviteCode result result : " + result);
      console.log("_requestInviteCode result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        if( data.result_data.member_type === ServerRequestManager.MEMBER_TYPE_TEACHER ) {
          Util.showToast('해당코드는 학부모님 초대 코드가 아닙니다.')
          return;
        }
        var newList = [...childDataList];
        let childData = getChildData( inviteCode, data.result_data.name, data.result_data.class_data )
        childData.logo = Util.makeShowImageFromUrl( data.result_data.logo_path, tree_img );
        newList.push(childData);
        setChildDataList(newList);
        setInviteCode("");
      }
    }, inviteCode);
  };

  // 자녀정보삭제 팝업창 => '삭제'
  const clickChildRemove = () => {

    var newList = [...childDataList];
    newList.splice(childRemovePosition, 1);
    setChildDataList(newList);

    setChildRemovePosition(-1);
  };

  // 자녀정보삭제 팝업창 => '취소'
  const clickRemoveCancel = () => {
    setChildRemovePosition(-1);
  };

  /************************************************
   * end 자녀 정보 입력
   ************************************************/

  // console.log("Call BFSignupParentScreen");

  /*setTimeout( function() {
        console.log("BFSelectSigninScreen setTimeout");
        changeScreenType(Const.HOME_SCREEN_TYPE_INTRO);
    }, 1000 );*/

  const clickSignupButton = () => {
    // alert('회원가입')

    // 필수입력
    if( idType == ServerRequestManager.ID_TYPE_NONE ){
      if( Validation.CheckMust(idStr,pwStr,repwStr) === false ) {
        Validation.MsgCheckMust();
        return;
      }
      // 아이디(이메일)
      if( Validation.CheckEmail(idStr) === false ) {
        Validation.MsgCheckEmail();
        return;
      }
      // 비밀번호
      if( Validation.CheckPassword(pwStr) === false ) {
        Validation.MsgCheckPassword();
        return;
      }
      // 비밀번호 확인
      if( Validation.CheckPasswordConfirm(pwStr, repwStr) === false ) {
        Validation.MsgCheckPasswordConfirm();
        return;
      }
    }


    // 필수입력 + 추가입력 (자녀정보입력 제외)
    console.log(`
      idStr=${idStr},             
      snsToken=${snsToken},
      pwStr=${pwStr},
      repwStr=${repwStr},
      nameStr=${nameStr},
      nickNameValue=${nickNameValue},
      imgLogo=${imgLogo},
      imgProfile=${imgProfile},
    `)

    
    var code = codeResultData.code;
    var id = idStr;
    var pw = pwStr;
    var member_img = null;
    var id_token = snsToken;

    if( imgProfileBase64 ) {
      member_img = imgProfileBase64;
    }
    var member_name = nameStr;
    var member_alias = nickNameValue;

    var reqData = {};

    if( childDataList.length > 0 ) {
      let children_datas = [];
      for( var i=0; i<childDataList.length; i++ ) {
        if( childDataList[i].name ) {
          let className = childDataList[i].className;
          
          if( className == "없음" ) {
            Util.showToast("반 정보가 없는 어린이집은 아이추가가 불가능합니다. 어린이집에 확인해 주세요. 가입을 진행하시려면 아이 정보를 지워주세요.");
            return;
          }

          var middleData = {
            code:childDataList[i].code,
            name:childDataList[i].name,
            gender:childDataList[i].gender === Const.SEX_TYPE_MALE?"남":"여",
            birthday:childDataList[i].birthday,
            class:className
          };
          children_datas.push(middleData);
        }
      }
      reqData["children_data"] = children_datas;
    }

    console.log("reqData : " + JSON.stringify(reqData) );


    if( idType == ServerRequestManager.ID_TYPE_NONE ) {
      // 인증코드 확인
      ServerRequestManager.requestCheckEmailAuth( (result, data) => {
        console.log("requestCheckEmailAuth result result : " + result);
        console.log("requestCheckEmailAuth result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          // 회원가입
          ServerRequestManager.requestSignUp( (result, data) => {
            console.log("requestSignUp result result : " + result);
            console.log("requestSignUp result data : " + JSON.stringify(data));
            
            if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
              moveMainScreenAfterLogin();
            }
          }, code, idType, id, pw, member_img, member_name, member_alias, reqData );
        }
      }, id, certiNumStr );
    }
    else {
      // 회원가입
      ServerRequestManager.requestSignUp( (result, data) => {
        console.log("requestSignUp result result : " + result);
        console.log("requestSignUp result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          moveMainScreenAfterLogin();
        }
      }, code, idType, id, pw, member_img, member_name, member_alias, reqData, id_token );
    }
  };

  
  const moveMainScreenAfterLogin = () => {
    var fcm_token = fcmToken;
    
    ServerRequestManager.requestLogin((result, data) => {
      console.log("requestLogin result result : " + result);
      console.log("requestLogin result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        data.result_data.code = codeResultData.code;
        data.result_data.id = idStr;
        data.result_data.pw = pwStr;
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__LOGIN_DATA, data.result_data);
        
        var member_type = data.result_data.member_type;
        var sess_id = data.result_data.sess_id;

        var receiveUserInfo = false;
        var receiveChildcareInfo = false;
        var childcare_num = null;

        // 웹뷰 메시지 전송
        let userData = {
          req_type:'login',
          req_data:{
            id:idStr,
            pw:pwStr,
            member_type:member_type,
            sess_id:sess_id
          }
        }
        ServerRequestManager.requestWebViewPostMessage( window, userData );


        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

            receiveUserInfo = true;

            if( receiveChildcareInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, idStr, sess_id);        

        // 어린이집 정보 요청
        ServerRequestManager.requestChildCareInfo((result, data) => {
          console.log("requestChildCareInfo result result : " + result);
          console.log("requestChildCareInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);
    
            receiveChildcareInfo = true;

            if( receiveUserInfo == true ) {
              moveScreen(member_type);
            }
          }
        }, idStr, sess_id, childcare_num);
      }
    }, idType, idStr, pwStr, fcm_token, snsToken );
  }

  const moveScreen = (member_type) => {
    if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
      props.history.push({ pathname:"/AFMainParentScreen" })
    }
    else if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      props.history.push({ pathname:"/AFMainSchoolScreen" })
    }
  }

  const clickCheckId = () => {
    // 아이디 체크 요청
    ServerRequestManager.requestCheckId( (result, data) => {
      console.log("requestCheckId result result : " + result);
      console.log("requestCheckId result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {

        if( data.result_data.id ) {
          Util.showToast("이미 가입된 아이디 입니다.");
        }
        else {
          // 일반 회원가입일 경우 인증코드 요청해야함
          if( idType === ServerRequestManager.ID_TYPE_NONE ) {
            ServerRequestManager.requestSendEmailAuth((result, data) => {
              console.log("requestSendEmailAuth result result : " + result);
              console.log("requestSendEmailAuth result data : " + JSON.stringify(data));
              
              if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                setCheckIdStr(idStr);
                Util.showToast('가입 가능한 아이디 입니다. 입력한 이메일로 인증번호를 보냈습니다.');
              }
            }, idStr );
          }
          else {
            setCheckIdStr(idStr);
            Util.showToast('가입 가능한 계정입니다.');
          }
        }
      }
    }, idType, idStr );
  };

  let ButtonIdRef = useRef();
  let pwTextInput = useRef();
  let rePwTextInput = useRef();

  /* 
  var nameTextInput = null;
  var pwTextInput = null;
  var rePwTextInput = null;
  */

  return (
    <div>
      <div>
        <Container>
          <TitleComponent history={props.history} title={'회원가입'} />
          <Wrap>
          <Container_top>
            <Logo_box>
              <Img_logo src={imgLogo} resizeMode={'contain'} />
            </Logo_box>
            <div>
              <Text_top_first>반갑습니다.</Text_top_first>
              <Text_top_second>
                유아 교육 전용 온라인 교실 e코앤코에 오신 것을 환영합니다.
              </Text_top_second>
            </div>
          </Container_top>

            <div style={{height:30}} />

            {/* 인풋 필수정보입력 */}
            <ShadowComponent>
              <View_input_wrap>
                <Input_common
                  placeholder="아이디(이메일)"
                  placeholderTextColor={viewIdStr == '' ? '#bbb' : '#000'}
                  keyboardType="email-address"
                  onChange={(e) => setViewIdStr(e.target.value)}
                  returnKeyType="done"
                  disabled={idType !== ServerRequestManager.ID_TYPE_NONE }
                  /*onSubmitEditing={() => {
                    ButtonIdRef.current.focus();
                  }}*/ // TODO Enter 입력시로 변경
                  value={viewIdStr}
                />
                <Dot_must>*</Dot_must>
                <Btn_id_check onClick={clickCheckId} ref={ButtonIdRef}>
                  <Toggle_btn_text>{ checkIdStr === "" ?"중복확인":"재 확인"}</Toggle_btn_text>
                </Btn_id_check>
              </View_input_wrap>
            </ShadowComponent>

            { idType === ServerRequestManager.ID_TYPE_NONE && checkIdStr !== "" && 
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="인증번호"
                    placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    secureTextEntry={passwordSecured}
                    onChange={(e) => setCertiNumStr(e.target.value)}
                    /*onSubmitEditing={() => {
                      pwTextInput.focus();
                    }}*/ // TODO Enter 입력시 이벤트로 변경
                    value={certiNumStr}
                  />
                  <Dot_must>*</Dot_must>
                </View_input_wrap>
              </ShadowComponent>
            }

            {
              idType == ServerRequestManager.ID_TYPE_NONE &&
              <>
                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="비밀번호"
                      placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                      textContentType="password"
                      secureTextEntry={passwordSecured}
                      onChange={(e) => setPwStr(e.target.value)}
                      returnKeyType="next"
                      type="password"
                      /*onSubmitEditing={() => {
                        rePwTextInput.focus();
                      }}*/// TODO Enter 입력시로 변경
                      value={pwStr}
                      ref={(input) => {
                        pwTextInput = input;
                      }}
                    />
                    <Dot_must>*</Dot_must>
                  </View_input_wrap>
                </ShadowComponent>

                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="비밀번호 확인"
                      placeholderTextColor={repwStr == '' ? '#bbb' : '#000'}
                      textContentType="password"
                      secureTextEntry={passwordConfirmSecured}
                      onChange={(e) => setRePwStr(e.target.value)}
                      returnKeyType="done"
                      type="password"
                      /*onSubmitEditing={() => {
                        // nameTextInput.focus();
                      }}*/ // TODO Enter 입력시로 변경
                      value={repwStr}
                      ref={(input) => {
                        rePwTextInput = input;
                      }}
                    />
                    <Dot_must>*</Dot_must>
                  </View_input_wrap>
                </ShadowComponent>
                <Text_info1>* 필수입력사항입니다.</Text_info1>
              </>
            }
            {/* END 인풋 필수정보입력 */}

            <Line_mid />

            {/* 인풋 추가정보입력 */}
            <Text_mid_first>추가정보 입력</Text_mid_first>
            <Text_mid_second>
              ※ 추가정보를 입력하시면 온라인 교실에 참여 하실 수 있습니다.
            </Text_mid_second>
            <Container_info_add>
              <TouchAble_profile_img onClick={() => {
                // alert('버튼')
                let options = {
                  mediaType:'photo',  
                  includeBase64:true,  
                }

                // 이미지 등록
                ImageSelector(options,
                  response => {
                    if (response.didCancel || response.error){ // 사진 가져오지 못한 경우
                      Util.showToast('사진 가져오기가 취소되었습니다.');
                    } else { // 사진 가져온 경우

                      /* jpg, jpeg 파일만 받도록 설정해야함
                        1. 안드로이드에서는 gif도 jpg로 변환되는 것으로 확인
                        2. IOS에서는 안된다고 하는데, 추후 꼭 확인해봐야함.
                      */
                      /* 
                      if ( response.type == 'image/jpeg' || response.type != 'image/jpg' ) { // jpg, jpeg 만 받도록
                        Util.showToast('jpg, jpeg 파일만 등록가능합니다.');
                        return;
                      }
                       */
                      const source = {uri: response.uri};
                      setImgProfile(source);
                      setHasImgProfile(true);

                      let base64 = response.base64;           // 보낼 인코딩된 이미지 파일
                      base64 = base64.replace(/\r?\n?/g, ''); // 개행을 제거하지 않으면 서버가 처리하지 못하므로 개행 제거
                      base64 = base64.trim();                 // 공백 제거
                      
                      let json_data = {
                        req_type:'join',
                        req_data:{
                          code:'L6INJF',
                          id:'asdf@naver.com',
                          pw:'asdasdasdA@A12',
                          proc:4,
                          member_type:1,
                          teacher_img:base64,
                        }
                      }
                      //ServerRequestManager._requestIncludeImg(json_data);
                      
                    }
                    
                  },
                );
              }}>
                <Profile>
                  <Img_profile src={imgProfile} resizeMode={'contain'} />
                </Profile>
                <Img_camera
                  src={cameraImg}
                  resizeMode={'contain'}
                  onClick={()=>{profileImgInputFile.current.click();}}
                />
              </TouchAble_profile_img>
              <Box_profile_input>
                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="가입자 이름을 입력해주세요"
                      placeholderTextColor={nameStr == '' ? '#bbb' : '#000'}
                      onChange={(e) => setNameStr(e.target.value)}
                      returnKeyType="next"
                      /*onSubmitEditing={() => {
                        pwTextInput.focus();
                      }}*/ // TODO Enter 입력시로 변경
                      value={nameStr}
                    />
                  </View_input_wrap>
                </ShadowComponent>
                <ShadowComponent>
                  <View_select_wrap>
                    <RNPickerSelectComponent
                      placeholder={{
                        label:
                          nickNameValue == ''
                            ? '호칭을 선택해주세요'
                            : nickNameValue,
                        value: nickNameValue,
                        color: '#ff4784',
                      }}
                      items={nickNameItems}
                      onValueChange={(value) => {
                        setNickNameValue(value);
                      }}
                      value={nickNameValue}
                      parents_style={{
                        placeholder: {
                          color: nickNameValue == '' ? '#bbb' : '#000',
                        },
                      }}
                    />
                  </View_select_wrap>
                </ShadowComponent>
              </Box_profile_input>
            </Container_info_add>
            {/* END 인풋 추가정보입력 */}

            <Line_mid></Line_mid>

            {/* 인풋 자녀정보입력 */}
            <Text_mid_wrap>
              <Text_mid_first>자녀 정보 입력</Text_mid_first>
              <View_mid_text>
                <Text_warning>※</Text_warning>
                <Text_comment>
                  아이 정보 입력 시, 내 아이의 학급에 맞는 온라인 교실을
                  자동으로 보여주고, 맞춤형 정보를 추천 받을 수 있습니다.
                </Text_comment>
              </View_mid_text>
              <Btn_img_plus onClick={() => clickAddChildButton()}>
                <Img_plus
                  src={plusImg}
                  resizeMode={'contain'}
                />
              </Btn_img_plus>
            </Text_mid_wrap>

            {/* 자녀 정보 입력 */}
            <ChildInfoList
              childDataList={childDataList}
              setChildDataList={setChildDataList}
              setChildRemovePosition={setChildRemovePosition}
            />
            {/* END 인풋 자녀정보입력 */}

            <div style={{height: 30}} />

            <ButtonComponent text="회원가입" buttonClick={clickSignupButton} />

            {/* 자녀정보입력 폼추가 클릭시 뜨는 첫번째 팝업창 */}
            <ShowPopupChildAddComponent
              isVisibleModal={isVisibleModal}
              setIsVisibleModal={setIsVisibleModal}
              setIsVisibleCodeModal={setIsVisibleCodeModal}
              clickAddChildMyCenter={clickAddChildMyCenter}
            />

            {/* '초대코드 입력' 팝업창 */}
            <ShowPopupCodeComponent
              setInviteCode={setInviteCode}
              isVisibleCodeModal={isVisibleCodeModal}
              setIsVisibleCodeModal={setIsVisibleCodeModal}
              clickCodeButton={clickCodeButton}
            />

            {/* '자녀정보삭제' 팝업창 */}
            <ShowPopupChildRemoveComponent
              childRemovePosition={childRemovePosition}
              setChildRemovePosition={setChildRemovePosition}
              clickChildRemove={clickChildRemove}
              clickRemoveCancel={clickRemoveCancel}
            />
          </Wrap>
          
          <input type='file' id='file' ref={profileImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
            var file = e.target.files[0];            
            var name = Util.getFileName(e.target.value);
            Util.loadImageFileBase64(file, (previewData, base64Data)=> {
              setImgProfile(previewData);
              setImgProfileBase64(base64Data);
            });
          }}/>
        </Container>
      </div>
    </div>
  );
}
export default BFSignupParentScreen

/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  padding-bottom: ${Const.getRateHeight(50)}px;
`;

// 상단 프로필 영역
const Container_top = styled.div`
  width: 100%;
  margin-top:10px;
`;

const Logo_box = styled.div`
  width: 180px;
  height:180px;
  margin:0 auto;
  border:1px solid #ddd;
  border-radius:70%;
  overflow:hidden;
`;

const Img_logo = styled.img`
  width: 100%;
  height: 100%;
`;

const Text_top_first = styled.span`
  margin-top: 40px;
  font-size:1.6em;
  color:#000;
  display:block;

`;

const Text_top_second = styled.span`
  margin-top:10px;
  display:block;
  color:#666;
  font-size:0.82em;
`;

// 정보 입력 영역
const Wrap = styled.div`
  padding:0 26px;
  box-sizing:border-box;
  padding-top:20px;
  width:100%;
`;

const View_input_wrap = styled.div`
  margin-bottom:8px;
  position:relative;

  ${(props) =>
    props.selectbox &&
    css`
     
    `}
`;

const Input_common = styled.input`
  width: 100%;
  height: 56px;
  font-size: 0.91em;
  border-radius:5px;
  border-color:#fff;
  color:#000;
  border:none;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  padding:0 2%;
  box-sizing:border-box;
`;

const Dot_must = styled.span`
  position: absolute;
  top:2px;
  left:4px;
  color: ${Const.COLOR_PINK};
`;

const Btn_id_check = styled.div`
  width: 84px;
  height:42px;
  position:absolute;
  top:6px;
  right:9px;
  text-align:center;
  line-height:42px;
  border-radius: 50px;
  background-color: ${Const.COLOR_BUTTON_SECOND_BG};
  cursor:pointer;
`;

const Toggle_btn_text = styled.span`
  color: #fff;
  font-weight: 400;
  font-size:0.91em;
`;

const Text_info1 = styled.span`
  color: red;
  margin-top:20px;
  display:block;
  font-size:0.82em;
  font-weight: 500;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:30px;
  margin-bottom:32px;
  border-bottom:1px dotted #ddd;
`;

// 인풋 추가정보입력


const Text_mid_wrap = styled.div`
  position:relative;
`;


const Text_mid_first = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  display:block;
`;

const Text_mid_second = styled.span`
  color: #ff4784;
  font-size:0.82em;
  display:block;
  margin-top:10px;
`;

const Container_info_add = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position:relative;
  margin:20px 0;
`;

const TouchAble_profile_img = styled.div`
  justify-content: center;
  align-items: center;
  display:inline-block;
  width:120px;
  position:relative;
  margin-right:10px;
`;

const Profile = styled.div`
  border-radius:70%;
  width:100px;
  height:100px;
  border:1px solid #ddd;
  overflow:hidden;
  margin-right:10px;
  position:relative;
`;

const Img_profile = styled.img`
  width:100%;
  height:100%;
  position:relative;
`;

const Img_camera = styled.img`
  height: 30px;
  position:absolute;
  bottom:0px;
  right:5px;
  cursor:pointer;
`;

const Box_profile_input = styled.div`
  width:calc(100% - 120px);
  display:inline-block;
`;

const View_select_wrap = styled.div`
  height: 56px;
  width: 100%;
`;

// 인풋 자녀정보입력
const View_mid_text = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display:block;
  margin-top:10px;
  margin-bottom:25px;
`;

const Text_warning = styled.span`
  margin-right:3px;
`;

const Text_comment = styled.span`
  color: #666;
  font-size:0.82em;
  line-height:18px;
`;

const Btn_img_plus = styled.div`
  z-index: 1;
  position: absolute;
  right:0;
  top:0;
  width:51px;
  height:45px;
  text-align:center;
  padding-top:10px;
  box-sizing:border-box;
  margin:-16px -5px 0 0;
`;

const Container_child_info = styled.div`
  border:2px dotted #ccc;
  margin-top:12px;
  border-radius:8px;
  padding:20px 14px 10px;
  box-sizing:border-box;

  /* ${(props) =>
    props.items != undefined && props.index == props.items.length - 1 &&
    css`
      // 마지막 박스일 경우
      border-bottom-width: 0;
      margin-bottom: 0;
    `} */
`;

const Img_plus = styled.img`
  height: ${Const.getRateHeight(20)}px;
`;

const Container_school_name = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height:56px;
  margin-bottom:10px;
  line-height:56px;
`;

const Img_logo_box = styled.div`
  width:50px;
  height:50px;
  border:1px solid #ddd;
  border-radius:70%;
  overflow:hidden;
  display:inline-block;
  vertical-align:top;
  margin-top:1px;
`;

const Img_logo_small = styled.img`
  height: 100%;
  width: 100%;
`;

const Text_school_name = styled.span`
  font-size: 1.15em;
  font-weight:500;
  line-height:56px;
  height:56px;
  display:inline-block;
  margin-left:16px;
  text-overflow:ellipsis;
  width:calc(100% - 72px);
  overflow:hidden;
  white-space:nowrap;
`;

const Btn_img_minus = styled.div`
  position: absolute;
  right:0;
  top:0;
  width:37px;
  height:50px;
  margin:-3px -2px 0 0;
  text-align:center;
  padding-top:11px;
  box-sizing:border-box;
`;

const Img_minus = styled.img`
  width:30px;
  height:30px;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top:0;
  right:5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Btn_radio = styled.div`
  margin-top:7px;
  width:57px;
  height:42px;
  display:inline-block;
  vertical-align:top;
  margin-left:3px;
`;

const Text_radio = styled.span`
  width:100%;
  color:#fff;
  text-align:center;
  height:100%;
  line-height:42px;
  font-size:0.91em;
  font-weight:400;
  display:block;
  background-color: #b7b7b7;
  border-radius:20px;

  // 남아 클릭 시
  ${(props) =>
    props.male &&
    css`
      // 태그에 넣은 male 속성을 가진 태그만 가져와서 별도의 css를 넣는다.
      background-color: ${({gender}) => {
        // useState에 담긴 gender 속성 내부 값을 가져온다.
        if (gender == 1) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}

  // 여아 클릭 시
  ${(props) =>
    props.female &&
    css`
      background-color: ${({gender}) => {
        if (gender == 2) return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}
`;

/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/