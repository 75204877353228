import React from 'react';
//import {StyleSheet} from 'react-native';
import Const from '../../util/Const';

//import Shadow from 'shadows-rn'; // TODO 다른대안 필요

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
function ShadowComponent({parents_style, children}) {

  var borderRadius = 0;
  if( parents_style != undefined && parents_style != null ) {
    borderRadius = parents_style.borderRadius;
  }

  return (
    <div style={parents_style}>
      <div style={{
        width: '100%',
        height: "100%",
        borderRadius: borderRadius,
        backgroundColor: '#fff',
        zIndex: -1,
      }}>
        {children}
      </div>
    </div>
  );
  /*
    <Shadow
      style={{
        width: '100%',
        height: Const.getRateHeight(70),
        borderRadius: 15,
        backgroundColor: '#fff',
        marginBottom: Const.getRateHeight(7),
        zIndex: -1,
      }}
      inset={false}
      shadows={[
        {
          offsetX: 0,
          offsetY: 0,
          radius: 20,
          color: '#eee',
        },
      ]}>
      {children}
    </Shadow>
    */
}
export default ShadowComponent