import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    /**
        router 화면전환 효과
    */
    .fade-enter {opacity:0; z-index:1;}
    .fade-enter.fade-enter-active {opacity:1; transition: opacity 250ms ease-in;}
    .fade-exit {display:none;}
    .fade-exit.fade-exit-active {}


    /**
        form
    */
    input, 
    select {
        background:#fff;
        -webkit-appearance: none; // IOS box-Shadow 보이지 않는 에러 대응
        height:40px;
    }
    input::placeholder,
    select::placeholder {color:#767676}

    input:focus {outline:none;}
    input[disabled], 
    input[readonly],
    select[disabled],
    select[readonly] {
        background:#fff;
        color:#767676;
    }

    // IOS 전용 style
    @supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true))
    and (not (-moz-appearance:none))
    {
        input {padding-left:0}
    }
`;


export default GlobalStyles;