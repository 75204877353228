import React from 'react';
// import * as Keychain from 'react-native-keychain';
// import DeviceInfo from 'react-native-device-info';

/**
 * KeyChainStore => 앱 로그인 유지를 위해 async storage에 로그인정보를 저장/유지하기 위해 사용.
 * 
 * 1. react-native-keychain    => async storage에 보안 요소를 추가한 것
 * 2. react-native-device-info => 패키지 네임 확인 가능 -> const service = DeviceInfo.getBundleId(); // com.nanoit.angeltv
 * 
 * https://www.npmjs.com/package/react-native-keychain
 * https://www.npmjs.com/package/react-native-device-info
 * 
 * ※ 전체 사용법은 최하단에 샘플있음.
 */

var KeyChainStore = new function(){
    //this.serviceKey = DeviceInfo.getBundleId();
    this.serviceKey = "test";
    this.setKeyChain = setKeyChain;
    this.getKeyChain = getKeyChain;
    this.regetKeyChain = regetKeyChain;
};
export default KeyChainStore;

// SET
function setKeyChain(service, username, password){
    //Keychain.setInternetCredentials(service, username, password);
}

// GET
async function getKeyChain(service){
    /*try {
        const credentials = await Keychain.getInternetCredentials(service);
        if (credentials) {
            console.log( JSON.stringify(credentials) );
        } else {
            console.log('No credentials stored');
        }
    } catch (error) {
        console.log("Keychain couldn't be accessed!", error);
    }*/
}

// RESET
function regetKeyChain(service){
    //Keychain.resetInternetCredentials(service);
}


/* KeyChain 전체 사용법 샘플 참고 ↓ set, get, reset 
async() => {
    const service = DeviceInfo.getBundleId(); // com.nanoit.angeltv
    const username = 'ungk456@naver.com';
    const password = 'rlaeogus!232';

    // Store the credentials
    await Keychain.setInternetCredentials(service, username, password);

    try {
    // Retrieve the credentials
    const credentials = await Keychain.getInternetCredentials(service);
    if (credentials) {
        console.log( JSON.stringify(credentials) );
    } else {
        console.log('No credentials stored');
    }
    } catch (error) {
        console.log("Keychain couldn't be accessed!", error);
    }
    await Keychain.resetInternetCredentials(service);
} 
*/