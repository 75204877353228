// React 이전 에러 수정



import React, {useState, useEffect} from 'react';

/*import {
  StatusBar,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';*/
//import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import Const from '../util/Const';
import Util from '../util/Util';
//import PreferenceManager from '../util/PreferenceManager';
import ServerRequestManager from '../util/ServerRequestManager';
import ButtonComponent from '../screen/component/ButtonComponent';
import InviteCodeComponent from '../screen/component/InviteCodeComponent';
import styled from 'styled-components';

import txt1Img from '../img/txt1.png';
import loginImg2 from '../img/login_img2.png';
import bulletImg2 from '../img/bullet2.png';

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
function BFCodeScreen( { history } ) {
  console.log("BFCodeScreen!!");

  const [inviteCode, setInviteCode] = useState(''); // 초대코드 6자리

  useEffect(() => {
    console.log("BFCodeScreen useEffect");

    // alert("6666666666");
    /* navigator.mediaDevices.enumerateDevices()
    .then(function(devices) {
      devices.forEach(function(device) {
        console.log("BFCodeScreen - " + device.kind + ": " + device.label +
                    " id = " + device.deviceId);
      });
    })
    .catch(function(err) {
      console.log(err.name + ": " + err.message);
    }); */

  }, []);

  // 하단 '계속' 버튼
  const _clickNextButton = () => {
    console.log("_clickNextButton");
    
    /**
         * JSON 요청
         * 0S09JO 선생 코드 샘플
         * F1LNM1 학부모코드 샘플
    */

    ServerRequestManager.requestInviteCode( (result, data) => {
      console.log("_requestInviteCode result result : " + result);
      console.log("_requestInviteCode result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        data.result_data.code = inviteCode;
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT, data.result_data);
        history.push({
          pathname:"/BFSelectSignupScreen"
        })
      }
    }, inviteCode);
    
  }

  // '이미 계정이 있으신가요?' 버튼
  const _clickMoveLoginButton = () => {

    history.push({
      pathname:"/BFLoginScreen"
    })
    

    /*history.push({
      pathname:"/BFLoginScreen",
      state:{search: searchText}
    })*/

    //navigation.navigate('BFLoginScreen', { changeScreenType : cst} )
    // TODO changeScreenType 은 글로발로 처리 필요
  };

  console.log("BFCodeScreen before return");
  return (
    <Container>
      <Container_top>
        <Img_txt1
          src={txt1Img}
          resizeMode={'contain'}
        />
        <Img_login_img2
          src={loginImg2}
          resizeMode={'contain'}
        />
        <Text_top>
          선생님과의 실시간 수업, 학부모님들을 위한 상담 및 참여수업 등 이제
          간편한 &nbsp;
          <Color_pink>e코앤코</Color_pink>로 시작하세요!!!
        </Text_top>
        <Line_mid />
      </Container_top>


      <Container_btm>
        <Container_btm_top>
          <Title>초대코드 입력</Title>
          <Sub_text>유아 교육 기관 초대코드를 입력해주세요.</Sub_text>
        </Container_btm_top>

        <Container_btm_mid>
          <Container_btm_input_view>
            <InviteCodeComponent 
              setInviteCode={setInviteCode}
              onComplete={_clickNextButton} />
          </Container_btm_input_view>
          <Text_btm_capution>※ 영문 대문자, 숫자 6자리</Text_btm_capution>
        </Container_btm_mid>

        <Container_btm_btm>
          <ButtonComponent text="계속" buttonClick={_clickNextButton} />
          <Touchable_btn onClick={_clickMoveLoginButton}>
            <Touchable_btn_text>
              이미 계정이 있으세요?
            </Touchable_btn_text>
            <Img_bullet2
              //source={require('../img/bullet2.png')}
              src={bulletImg2}
              resizeMode={'contain'}
            />
          </Touchable_btn>
        </Container_btm_btm>
      </Container_btm>
    </Container>
  );
}
export default BFCodeScreen

/******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;
const CONTAINER_HALF_HEIGHT = Math.round(
  // (SCREEN_HEIGHT - StatusBar.currentHeight) / 2,
  SCREEN_HEIGHT / 2
);

const Container = styled.div`
  width:100%;
  padding-bottom: 0;
  background: #fff;
  padding-bottom:50px;
`;

// 상단 영역
const Container_top = styled.div`
  padding: 0 26px;
  padding-top:30px;
  box-sizing:border-box;
`;

const Img_txt1 = styled.img`
  flex: 0.2;
  width: 100%;
  max-width:500px;
  margin-bottom: ${Const.getRateHeight(10)}px;
`;

const Img_login_img2 = styled.img`
  flex: 0.5;
  width: 100%;
  max-width:500px;
  margin-top:10px;
`;

const Text_top = styled.span`
  font-size:0.92em;
  margin-top:15px;
  line-height:28px;
  display:block;
  width:100%;
  color:#666;
  word-break:keep-all;
  font-weight:400;
  text-align:center;
`;

const Color_pink = styled.span`
  color: ${Const.COLOR_PINK};
`;

const Line_mid = styled.div`
  height:1px;
  width:100%;
  background:#ddd;
  margin-top:34px;
`;

// 하단 영역
const Container_btm = styled.div`
  padding: 0 26px;
  padding-top:34px;
  box-sizing:border-box;
`;

const Container_btm_top = styled.div`
  
`;

const Title = styled.span`
 font-size:1.6em;
 font-weight:600;
 text-align:center;
 display:block;
`;

const Sub_text = styled.span`
  margin-top:10px;
  display:block;
  font-size:0.82em;
  color:#666;  
  text-align:center;
  display:block;
`;

const Container_btm_mid = styled.div`
  flex: 0.4;
  padding-top: 30px;
  width:100%;
`;

const Container_btm_input_view = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
`;

const Text_btm_capution = styled.span`
  font-size:0.82em;
  font-weight:600;
  margin-top:26px;
  display:block;
  margin-left:3px;
  color:#ff4784;
`;

const Container_btm_btm = styled.div`
  margin-top:26px;
`;

const Touchable_btn = styled.div`
  cursor:pointer;
  margin-top:28px;
  text-align:center;
`;

const Touchable_btn_text = styled.span`
  font-size:0.91em;
  font-weight:500;
`;

const Img_bullet2 = styled.img`
  width:10px;
  margin-left:20px;
  vertical-align:middle;
`;

/*******************************************************************************************************
 *  Style end
 *******************************************************************************************************/
