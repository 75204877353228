import React, {useRef, useEffect, useState, useCallback} from 'react';
import Util from '../../util/Util';
import Const from '../../util/Const';
// import '../../util/Global.css';
//import Shadow from 'shadows-rn'; 
import ShadowComponent from './ShadowComponent'; 
import styled, {css, keyframes} from 'styled-components';


/*******************************************************************************************************
 *
 * 자녀정보입력 -> 초대코드 입력 INPUT 6개
 *
 *******************************************************************************************************/

function makeRefs() {
  const refs = [];
  for( var i=0; i<6; i++ ) {
    refs.push(null);
  }
  return refs;
}

function InviteCodeComponent({ inviteCode, setInviteCode, editableValue, onComplete }) {

  const [refs, setRefs] = useState( makeRefs() );

  const [code0, setCode0] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");

  const changeInviteCode = ( index, value ) => {
    var codes = [];
    codes.push(code0);
    codes.push(code1);
    codes.push(code2);
    codes.push(code3);
    codes.push(code4);
    codes.push(code5);

    if( index == 0 ) setCode0(value);
    else if( index == 1 ) setCode1(value);
    else if( index == 2 ) setCode2(value);
    else if( index == 3 ) setCode3(value);
    else if( index == 4 ) setCode4(value);
    else if( index == 5 ) setCode5(value);

    codes[index] = value;

    var fullCode = "";
    for( var i=0; i<codes.length; i++ ) {
      fullCode = fullCode + codes[i];
    }
    setInviteCode(fullCode);
  }

  /**
   * useState에서 전체값중 부분값만 변경하는 건 불가능함. 
   * 그래서 input 6개 중 하나라도 변경되면 재조립하도록 작업
   */
  useEffect(() => {    
    console.log("InviteCodeComponent useEffect");

    let getInviteCode = inviteCode == null || inviteCode == undefined ? "" : inviteCode;

    if( getInviteCode.length > 0 ){
      for(let i=0; i < getInviteCode.length; i++){
        var value = getInviteCode.substring(i, i+1);
        if( i == 0 ) setCode0(value);
        else if( i == 1 ) setCode1(value);
        else if( i == 2 ) setCode2(value);
        else if( i == 3 ) setCode3(value);
        else if( i == 4 ) setCode4(value);
        else if( i == 5 ) setCode5(value);
      }
    }
  }, []);

  return refs.map((refData, index) => {

    var codeValue = code0;
    if( index == 1 ) codeValue = code1;
    else if( index == 2 ) codeValue = code2;
    else if( index == 3 ) codeValue = code3;
    else if( index == 4 ) codeValue = code4;
    else if( index == 5 ) codeValue = code5;

    if( inviteCode != null && inviteCode.length == 6 ) {
      codeValue = inviteCode.substring(index, index+1);
    }

    return (
      <ShadowComponent
        key={index}       
        parents_style={{
          width: '15%',
          height: '64px',
          margin:'0 1%'
        }}
      >
        <TextInput_code
          aniActive
          maxLength={1}
          ref={ (ref) => {
            var beforeRefs = refs;
            beforeRefs[index] = ref;
            setRefs(beforeRefs);   
          }}
          disabled={ editableValue==true?true:false }
          value={ codeValue }
          //autoFocus={codeInput.id == 0 ? true : false}
          //editable={editableValue == undefined ? true : editableValue}
          onChange={(e) => {
            
            console.log("index : " + index + ", value : " + e.target.value);
            /*var beforeValues = values;
            beforeValues[index] = e.target.value;
            console.log("beforeValues[index] : " + e.target.value);
            setValues(beforeValues);    */
            
            changeInviteCode( index, e.target.value );

            if( e.target.value !== "" && index < refs.length - 1 ) {
              if( refs[index+1] != null ) {
                refs[index+1].focus();
              }
            }
          }}
          onKeyUp={(e)=>{
            if( e.keyCode == 13) { // 키보드 Enter 클릭
              if( onComplete != null && onComplete != undefined ) {
                onComplete();
              }
            }
            else if( e.keyCode == 27 ){ // ESC 클릭
              
              setCode0("");
              setCode1("");
              setCode2("");
              setCode3("");
              setCode4("");
              setCode5("");
              setInviteCode("");
              refs[0].focus();
            }
          }}
        />
      </ShadowComponent>
    );
  });
}
export default InviteCodeComponent
/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/
const TextInput_code = styled.input`
  height: 100%;
  width:100%;
  text-align: center;
  font-size: 1.3em;
  border-radius: 10px;
  background-color: #fff;
  outline:none;
  text-transform: uppercase; // IOS only
  color:#000;
  border:none;
  box-shadow:2px 2px 4px 4px rgba(0,0,0,0.05);
  box-sizing: border-box;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
`;



/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/