import React from 'react';
import Const from '../../../util/Const';
import styled, {css} from 'styled-components';
//import * as Animatable from 'react-native-animatable';
import ModalComponent from '../ModalComponent';
import ButtonComponentInChildRemovePopup from '../ButtonComponentInChildRemovePopup';

import closeImg from '../../../img/close.png'
import delImg from '../../../img/del.png'

/*******************************************************************************************************
 * '자녀정보삭제' 팝업창
 *
 * @param {childRemovePosition}    삭제 포지션
 * @param {setChildRemovePosition} 팝업창 show /hide 여부 셋팅
 * @param {clickChildRemove}        '삭제'
 * @param {clickRemoveCancel}       '취소'
 *******************************************************************************************************/
export default function ShowPopupChildRemoveComponent({
  childRemovePosition,
  setChildRemovePosition,
  clickChildRemove,
  clickRemoveCancel,
}) {

  
  return (
    childRemovePosition > -1 && (
      <ModalComponent
        isVisible={true}
        backdropOpacity={0.6}
        backdropTransitionInTiming={300}
        backdropTransitionOutTiming={300}>
        <Modal_contents
          animation={Const.ANIMATION_NAME}
          duration={Const.ANIMATION_DURATION}>
          <CodeModal_head>
            <Touchable_Modal_close
              onClick={() => setChildRemovePosition(-1)}>
              <Img_close
                src={closeImg}
                resizeMode={'contain'}
              />
            </Touchable_Modal_close>
            <Img_code
              src={delImg}
              resizeMode={'contain'}
            />
            <Text_modal_Title>자녀 정보 삭제</Text_modal_Title>
          </CodeModal_head>
          <Text_remove_warning>
            자녀정보를 삭제하시면 가입코드를 다시 입력해야 합니다. 정말
            삭제하시겠습니까?
          </Text_remove_warning>

          <Box_btn_modal>
            <ButtonComponentInChildRemovePopup
              text="삭제"
              buttonClick={clickChildRemove}
              items2
            />
            <ButtonComponentInChildRemovePopup
              text="취소"
              buttonClick={clickRemoveCancel}
              items2
              styleType={'bgGray'}
            />
          </Box_btn_modal>
        </Modal_contents>
      </ModalComponent>
    )
  );
}

/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/

// '자녀정보입력 팝업' 영역
// Animatable 추가
const Modal_contents = styled.div`
    z-index: 1;
    background-color: #fff;
    border-radius:10px;
    width:330px;
    padding:25px 18px;
    margin:0 auto;
    box-sizing:border-box;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
  `;

const CodeModal_head = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Touchable_Modal_close = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width:38px;
  height:38px;
  text-align:center;
  line-height:42px;
`;

const Img_close = styled.img`
  height: 16px;
  width: 16px;
`;

  const Img_code = styled.img`
  width: 43px;
  height: 45px;
  display:inline-block;
`;

const Text_modal_Title = styled.span`
  font-size: 1.4em;
  font-weight: 500;
  height:45px;
  vertical-align:top;
  line-height:45px;
  display:inline-block;
  margin-left:10px;
`;

const Text_remove_warning = styled.span`
  font-size:0.91em;
  display:block;
  color:#666;
  padding:8px 6px 22px;
  line-height:23px;
  word-break:break-all;

`;

const Box_btn_modal = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display:flex;
  justify-content:space-around;
`;

/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/
