import React, {useState, useEffect, memo} from 'react';
import Const from '../../util/Const';
import styled, {css} from 'styled-components';

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 * @param {props.text}        글자
 * @param {props.buttonClick} 클릭시 실행할 함수
 * @param {props.imgName}     내부 아이콘 이미지
 * @param {props.styleType}   스타일 구분용도
 * @param {props.items2}      한 라인에 버튼 2개, width 변경
 *
 *******************************************************************************************************/

import emailImg from '../../img/email_ov.png';
import naverImg from '../../img/naver.png';
import kakaoImg from '../../img/kakao.png';
import googleImg from '../../img/google.png';
export default memo(
  // 컴포넌트가 바뀌었을 때만 호출하도록 최적화
  function ButtonComponentInChildRemovePopup(props) {
    const [imgSource, setImgSource] = useState(null);
    const imgName = props.imgName ? props.imgName : null;

    // 버튼 안에 SNS 아이콘
/* 
    if (imgName !== null) {
       */
      useEffect(() => {
        switch (imgName) {
          case 'EMAIL':
            return setImgSource(emailImg);
          case 'NAVER':
            return setImgSource(naverImg);
          case 'KAKAO':
            return setImgSource(kakaoImg);
          case 'GOOGLE':
            return setImgSource(googleImg);
          default:
            // throw new Error('해당 이름의 아이콘을 찾을 수 없습니다');
            return setImgSource('');
        }
      }, []);
      /* 
    }
 */

    return (
      <Touchable_btn
        imgName={imgName}
        styleType={props.styleType}
        onClick={props.buttonClick}
        items2={props.items2}>
        <Img_icon imgName={imgName} src={imgSource} resizeMode={'contain'} />
        <Line_mid imgName={imgName} />
        <Text_btn imgName={imgName} styleType={props.styleType}>
          {props.text}
        </Text_btn>
      </Touchable_btn>
    );
  },
);

/*******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
 const Touchable_btn = styled.div`
 width: 100%;
 height: 50px;
 border-radius: 10px;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 line-height:50px;
 cursor:pointer;
 background: ${Const.COLOR_BUTTON_DEFAULT_BG};
 
 ${(props) =>
   props.imgName !== null &&
   css`
     flex-direction: row;
     justify-content: flex-start;

     ${(props) =>
       props.styleType == 'type2' &&
       css`
         background: #ddd;
       `}
   `}

 ${(props) =>
   props.styleType == 'bgGray' &&
   css`
     background: #bbb;
   `}

   ${(props) =>
   props.items2 &&
   css`
     width: 48%;
   `}
`;

const Img_icon = styled.img`
 width:50px;

 ${(props) =>
   props.imgName === null &&
   css`
     display: none;
   `}
`;

const Line_mid = styled.div`
 width: 1px;
 height: 25px;
 background: rgba(255,255,255,0.5);
 position:absolute;
 top:23px;
 left:85px;

 ${(props) =>
   props.imgName === null &&
   css`
     display: none;
   `}
`;

const Text_btn = styled.span`
 font-size: 1.1em;
 display: inline-block;
 text-align:center;
 line-height:50px;
 width:100%; 
 color: ${Const.COLOR_BUTTON_DEFAULT_TEXT};

 ${(props) =>
   props.imgName !== null &&
   css`
     font-size: 16px;
     font-weight: normal;

     ${(props) =>
       props.styleType == 'type2' &&
       css`
         color: #000;
       `}
   `}
`;
/*******************************************************************************************************
*  Style End
*******************************************************************************************************/
