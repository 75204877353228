
/**
 * 각종 유틸 클래스
 */
var Util = new (function(){
  /**
   * Toast 관련 메소드
   */
  this.showToast = showToast;
  this.hideToast = hideToast;
  this.showNetworkErrorToast = showNetworkErrorToast;

  this.getAppVersion = getAppVersion;

  /**
   * 글로벌 데이터 관련 변후
   */
  // this.globalDataControllFuntion;   
  this.getGlobalData = getGlobalData;
  this.setGlobalData = setGlobalData;
  
  this.changeWindowSize = changeWindowSize;

  this.setGlobalDataHandler = setGlobalDataHandler;
  this.callGlobalDataHandler = callGlobalDataHandler;
  this.globalDataHandler = null;

  this.openDevTool = openDevTool; // 개발자툴 열기 요청
  this.windowMinimize = windowMinimize;
  this.windowMaximize = windowMaximize;
  this.setWindowResizable = setWindowResizable;

  this.GLOBAL_DATA_CONTROLL_SET = 0;
  this.GLOBAL_DATA_CONTROLL_GET = 1;

  // this.topComponentHandler;
  this.setTopComponentHandler = setTopComponentHandler;   // 상단 컴포넌트 핸들러를 설정하는 메소드
  this.callTopComponentHandler = callTopComponentHandler; // 상단 컴포넌트 핸들러를 호출하는 메소드
  
  this.TOP_HANDLER_ACTION__OPEN_CONFERENCE = "open_conference";
  this.TOP_HANDLER_ACTION__CLOSE_CONFERENCE = "close_conference";
  
  // this.ipcReceiver; // IPC 리시버
  this.sendIpc                  = sendIpc;                  // 메인 프로세스로 IPC 통신 전송하는 메소드 
  this.setIpcReceiver           = setIpcReceiver;           // IPC 리시버를 설정하는 메소드
  this.callIpcReceiver          = callIpcReceiver;          // Ipc 리시버를 호출하는 메소드
  this.loadImageFileBase64      = loadImageFileBase64;      // 이미지를 base64형태로 로드하는 메소드
  this.getFileName              = getFileName;              // 이미지 경로로 부터 파일명을 가져오는 메소드
  this.makeShowImageFromUrl     = makeShowImageFromUrl;     // 전달받은 주소값으로 화면 표시용 이미지 데이터를 만드는 메소드
  this.getBirthItems            = getBirthItems;            // 생일 데이터리스트를 가져오는 메소드
  this.makeTwoNumberStr         = makeTwoNumberStr;         // 두자리 숫자 텍스트를 만드는 메소드
  this.makeTimeStrFormat        = makeTimeStrFormat;        // 14:01 형태의 시간 텍스트를 만드는 메소드
  this.makeChildrenListFromStr  = makeChildrenListFromStr;  // 텍스트 형식의 아이 데이터를 리스트로 변경하는 메소드
  this.makeClassList            = makeClassList;            // 반 데이터 리스트를 만드는 메소드
  this.makeDateFormatyyyyMMdd   = makeDateFormatyyyyMMdd;   // 날짜 텍스트 만드는 메소드 yyyyMMdd
  this.writeTextInClipboard     = writeTextInClipboard;     // 클립보드에 텍스트 복사
  this.isHaveVideoDevice        = isHaveVideoDevice;        // 비디오 기기가 있는지를 체크
  
  // url 특정 파라미터 값 가져오기
  this.getURLParams = getURLParams;

  // cookie
  this.setCookie = setCookie;
  this.getCookie = getCookie;
  this.deleteCookie = deleteCookie;
  this.getToken_setCookie_forNaverLogin = getToken_setCookie_forNaverLogin;
})();
module.exports = Util;

// import Toast, {BaseToast} from 'react-native-toast-message'; // Toast 메시지 관련
// import DeviceInfo from 'react-native-device-info'; // Device, App 관련정보

/**
 * 상단 컴포넌트 핸들러를 설정하는 메소드
 * @param {*} handler 
 */
function setTopComponentHandler( handler ) {
  Util.topComponentHandler = handler;
}

/**
 * 상단 컴포넌트 핸들러를 호출하는 메소드
 * @param {*} action 
 */
function callTopComponentHandler( action ) {
  Util.topComponentHandler( action );
}

function showToast(text) {
  alert(text);
}

function showNetworkErrorToast(){
  alert("네트워크 상태가 원활하지 않습니다. 확인 후 다시 시도해 주세요");
}

function hideToast() {
  //Toast.hide();
}

function getAppVersion() {
  return 0;
  //return DeviceInfo.getVersion();
}

function getGlobalData( window, global_data_type ) {
  const Const = require("./Const");
  var reqData = {
    data_type : Const.IPC_DATA_TYPE_GET_DATA,
    global_data_type : global_data_type
  }
  sendIpc(window, reqData);
}

function setGlobalData( window, global_data_type, data ) {
  const Const = require("./Const");
  var reqData = {
    data_type : Const.IPC_DATA_TYPE_SET_DATA,
    global_data_type : global_data_type,
    data : data
  }
  sendIpc(window, reqData);
}

function setGlobalDataHandler( handler ) {
  Util.globalDataHandler = handler;
}

function callGlobalDataHandler(data) {
  Util.globalDataHandler(data);
}

function changeWindowSize( window, width, height, key ) {
  const Const = require("./Const");
  var reqData = {
    data_type : Const.IPC_DATA_TYPE_RESIZE,
    width : width,
    height : height,
    key : key
  }
  sendIpc(window, reqData);
}

function openDevTool( window ) {
  const Const = require("./Const");
  sendIpc(window, {data_type : Const.IPC_DATA_TYPE_OPEN_DEV_TOOL});
}

function windowMinimize(window) {
  const Const = require("./Const");
  sendIpc(window, {data_type : Const.IPC_DATA_TYPE_WINDOW_MINIMIZE});
}

function windowMaximize(window) {
  const Const = require("./Const");
  sendIpc(window, {data_type : Const.IPC_DATA_TYPE_WINDOW_MAXIMIZE});
}

function setWindowResizable(window, able)  {
  const Const = require("./Const");
  sendIpc(window, {data_type : Const.IPC_DATA_TYPE_SET_RESIZE_ABLE, able:able});
}

/**
 * 메인 프로세스로 IPC 통신 전송하는 메소드
 * @param {*} window 
 * @param {*} data 
 */

function sendIpc( window, data ) {
  // const { ipcRenderer } = window.require("electron");
  // ipcRenderer.send("message-to-main-async", data);
  if( Util.globalDataHandler != null )
    Util.globalDataHandler(data);
}

/**
 * IPC 리시버를 설정하는 메소드
 * @param {*} receiver 
 */
function setIpcReceiver( receiver ) {
  Util.ipcReceiver = receiver;
  /*const { ipcRenderer } = window.require("electron");
  ipcRenderer.on('message-to-window-async', (event, arg) => { // 통신 receiver 등록
    receiver(arg);
  });*/
}

/**
 * Ipc 리시버를 호출하는 메소드
 * @param {*} arg 
 */
function callIpcReceiver( arg ) {
  if( Util.ipcReceiver != undefined ) {
    Util.ipcReceiver( arg );
  }
}

/**
 * 이미지를 base64형태로 로드하는 메소드
 * @param {*} file 
 * @param {*} callback 
 */
function loadImageFileBase64( file, callback ) {
  var reader = new FileReader();

  if( file == undefined || file == null ) {
    return;
  }

  //로드 한 후
  reader.onload = function (e) {
      console.log("reader.result");
      console.log(e); //[object ProgressEvent]

      var binaryString = e.target.result;

      var base64Data = btoa(binaryString);
      //document.getElementById("base64Str").value = base64Data;
      console.log("base64:" + base64Data);

      var preViewReader = new FileReader();
      preViewReader.onload = function (e) {
        var previewData = e.target.result;
        callback( previewData, base64Data );
      }
      preViewReader.readAsDataURL(file);
  };
  reader.readAsBinaryString(file);
}

/**
 * 이미지 경로로 부터 파일명을 가져오는 메소드
 * @param {*} nameValue 
 * @returns 
 */
function getFileName( nameValue ) {
  console.log("getFileName nameValue : " + nameValue);
  var name = nameValue.split("fakepath\\")[1];
  return name;
}

/**
 * 전달받은 주소값으로 화면 표시용 이미지 데이터를 만드는 메소드
 * @param {*} url 
 * @param {*} defaultImg 
 * @returns 
 */
function makeShowImageFromUrl( url, defaultImg ) {
  if( url === null || url === undefined || url === "" ) {
    return defaultImg;
  }
  
  let realUrl = "https://ntm.nanoit.kr/upload/" + url;
  console.log("makeShowImageFromUrl realUrl : " + realUrl);
  return realUrl;
}

/**
 * 생일 데이터리스트를 가져오는 메소드
 * @returns 
 */
function getBirthItems() {
  
  let today = new Date();
  let year = today.getFullYear();

  let list = [];

  var start=year;
  for( var i=start; i>start - 7; i-- ) {
    let birthItem = {
      id:start-i,
      label:i+"년생",
      value:i
    }
    list.push(birthItem);
  }
  return list;
}

/**
 * 두자리 숫자 텍스트를 만드는 메소드
 * @param {*} number 
 * @returns 
 */
function makeTwoNumberStr( number ) {
  let num = number * 1;
  if( num < 10 ) {
    return "0" + num;
  }
  return "" + num;
}

/**
 * 14:01 형태의 시간 텍스트를 만드는 메소드
 * @param {*} number 
 * @returns 
 */
function makeTimeStrFormat( timeStr ) {
  return timeStr.substr(0,2) + ":" + timeStr.substr(2,2);
}

/**
 * 텍스트 형식의 아이 데이터를 리스트로 변경하는 메소드
 * @param {*} childStr 
 */
function makeChildrenListFromStr( childStr ) {
  let childrenList = [];

  if( childStr ) {
    var middleDataStrs = childStr.split("::");
    if( middleDataStrs != null && middleDataStrs.length > 2 ) {
      for( var i=1; i<middleDataStrs.length-1; i++ ) {
        var rowDataStrs = middleDataStrs[i].split("|");
        var data = {
          childcare_num :rowDataStrs[0],
          name          :rowDataStrs[1],
          gender        :rowDataStrs[2]==="남"?1:2,
          birthday      :rowDataStrs[3],
          className     :rowDataStrs[4]
        }
        childrenList.push(data);
      }
    }
  }

  return childrenList;
}

/**
 * 반 데이터 리스트를 만드는 메소드
 * @param {*} class_data 
 * @returns 
 */
function makeClassList( class_data ) {
  console.log("makeClassList class_data : " + class_data);
  let classList = [];
  if( class_data == null || class_data == "" ) {
    class_data = "::없음::";
  }
  console.log("makeClassList class_data 2 : " + class_data);

  if( class_data !== null && class_data !== "" ) {
    var classStrs = class_data.split("::");
    if( classStrs.length > 2 ) {
      for( var i=1; i<classStrs.length-1; i++ ) {
        classList.push({id:i-1,label:classStrs[i],value:classStrs[i]});
      }
    }
  }
  return classList;
}

/**
 * 날짜 텍스트 만드는 메소드 yyyyMMdd
 * @param {*} date 
 * @returns 
 */
function makeDateFormatyyyyMMdd( date ) {
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  return yyyy + "" + makeTwoNumberStr(mm) + "" + makeTwoNumberStr(dd);
}

/**
 * 클립보드에 텍스트 복사
 * @param {*} window 
 * @param {*} text 
 */
function writeTextInClipboard( window,  text ) {
  const {clipboard} = window.require('electron');
  clipboard.writeText(text)
}

/**
 * 비디오 기기가 있는지를 체크
 */
function isHaveVideoDevice( navigator, callback ) {
  const Const = require("./Const");
  let isHave = false;
  /*
  navigator.mediaDevices.enumerateDevices()
    .then(function(devices) {
      devices.forEach(function(device) {
        console.log("isHaveVideoDevice - " + device.kind + ": " + device.label + " id = " + device.deviceId);
                    
        if( device.kind == Const.DEVICE_TYPE_VIDEO_INPUT) {
          console.log("Is have video input!!!!!");
          isHave = true;
        }
      });
      callback(isHave);
    })
    .catch(function(err) {
      console.log("isHaveVideoDevice - " + err.name + ": " + err.message);
      callback(isHave);
    });*/
}


 // 도메인 특정 파라미터값 가져오기
function getURLParams(url) {
  var result = {};
  url.replace(/[#?&]{1}([^=&#]+)=([^&#]*)/g, function(s, k, v) { result[k] = decodeURIComponent(v); });
  return result;
}

function setCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
  var i, x, y, ARRcookies = document.cookie.split(";");
  
  for (i = 0; i < ARRcookies.length; i++) {     
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    
    x = x.replace(/^\s+|\s+$/g, "");

    if (x == name) {
      return unescape(y);
    }
  }
}

function deleteCookie(cookieName){
  var temp=getCookie(cookieName);
  if(temp){
      setCookie(cookieName,temp,(new Date(1)));
  }
}


function getToken_setCookie_forNaverLogin(){
  const access_token = Util.getURLParams(window.location.href).access_token;
  if(access_token!=undefined) Util.setCookie('access_token', access_token, 1);
}
