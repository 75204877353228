import Util from '../util/Util';

/**
 *  폼 검증 + @
 *  모듈화한 이유 : 폼 체크 방법과 알림메시지를 통일하기 위해 사용
 */
var Validation = new function() {
    this.BubbleSort = BubbleSort;

    this.CheckMust = CheckMust;
    this.MsgCheckMust = MsgCheckMust;

    this.CheckEmail = CheckEmail;
    this.MsgCheckEmail = MsgCheckEmail;
    this.CheckPassword = CheckPassword;
    this.MsgCheckPassword = MsgCheckPassword;
    this.CheckPasswordConfirm = CheckPasswordConfirm;
    this.MsgCheckPasswordConfirm = MsgCheckPasswordConfirm;

    this.CheckName = CheckName;
    this.MsgCheckName = MsgCheckName;
    this.CheckNickName = CheckNickName;
    this.MsgCheckNickName = MsgCheckNickName;
    this.CheckClassName = CheckClassName;
    this.MsgCheckClassName = MsgCheckClassName;
    this.CheckDate = CheckDate;
    this.MsgCheckDate = MsgCheckDate;
    this.CheckStartTime = CheckStartTime;
    this.MsgCheckStartTime = MsgCheckStartTime;
    this.CheckEndTime = CheckEndTime;
    this.MsgCheckEndTime = MsgCheckEndTime;

};
export default Validation;

// 버블정렬 (자녀정보입력의 동적 폼에서 사용중)
function BubbleSort(array){
    for (let i = 0; i < array.length; i++) {
        let swap;
        for (let j = 0; j < array.length - 1 - i; j++) {
            if (array[j].id > array[j + 1].id) {
                // id 기준
                swap = array[j];
                array[j] = array[j + 1];
                array[j + 1] = swap;
            }
        }
        if (!swap) break;
    }
    return array;
}

// 필수입력사항 체크
function CheckMust(...param){
    let val = null;
    param.map(e => {
        if( e.trim().length === 0 || e.trim().length === '' ){
            return val = false;
        }else{
            return val = true;
        }
    })
    return val;
}
function MsgCheckMust(){
    Util.showToast('필수입력사항을 작성해주세요.');
}

// 아이디(이메일) 체크
function CheckEmail(email){                                                 
    var reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if(!reg_email.test(email.trim())) {                            
        return false;
    } else {
        return true;
    }                            
}
function MsgCheckEmail(){                                                 
    Util.showToast('아이디(이메일) 입력이 올바르지 않습니다.');
}

// 비밀번호 체크
function CheckPassword(pwd){                                                 
    if( pwd.trim().length < 8 || pwd.trim().length > 20 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckPassword(){                                                 
    Util.showToast('비밀번호를 8자리 ~ 20자리 이내로 입력해주세요.');
}

// 비밀번호확인 체크
function CheckPasswordConfirm(pwd, pwdConfirm){                                              
    if( pwd.trim() !== pwdConfirm.trim() ) {                         
        return false;
    } else {
        return true;
    }                            
}
function MsgCheckPasswordConfirm(){
    Util.showToast('작성한 비밀번호가 일치하지 않습니다.');
}

// 이름 체크
function CheckName(name){                                                 
    if( name.trim().length === 0 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckName(){                                                 
    Util.showToast('가입자 이름을 입력해주세요.');
}

// 호칭 체크
function CheckNickName(nickname){                                                 
    if( nickname.trim().length === 0 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckNickName(){                                                 
    Util.showToast('호칭을 선택해주세요.');
}

/**
 *  온라인 교실
 */
// 온라인 교실 제목 체크
function CheckClassName(name){                                                 
    if( name.trim().length === 0 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckClassName(){                                                 
    Util.showToast('온라인 교실 제목을 입력해주세요.');
}

// 날짜 체크
function CheckDate(date){                                                 
    if( date.trim().length === 0 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckDate(){                                                 
    Util.showToast('날짜를 선택해주세요.');
}

// 시작시간 체크
function CheckStartTime(date){                                                 
    if( date.trim().length === 0 ) {                            
        return false;         
    } else {
        return true;         
    }                            
}
function MsgCheckStartTime(){                                                 
    Util.showToast('시작시간을 선택해주세요.');
}

// 종료시간 체크
function CheckEndTime(date){                                                 
    if( date.trim().length === 0 ) {                            
        return false;         
    } else {                       
        return true;         
    }                            
}
function MsgCheckEndTime(){                                                 
    Util.showToast('종료시간을 선택해주세요.');
}
