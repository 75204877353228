import React, {memo} from 'react';
import Const from '../../../util/Const';
import styled, {css, keyframes} from 'styled-components';
//import * as Animatable from 'react-native-animatable';
import ModalComponent from '../ModalComponent';

/*******************************************************************************************************
 *
 * 자녀 정보 입력 추가 시 팝업창
 * @param {isVisibleModal}          팝업창 show / hdie 여부
 * @param {setIsVisibleModal}       팝업창 show / hdie 여부 셋팅
 * @param {setIsVisibleCodeModal}   초대코드입력 팝업창 show / hdie 여부 셋팅
 * @param {clickAddChildMyCenter}   현재 기관에 추가 누를 경우 폼추가 함수
 *
 *******************************************************************************************************/
// memo = 컴포넌트가 바뀌었을 때만 호출되어 성능이 최적화
  function ShowPopupChildAddComponent({
    isVisibleModal,
    setIsVisibleModal,
    setIsVisibleCodeModal,
    clickAddChildMyCenter,
  }) {
    // alert('자녀 정보 입력 추가')
    return (
      <ModalComponent
        isVisible={isVisibleModal}
        backdropOpacity={0.6}
        backdropTransitionInTiming={300}
        backdropTransitionOutTiming={10}>
        <Modal_contents aniScale>
          <TouchAble_btn_modal
            top
            onClick={() => {
              clickAddChildMyCenter();
              setIsVisibleModal(false);
            }}>
            <Text_modal>현재 기관에 추가</Text_modal>
          </TouchAble_btn_modal>
          <Line_mid_modal />
          <TouchAble_btn_modal
            btm
            onClick={() => {
              setIsVisibleModal(false);
              setIsVisibleCodeModal(true);
            }}>
            <Text_modal>새 기관에 추가 (새로운 가입코드 필요)</Text_modal>
          </TouchAble_btn_modal>
        </Modal_contents>
      </ModalComponent>
    );
  }
export default ShowPopupChildAddComponent
/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/
/**
 * start - animation
 */
const aniScale = keyframes`
  0% {
    transform:scale(0.1);
  }
  100% {
    transform:scale(1);
  }
`;
/**
* End - animation
*/


// '자녀정보입력 팝업' 영역
// Animatable 추가
const Modal_contents = styled.div`
    z-index: 1;
    background-color: #fff;
    width:90%;
    margin:0 auto;
    position:absolute;
    top:40%;
    left:5%;
    border-radius:10px;

    ${(props) => props.aniScale && css`
      animation:${aniScale} 0.4s ease-in-out;
    `}
  `;

const TouchAble_btn_modal = styled.div`
    height:60px;
    line-height:60px;
    text-align:center;
    cursor:pointer;
  `;

const Line_mid_modal = styled.div`
  border-bottom:1px dotted #ccc;
`;

const Text_modal = styled.span`
  text-align: center;
  font-size: 1.05em;
  display:inline-block;
  overflow:hidden;
  text-overflow:ellipsis;
  width:100%;
  white-space:nowrap;
`;

/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/
