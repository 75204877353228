import React from 'react';
import Const from '../../util/Const';
import styled from 'styled-components';

import topIconImg from '../../img/top_icon1.png'

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
function TitleComponent(props) {
  // console.log("TitleComponent");

  const clickBackButton = ( fdsaf ) => {
    props.history.goBack();
  };

  return (
    <Container>
      <Touchable onClick={clickBackButton}>
        <Img_top_icon1
          src={topIconImg}
          resizeMode={'contain'}
        />
      </Touchable>
      <Title>
        <Text_title>{props.title}</Text_title>
      </Title>
    </Container>
  );
}
export default TitleComponent
/*******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
const Container = styled.div`
  position: relative;
  flex-direction: row;
  background: #fff;
  height: 70px;
  line-height:70px;
  width:100%;
`;

const Touchable = styled.div`
  position: absolute;
  background: ${Const.COLOR_TRANSFER};
  z-index:1;
  height: 70px;
  width:70px;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  display:inline-block;
  box-sizing:border-box;
  text-align:center;
  left:0;
  top:0;
`;

const Img_top_icon1 = styled.img`
  height:20px;
  position:relative;
  top:5px;
`;

const Title = styled.div`
  position: absolute;
  height: 70px;
  width:100%;
  align-items: center;
  justify-content: center;
  left:0;
  top:0;
  line-height:70px;
  box-sizing:border-box;
  text-align:center;
`;

const Text_title = styled.span`
  font-size: 1.25em;
  font-weight: 400;
  color: #000;
`;

/*******************************************************************************************************
 *  Style end
 *******************************************************************************************************/
