import React from 'react';
import '../../css/loading.css';


export default function LoadingComponent() {

    return( 
        <div className="wrapper">
            <div className="loaderV1 loader"></div>
        </div>
    )
}