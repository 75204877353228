// React 이전 에러 수정
import React, {useState, useRef, useEffect} from 'react';
//import {StatusBar, Keyboard, TouchableWithoutFeedback, View, Text} from 'react-native';

import Const from '../util/Const';
import Util from '../util/Util';
// import SnsLogin from '../util/SnsLogin';
import ButtonComponent from '../screen/component/ButtonComponent';
import TitleComponent from '../screen/component/TitleComponent';
//import Shadow from 'shadows-rn'; 
import ShadowComponent from './component/ShadowComponent'; 
import styled, {css} from 'styled-components';
import Validation from '../util/Validation';
import ServerRequestManager from '../util/ServerRequestManager';
//import { ScrollView } from 'react-native-gesture-handler';

/*******************************************************************************************************
 * 
 * 이메일 발송 성공시 추가로 표시할 INPUT
 * 
 *******************************************************************************************************/
const AddInput = ({
  isVisibleInput, 
  RefItems,
  certiNumStr,setCertiNumStr,
  pwStr, setPwStr,
  repwStr, setRepwStr,
  clickNewPasswordButton
}) => 
  isVisibleInput === true &&
    (
      <>
        <Line_mid></Line_mid>

        <Text_btm_subTitle>메일로 발송된 인증번호를 입력해주세요</Text_btm_subTitle>
        <ShadowComponent>
          <View_input_wrap>
            <Input_common
              placeholder="인증번호 입력"
              placeholderTextColor='#bbb'
              keyboardType="default"
              onChange={(e) => setCertiNumStr(e.target.value)}
              returnKeyType="next"
              value={certiNumStr}
              //ref={RefItems[0]}
              // onSubmitEditing={() => RefItems[1].current.focus()} // TODO ENTER 입력 이벤트로 변경해야함
            />
          </View_input_wrap>
        </ShadowComponent>

        <ShadowComponent>
          <View_input_wrap>
            <Input_common
              placeholder="새 비밀번호"
              placeholderTextColor='#bbb'
              keyboardType="default"
              onChange={(e) => setPwStr(e.target.value)}
              returnKeyType="next"
              secureTextEntry={true}
              value={pwStr}
              type="password"
              //ref={RefItems[1]}
              // onSubmitEditing={() => RefItems[2].current.focus()} // TODO ENTER 입력 이벤트로 변경해야함
            />
          </View_input_wrap>
        </ShadowComponent>

        <ShadowComponent>
          <View_input_wrap>
            <Input_common
              placeholder="새 비밀번호 확인"
              placeholderTextColor='#bbb'
              keyboardType="default"
              onChange={(e) => setRepwStr(e.target.value)}
              returnKeyType="done"
              secureTextEntry={true}
              value={repwStr}
              type="password"
              //ref={RefItems[2]}
              // onSubmitEditing={() => clickNewPasswordButton()} // TODO ENTER 입력 이벤트로 변경해야함
            />
          </View_input_wrap>
        </ShadowComponent>
      </>
    )


/*******************************************************************************************************
 *
 * 비밀번호 찾기 화면
 *
 *******************************************************************************************************/
function BFSearchPwdScreen(props) {
  const [idStr, setIdStr] = useState('');             // 아이디(이메일)
  const [certiNumStr, setCertiNumStr] = useState(''); // 인증번호 입력
  const [pwStr, setPwStr] = useState('');             // 새 비밀번호
  const [repwStr, setRepwStr] = useState('');         // 새 비밀번호 확인
  const [isVisibleInput, setIsVisibleInput] = useState(false); // 추가인풋 visible/hidden 결정
  // const RefItems = Array.from({length:4}, a => useRef(''));  // 각 인풋 ref 설정
  const [spaceStyle, setSpaceStyle] = useState('on');
/* 
  const dismissKeyboard = () => {
    Keyboard.dismiss();
  };
 */

  useEffect(() => {
    ServerRequestManager.requestChangePage( window, "BFSearchPwdScreen" );
  }, []);

  // 비밀번호 찾기
  const clickSearchButton = () => {
    if( Validation.CheckEmail(idStr) === false ) {
      Validation.MsgCheckEmail();
      return;
    }

    // 비밀번호 재설정 상태일경우
    if( isVisibleInput ) {
      ServerRequestManager.requestCheckEmailAuth((result, data) => {
        console.log("requestCheckEmailAuth result result : " + result);
        console.log("requestCheckEmailAuth result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          if( pwStr == "" ) {
            Util.showToast('새 비밀번호를 입력하세요.');
            return;
          }
          if( repwStr == "" ) {
            Util.showToast('새 비밀번호 확인을 입력하세요.');
            return;
          }
          if( pwStr !== repwStr ) {
            Util.showToast('새로 입력한 비밀번호가 일치하지 않습니다.');
            return;
          }

          // 비밀번호 변경 요청
          ServerRequestManager.requestChangePw((result, data) => {
            console.log("requestChangePw result result : " + result);
            console.log("requestChangePw result data : " + JSON.stringify(data));
            
            if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
              props.history.goBack();
              Util.showToast('비밀번호 변경성공');
            }
          }, idStr, pwStr);
        }
      }, idStr, certiNumStr);
    } 
    // 인증번호 요청일 경우
    else {
      
      ServerRequestManager.requestSendEmailAuth((result, data) => {
        console.log("requestSendEmailAuth result result : " + result);
        console.log("requestSendEmailAuth result data : " + JSON.stringify(data));
        
        if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
          setIsVisibleInput(true); // 숨긴 인풋 보이게 변경
          setSpaceStyle('off');    // style 변경

          Util.showToast('등록된 이메일로 인증번호를 보냈습니다.');
        }
      }, idStr);
    }


    // if() 존재하는 이메일인지 체크해야함
    //Util.showToast('등록된 이메일로 인증번호를 보냈습니다.');
  };

  // 새 비밀번호 설정
  const clickNewPasswordButton = () => {
    // if() 인증번호 일치하는지 체크해야함
    if( Validation.CheckPassword(pwStr) === false ) {
      Validation.MsgCheckPassword();
      return;
    }
    if( Validation.CheckPasswordConfirm(pwStr, repwStr) === false ) {
      Validation.MsgCheckPasswordConfirm();
      return;
    }

    Util.showToast('비밀번호가 변경되었습니다.');

    // 바로 로그인해서 메인으로 갈지, 직접 다시 로그인하게 할지 선택 !!
    // navigation.navigate()
  };
  
  return (
    <div> {/* 상하 스크롤 되어야함 */}
        <div>
          <Container>
            <TitleComponent history={props.history} title={'정보 찾기'} />
            <Wrapper>
              <Text_top_title>{isVisibleInput == false ? '비밀번호 찾기' : '비밀번호 재설정'}</Text_top_title>
              <Text_top_subTitle spaceStyle={spaceStyle}>
                회원정보에 입력된 아이디를 입력해 주세요.
              </Text_top_subTitle>
              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="아이디(이메일)"
                    placeholderTextColor='#bbb'
                    keyboardType="email-address"
                    onChange={(e) => setIdStr(e.target.value)}
                    returnKeyType="done"
                    disabled={isVisibleInput == true}
                    // onSubmitEditing={() => { clickSearchButton(); }}  // TODO ENTER 입력 이벤트로 변경해야함
                    value={idStr}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <AddInput 
                isVisibleInput={isVisibleInput} 
                // RefItems={RefItems} 
                certiNumStr={certiNumStr}
                setCertiNumStr={setCertiNumStr}
                pwStr={pwStr}
                setPwStr={setPwStr}
                repwStr={repwStr}
                setRepwStr={setRepwStr}
                clickNewPasswordButton={clickNewPasswordButton}
              />

              <div style={{height:30}} />
              <ButtonComponent
                text={isVisibleInput == false ? '비밀번호 찾기' : '비밀번호 재설정'}
                buttonClick={clickSearchButton}
                //ref={RefItems[3]}
              />
            </Wrapper>
          </Container>
        </div>
    </div>
  );
}
export default BFSearchPwdScreen
/*******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;

const Container = styled.div`
  background-color: #fff;
  width:100%;
  padding-bottom:20px;
  font-size:16px;
`;

const Wrapper = styled.div`
  padding:0 26px;
  box-sizing:border-box;
`;

const Text_top_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  display:block;
  margin-top:20px;
  margin-bottom:6px;
`;

const Text_top_subTitle = styled.span`
  font-size:0.82em;
  color:#666;
  display:block;

  ${props => props.spaceStyle === 'off' && css`
    padding-bottom: 12px;
  `}
`;

const Line_mid = styled.span`
  height: 1px;
  margin-top: 30px;
  margin-bottom:32px;
  border-bottom:1px dotted #ddd;
  display:block;
`;

const Text_btm_subTitle = styled.span`
  font-size: 0.92em;
  font-weight:500;
  display:block;
  margin-bottom:20px;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  position:relative;

  ${(props) =>
    props.selectbox &&
    css`
      padding-top: ${Const.getRateHeight(5)}px;
    `}
`;

const Input_common = styled.input`
  border:none;
  font-size:0.92em;  
`;

/*******************************************************************************************************
 *  Style End
 *******************************************************************************************************/
