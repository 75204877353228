import React, {useState, useRef, useEffect} from 'react';
import Const from '../util/Const';
import Util from '../util/Util';
import Validation from '../util/Validation';
import styled, {css} from 'styled-components';
import ShadowComponent from './component/ShadowComponent';
import ButtonComponent from './component/ButtonComponent';
import InviteCodeComponent from './component/InviteCodeComponent';
import TitleComponent from './component/TitleComponent';
import minus_img from '../img/minus.png';
import tree_img from '../img/tree.png';
import noImg from '../img/no_img.png'
import top_icon1_img from '../img/top_icon1.png';
import top_icon2_img from '../img/top_icon2.png';
import camera_img from '../img/camera.png';
import plus_img from '../img/plus.png';
import ServerRequestManager from '../util/ServerRequestManager';

const classItems = [
  {id: 0, label: '꽃님반', value: '꽃님반'},
  {id: 1, label: '햇님반', value: '햇님반'},
  {id: 2, label: '달님반', value: '달님반'},
];


/*******************************************************************************************************
 *
 * 학급 정보 component
 *
 *******************************************************************************************************/

 function ClassList({items, setClassInfo} ) {
  const listItems = items.map((item, index) => {
    return(
      <Container_school_name key={index}>
        <Input_school_name
        
          placeholder="반이름"
          onChange={(e) => {
            var newItems = [...items];
            newItems[index].value = e.target.value;
            setClassInfo(newItems);
          }}
          value={item.value}
        />
        <Btn_img_minus onClick={() => {
          var newItems = [...items];
          newItems.splice(index,1);
          setClassInfo(newItems);
        }}>
          <Img_minus src={minus_img} resizeMode={'contain'} />
        </Btn_img_minus>
      </Container_school_name>
    );
  })
  return listItems;
}


/**
 * 어린이집 이미지 등록 리스트들
 * @param {*} param0 
 * @returns 
 */
 function FileBoxs({items, setChildCareimgList, serverChildCareimg, setServerChildCareimg}) {
  console.log("FileBoxs tems.length : " + items.length);

  if( serverChildCareimg ) {
    return(
      <File_box>
        <Text_File_name>{serverChildCareimg}</Text_File_name>
        
      </File_box>
    );
  }
  else {
    const listItems = items.map((item, index) => {
      console.log("FileBoxs " + index + "-" + item.name);
      return(
        <File_box key={index}>
          <Text_File_name>{item.name}</Text_File_name>
          <Btn_file_del onClick={() => {
            var newChildCareimgList = [...items];
            newChildCareimgList.splice( index, 1 );
            setChildCareimgList(newChildCareimgList);
          }}>
            <span>삭제</span>
          </Btn_file_del>
        </File_box>
      );
    })
    return listItems;
  }
}

/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/
export default function AFmanagementScreen(props) {
  const [nameInputStr, setNameInputStr] = useState('');     // 가입자 이름
  
  const [phoneStr, setPhoneStr] = useState('');   // 전화번호
  const [schoolStr, setSchoolStr] = useState(''); // 유아교육기관 정보
  const [pwStr, setPwStr] = useState('');         // 비밀번호
  const [repwStr, setRePwStr] = useState('');     // 비밀번호 확인
  
  const [imgProfile, setImgProfile] = useState(noImg); // 추가정보 입력 프로필 이미지

  const [logoName, setLogoName] = useState('logo.png'); // 대표 이미지 등록 (로고)
  const [classInfo, setClassInfo] = useState([]);       // 학급정보
  const [photoStr, setPhotoStr] = useState('');         // 대표 사진 등록
  const [childNameStr, setChildNameStr] = useState('');

  const [inviteCode, setInviteCode] = useState('');

  const [globalData, setGlobalData] = useState(null);       // 글로벌 데이터
  const [childCareData, setChildCareData] = useState(null); // 어린이집 데이터
  const [userData, setUserData] = useState(null);           // 계정 데이터
  
  const [serverLogo, setServerLogo] = useState(null); // 서버에 저장된 로고
  const [serverChildCareimg, setServerChildCareimg] = useState(null); // 서버에 저장된 대표 사진들

  /* 이미지 관련 */
  const profileImgInputFile = useRef(null);
  const logoImgInputFile = useRef(null);
  const childCareimg1InputFile = useRef(null);
  
  const [imgProfileBase64, setImgProfileBase64] = useState(null);
  const [logoImgBase64, setLogoImgBase64] = useState(null);
  const [childCareimgList, setChildCareimgList] = useState([]);

  console.log('AFmanagementScreen!!');

  useEffect(() => {
    console.log("AFmanagementScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA );
    
    ServerRequestManager.requestChangePage( window, "AFmanagementScreen" );
  }, []);

  const IpcReceiver = (arg) => {
    console.log("AFmanagementScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        console.log("AFmanagementScreen IpcReceiver arg.data : " + JSON.stringify(arg.data) );
        setGlobalData(arg.data);

        setRowData(arg.data);
      }
    } 
  }
  
  const setRowData = ( gData ) => {
    let childCareDatas = gData.childCareData;

    if( childCareDatas != null && childCareDatas.length > 0 ) {
      let cCData = childCareDatas[0];
      if( cCData.parent_code ) {
        setInviteCode( cCData.parent_code );
      }
      setChildCareData(cCData);
      setSchoolStr(cCData.name);

      setClassInfo(Util.makeClassList(cCData.class_data));

      if( cCData.img_path1 ) {
        let count = 1;
        if( cCData.img_path2 ) count++;
        if( cCData.img_path3 ) count++;
        setServerChildCareimg( "등록된 대표사진 " + count + "개" );
      }
      if( cCData.logo_path ) {
        setServerLogo( "등록된 로고 있음" );
      }
    }    
    setUserData(gData.userData);
    setNameInputStr(gData.userData.name);
    if( gData.userData.img_path ) {
      setImgProfile(Util.makeShowImageFromUrl(gData.userData.img_path, noImg));
    }
  }

  const showToast = (text) => {
    Util.showToast(text);
  };

  const clickSendCodeButton = () => {

    Util.writeTextInClipboard(window, inviteCode);
    Util.showToast("복사완료");

    /* 
    if( codeAll.length == 6 ) {
      alert(codeAll);
      //navigation.navigate('BFSelectSignupScreen', { changeScreenType : cst} )
    }
    else {
      showToast( '6자리의 코드를 입력해주세요.' );
    } */
  };

  const clickLogoRegister = () => {
    // alert('대표 로고 이미지 등록')
    logoImgInputFile.current.click();
  };

  const clickLogoRemove = () => {
    // alert('대표 로고 이미지 삭제')

    setLogoName("");
    setServerLogo(null);
    setLogoImgBase64(null);
  };

  /**
   * 대표 사진등록 버튼
   */
  const clickPhotoRegister = () => {
    if( childCareimgList.length < 3 ) {
      childCareimg1InputFile.current.click();
    }
    else {
      Util.showToast("대표사진은 최대 3개까지만 등록할수 있습니다.");
    }
  };

  const clickPhotoRemove = () => {
    alert('사진 삭제')
  };

  const clickSaveButton = () => {
    //alert('저장')
    // navigation.navigate('AFMainSchoolScreen');

    let middleData = {};

    // 원장 선생님일 경우
    if( userData.member_alias == ServerRequestManager.TEACHER_TYPE_PRESIDENT ) {
      let toastMessage = null;

      if( schoolStr == null || schoolStr == "" ) { toastMessage = "유아 교육 기관 명이 없습니다."; }
      if( classInfo == null || classInfo.length == 0 ) { toastMessage = "학급정보가 없습니다. 학급을 입력해주세요."; }

      let childcare_class = "";
      for( var i=0; i<classInfo.length; i++ ) {
        if( i > 0 ) childcare_class += ",";
        childcare_class += classInfo[i].value;
        if( classInfo[i].value == null || classInfo[i].value == "" ) {
          toastMessage = "비어있는 학급정보가 있습니다.";
          break;
        }
      }

      if( toastMessage != null ) {
        Util.showToast(toastMessage); 
        return;
      }

      if( logoImgBase64 != null ) {
        middleData["childcare_logo"] = logoImgBase64;
      }
      
      if( serverChildCareimg == null ) {
        middleData["childcare_img1"] = childCareimgList.length > 0 && childCareimgList[0].base64Data ?childCareimgList[0].base64Data:"";
        middleData["childcare_img2"] = childCareimgList.length > 1 && childCareimgList[1].base64Data ?childCareimgList[1].base64Data:"";
        middleData["childcare_img3"] = childCareimgList.length > 2 && childCareimgList[2].base64Data ?childCareimgList[2].base64Data:"";
      }
      
      middleData["childcare_name"] = schoolStr;
      middleData["childcare_class"] = childcare_class;
    }

    let pw = null;
    if( pwStr != null && pwStr != "" ) {
      pw = pwStr;
      if( pw != repwStr ) {
        Validation.MsgCheckPasswordConfirm();
        return;
      }
    }

    let member_type = globalData.loginData.member_type;
    let id = globalData.loginData.id;
    let sess_id = globalData.loginData.sess_id;
    let member_img = imgProfileBase64;
    let member_name = nameInputStr;
    let member_alias = userData.member_alias;

    ServerRequestManager.requestUpdateUserInfo( (result, data) => {
      console.log("requestUpdateUserInfo result result : " + result);
      console.log("requestUpdateUserInfo result data : " + JSON.stringify(data));
      
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        Util.showToast("정보 수정 완료");

        var receiveUserInfo = false;
        var receiveChildcareInfo = false;
        var childcare_num = null;

        // 유저 정보 요청
        ServerRequestManager.requestGetUserInfo((result, data) => {
          console.log("requestGetUserInfo result result : " + result);
          console.log("requestGetUserInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

            receiveUserInfo = true;

            if( receiveChildcareInfo == true ) {
              props.history.goBack();
            }
          }
        }, id, sess_id);        

        // 어린이집 정보 요청
        ServerRequestManager.requestChildCareInfo((result, data) => {
          console.log("requestChildCareInfo result result : " + result);
          console.log("requestChildCareInfo result data : " + JSON.stringify(data));
          
          if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
            
            Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__CHILD_CARE_DATA, data.result_data);
    
            receiveChildcareInfo = true;

            if( receiveUserInfo == true ) {
              props.history.goBack();
            }
          }
        }, id, sess_id, childcare_num);
      }
    }, member_type, id, sess_id, pw, member_img, member_name, member_alias, middleData);
  };

  const clickClassAdd = () => {
    var newInfo = [...classInfo];
    newInfo.push({value:""});
    setClassInfo(newInfo);
    //classInfo
  };


  let nameInputRef = useRef();
  let phoneInputRef = useRef();

  let profileName = "";
  if( userData != null && userData.name ) {
    profileName = userData.name;
  }
  else {
    profileName = "이름없음";
  }

  if( userData != null && userData.member_alias == ServerRequestManager.TEACHER_TYPE_PRESIDENT ) {
    profileName += " 원장선생님";
  }
  else if( userData != null && userData.member_alias == ServerRequestManager.TEACHER_TYPE_TEACHER ) {
    profileName += " 선생님";
  }

  return (
      <div>
        <div>
          <Container>
            <TitleComponent history={props.history} title={'유아 교육 기관 관리'} />
            {/* 프로필 영역 
            <Layout_view_top>
              <TouchAble_btn_goback onClick={() => props.history.goBack()}>
                <Img_top_icon2
                  src={top_icon1_img}
                  resizeMode={'contain'}
                />
              </TouchAble_btn_goback>
              <Text_top_title>유아 교육 기관 관리</Text_top_title>
            </Layout_view_top>*/}
            {/* End - 프로필 영역 */}

            <Wrap>
              <Text_title>학부모 가입코드</Text_title>
              <Container_input_view>
                <InviteCodeComponent inviteCode={inviteCode} setInviteCode={setInviteCode} editableValue={false} />
              </Container_input_view>

              <ButtonComponent
                text="복사"
                buttonClick={clickSendCodeButton}
              />

              <Line_mid></Line_mid>

              <View_drawer_head>
                <TouchAble_profile_img onClick={() => {profileImgInputFile.current.click();}}>
                  <Profile>
                    <Img_profile src={imgProfile}/>
                  </Profile>
                  <Img_camera
                    src={camera_img}
                    resizeMode={'contain'}
                    onClick={()=>{}}
                  />
                </TouchAble_profile_img>

                <Wrap_info>
                  <Wrap_info_top>
                    <Text_name>{profileName}</Text_name>
                  </Wrap_info_top>
                  <Text_email>{globalData!= null && globalData.loginData != null?globalData.loginData.id:""}</Text_email>
                </Wrap_info>
              </View_drawer_head>

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="가입자 이름을 입력해주세요"
                    placeholderTextColor={nameInputStr == '' ? '#bbb' : '#000'}
                    onChange={(e) => setNameInputStr(e.target.value)}
                    returnKeyType="next"
                    /*onSubmitEditing={() => {
                      phoneInputRef.focus();
                    }}*/ // TODO Enter 입력시 이벤트 동작
                    value={nameInputStr}
                    ref={(input) => {
                      nameInputRef = input
                    }}
                  />
                </View_input_wrap>
              </ShadowComponent>

              {/* <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="전화번호를 입력해주세요"
                    keyboardType="number-pad"
                    placeholderTextColor={phoneStr == '' ? '#bbb' : '#000'}
                    onChange={(e) => setPhoneStr(e.target.value)}
                    returnKeyType="next"
                    value={phoneStr}
                    ref={(input) => {
                      phoneInputRef = input
                    }}
                  />
                </View_input_wrap>
              </ShadowComponent> */}

              <Text_info>
                ※ 온라인 교실 참여 시 다른 사람에게 표시됩니다.
              </Text_info>

              <div style={{height:10}} />

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="비밀번호"
                    placeholderTextColor={pwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    onChange={(e) => setPwStr(e.target.value)}
                    returnKeyType="next"
                    type="password"
                    /*onSubmitEditing={() => {
                      pwTextInput.focus();
                    }}*/ // TODO Enter 입력시 이벤트로 변경
                    value={pwStr}
                  />
                </View_input_wrap>
              </ShadowComponent>

              <ShadowComponent>
                <View_input_wrap>
                  <Input_common
                    placeholder="비밀번호 확인"
                    placeholderTextColor={repwStr == '' ? '#bbb' : '#000'}
                    textContentType="password"
                    onChange={(e) => setRePwStr(e.target.value)}
                    returnKeyType="next"
                    type="password"
                    /*onSubmitEditing={() => {
                      pwTextInput.focus();
                    }}*/ // TODO Enter 입력시 이벤트로 변경
                    value={repwStr}
                  />
                </View_input_wrap>
              </ShadowComponent>

              { userData != null && userData.member_alias == ServerRequestManager.TEACHER_TYPE_PRESIDENT &&
              <>
                <Line_mid />

                <Text_title>유아 교육 기관 정보</Text_title>

                <Text_info pink>
                  입력된 내용으로 학부모님들께 우리 기관을 소개할 수 있습니다.
                </Text_info>

                <div style={{height: Const.getRateHeight(10)}} />

                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholderTextColor={schoolStr == '' ? '#bbb' : '#000'}
                      onChange={(e) => setSchoolStr(e.target.value)}
                      value={schoolStr}
                      returnKeyType="next"
                      style={{fontSize:'1.24em', fontWeight:'500', top:2}}
                      placeholder={"유아 교육 기관 명을 입력해주세요"}
                    />
                  </View_input_wrap>
                </ShadowComponent>

                <Text_info>
                  ※ 등록된 유아 교육 기관명이 다르면 수정할 수 있습니다.
                </Text_info>

                <Line_mid />

                <Text_mid_first>대표 이미지 등록(로고)</Text_mid_first>
                <div style={{height: 20}} />
                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="jpg, png, bmp 파일만 가능합니다."
                      placeholderTextColor={'#bbb'}
                      returnKeyType="done"
                      disabled={true}
                    />
                    <View_wrap_radio>
                      <Btn_box onClick={() => clickLogoRegister()}>
                        <Text_btn active>등록</Text_btn>
                      </Btn_box>
                    </View_wrap_radio>
                  </View_input_wrap>
                </ShadowComponent>

                { ( logoImgBase64 != null || serverLogo != null ) && 
                  <File_box>
                    <Text_File_name>{serverLogo?serverLogo:logoName}</Text_File_name>
                    { serverLogo == null &&
                    <Btn_file_del onClick={() => clickLogoRemove()}>
                      <span>삭제</span>
                    </Btn_file_del>
                    }
                  </File_box>
                }

                <Line_mid />

                <Text_mid_first>대표 사진 등록(전경)</Text_mid_first>
                <Text_info>
                  유아 교육 기관의 사진을 등록해주세요. 최대 3장까지 등록할 수
                  있습니다.
                </Text_info>

                <div style={{height: Const.getRateHeight(10)}} />

                <ShadowComponent>
                  <View_input_wrap>
                    <Input_common
                      placeholder="jpg, png, bmp 파일만 가능합니다."
                      placeholderTextColor={'#bbb'}
                      returnKeyType="done"
                      disabled={true}
                    />
                    <View_wrap_radio>
                      <Btn_box onClick={() => clickPhotoRegister()}>
                        <Text_btn active>등록</Text_btn>
                      </Btn_box>
                    </View_wrap_radio>
                  </View_input_wrap>
                </ShadowComponent>


                <FileBoxs 
                serverChildCareimg = {serverChildCareimg}
                setServerChildCareimg = {setServerChildCareimg}
                items={childCareimgList}
                setChildCareimgList={(list)=>setChildCareimgList(list)}/>
                {/* 
                <File_box>
                  <Text_File_name>logo.png</Text_File_name>
                  <Btn_file_del onClick={() => clickPhotoRemove()}>
                    <span>삭제</span>
                  </Btn_file_del>
                </File_box>

                <File_box>
                  <Text_File_name>logo.png</Text_File_name>
                  <Btn_file_del onClick={() => clickPhotoRemove()}>
                    <span>삭제</span>
                  </Btn_file_del>
                </File_box>*/}

                <Line_mid />

                <Text_mid_box>
                  <Text_mid_first>학급정보</Text_mid_first>
                    <Btn_img_plus onClick={() => clickClassAdd()}>
                      <Img_plus
                        src={plus_img}
                        resizeMode={'contain'}
                      />
                    </Btn_img_plus>
                  </Text_mid_box>

                <div style={{height: Const.getRateHeight(20)}} />

                <ClassList items={classInfo} setClassInfo={setClassInfo} />
              </>
              }
              <div style={{height: Const.getRateHeight(60)}} />

              <ButtonComponent text="저장" buttonClick={clickSaveButton} />
                <div style={{height: 30}} />
            </Wrap>
            

            <input type='file' id='file' ref={profileImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
              var file = e.target.files[0];            
              var name = Util.getFileName(e.target.value);
              Util.loadImageFileBase64(file, (previewData, base64Data)=> {
                setImgProfile(previewData);
                setImgProfileBase64(base64Data);
              });
            }}/>
            <input type='file' id='file' ref={logoImgInputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
              var file = e.target.files[0];            
              var name = Util.getFileName(e.target.value);
              Util.loadImageFileBase64(file, (previewData, base64Data)=> {
                //setImgProfile(previewData);
                setLogoName(name);
                setLogoImgBase64(base64Data);
                setServerLogo(null);
              });
            }}/>
            <input type='file' id='file' ref={childCareimg1InputFile} accept={".jpeg, .jpg, .png, .bmp"} style={{display: 'none'}} onChange={(e)=>{
              var file = e.target.files[0];            
              var name = Util.getFileName(e.target.value);
              Util.loadImageFileBase64(file, (previewData, base64Data)=> {
                //setImgProfile(previewData);
                
                console.log("loadImageFileBase64");
                var newChildCareimgList = [... childCareimgList];

                var childCareimg = {
                  name:name,
                  base64Data:base64Data
                }
                newChildCareimgList.push(childCareimg);
                console.log("beforeChildCareimgList size : " + newChildCareimgList.length);
                setChildCareimgList(newChildCareimgList);
                console.log("beforeChildCareimgList 22222");
                setServerChildCareimg(null);
              });
            }}/>
          </Container>
        </div>
      </div>
  );
}

/*******************************************************************************************************
 * Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
`;

const Wrap = styled.div`
  padding: 0 16px;
  box-sizing:border-box;
`;

// 최상단 헤더 영역
const Container_input_view = styled.div`
  width: 100%;
  height:70px;
  margin:30px 0;
  display:flex;
  justify-content:space-around;
`;

const TouchAble_btn_goback = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 15px;
`;

// 프로필 영역
const View_drawer_head = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 120px;
`;

const Wrap_info = styled.div`
  width: 280px;
  margin-left: 20px;
`;

const TouchAble_profile_img = styled.div`
  position: relative;
  width:100px;
`;

const Wrap_info_top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

const Text_name = styled.span`
  color: #000;
  font-size: 1.24em;
  font-weight: 500;
  margin-bottom:8px;
`;

const Text_email = styled.span`
  color: #000;
  font-size: 17px;
  font-weight: 100;
  margin-top: ${Const.getRateHeight(5)}px;
`;

const Text_school_name = styled.span`
  font-size: 1.05em;
  font-weight: 500;
  padding-left:5px;
  box-sizing:border-box;
`;

const Input_school_name = styled.input`
  font-size: 1em;
  padding-left:2%;
  border:none;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  position:relative;
  margin-bottom:10px;

  ${(props) =>
    props.selectbox &&
    css`
      padding-top: ${Const.getRateHeight(5)}px;
    `}
`;

const Input_common = styled.input`
  width: 100%;
  font-size: 0.92em !important;
  border:none;
`;

const Text_info = styled.span`
  margin-top: 10px;
  display:block;
  font-size:0.82em;

  ${(props) =>
    props.pink &&
    css`
      color: ${Const.COLOR_PINK};
    `}
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:30px;
  margin-bottom:30px;
  border-bottom:1px dotted #ddd;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const Text_title = styled.span`
  color: #000;
  font-size: 1.15em;
  font-weight: 500;
  margin-bottom:6px;
  display:block;
  margin-top:28px;
`;

const Line_mid2 = styled.div`
  border-width: ${Const.BORDER_DEFAULT_WIDTH}px;
  border-color: ${Const.BORDER_DEFAULT_COLOR};
  margin-top: ${Const.getRateHeight(50)}px;
  margin-bottom: ${Const.getRateHeight(10)}px;
`;


const Text_mid_box = styled.div`
  position:relative;
`;


// 인풋 추가정보입력
const Text_mid_first = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  display:block;
`;

const Profile = styled.div` 
  border-radius:70%;
  width:100px;
  height:100px;
  border:1px solid #ddd;
  overflow:hidden;
`;

const Img_profile = styled.img`
  width: 100%;
  height: 100%;
`;

const Img_camera = styled.img`
  position: absolute;
  height: 33px;
  right:0px;
  bottom:0px;
  margin:0 -3px;
  cursor:pointer;
`;

const Btn_img_plus = styled.div`
  z-index: 1;
  position: absolute;
  right:0;
  top:4px;
  width:51px;
  height:45px;
  text-align:center;
  padding-top:10px;
  box-sizing:border-box;
  margin:-16px -5px 0 0;
  cursor:pointer;

`;

const Img_plus = styled.img`
  height: ${Const.getRateHeight(20)}px;
`;

const Container_school_name = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;
  position:relative;
`;

const Btn_img_minus = styled.div`
  right: 0;
  position: absolute;
  top:0px;
  width:50px;
  height:50px;
`;

const Img_minus = styled.img`
  height: 30px;
  position: absolute;
  right: 10px;
  top:10px;
`;

const Btn_box = styled.div`
  top:0;
  right:0px;
  padding-right:8px;
  box-sizing:border-box;
  cursor:pointer;
`;

const Text_btn = styled.span`
  width:60px;
  color: #fff;
  text-align: center;
  height: 42px;
  line-height: 42px;
  border-radius: 100px;
  font-size:0.92em;
  font-weight: 500;
  background-color: #b7b7b7;
  display:inline-block;

  ${(props) =>
    props.active &&
    css`
      background-color: #73c4a7;
    `}
`;

const File_box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom:6px;
`;

const Text_File_name = styled.span`
  font-size: 0.92em;
  padding-bottom: 6px;
  max-width:317px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
`;

const Btn_file_del = styled.div`
  border-width: 1px;
  border-radius: 20px;
  margin-left:15px;
  border:1px solid #666;
  font-size:0.82em;
  width:42px;
  height:22px;
  text-align:center;
  line-height:22px;
  font-weight:500;
  cursor:pointer;
`;
/*******************************************************************************************************
 * Style end
 *******************************************************************************************************/