import React, {useState} from 'react';
//import {Switch} from 'react-native';
import styled, {css} from 'styled-components';

import alarm_on_img from '../../img/alarm_on.png'
import alarm_off_img from '../../img/alarm_off.png'

export default function SwitchComponent(props) {
  const toggleSwitch = () => props.setIsEnabled((e) => !e);

  return (
    <Switch_img
      src={props.isEnabled?alarm_on_img:alarm_off_img}
      isEnabled={props.isEnabled}
      onClick={()=>{ 
        props.setIsEnabled( !props.isEnabled )
        props.onChange( !props.isEnabled );
      }}/>
  );

  /*
  return (
    <Switch
      trackColor={{false: '#e1e1e1', true: '#fd407f'}}
      thumbColor={props.isEnabled ? '#fff' : '#f4f3f4'}
      ios_backgroundColor="#e1e1e1"
      onValueChange={toggleSwitch}
      value={props.isEnabled}
    />
  );*/
}


const Switch_img = styled.img`
  width:60px;
  position:absolute;
  top:10px;
  right:5px;
  cursor:pointer;
  
`;
