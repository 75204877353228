import React from 'react';
//import {StyleSheet} from 'react-native';
import Const from '../../util/Const';
//import Shadow from 'shadows-rn'; // TODO 다른대안 필요

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
 function DateTimePickerModalComponent(props) {

  return (
    <div>

    </div>
  );
}
export default DateTimePickerModalComponent