import React, {memo} from 'react';
import Const from '../../../util/Const';
import styled, {css} from 'styled-components';
//import * as Animatable from 'react-native-animatable';
import ModalComponent from '../ModalComponent';
import InviteCodeComponent from '../InviteCodeComponent';
import ButtonComponent from '../ButtonComponent';

import closeImg from '../../../img/close.png'
import codeImg from '../../../img/code.png'

/*******************************************************************************************************
 *
 * 자녀정보입력추가 '초대코드입력' 팝업창
 * @param {isVisibleCodeModal}      팝업창 show / hdie 여부
 * @param {setIsVisibleCodeModal}   팝업창 show / hdie 여부 셋팅
 * @param {SetInviteCode}           입력코드 6자리 셋팅
 * @param {clickCodeButton}         확인 클릭시 동작
 *
 *******************************************************************************************************/

  // memo = 컴포넌트가 바뀌었을 때만 호출되어 성능이 최적화
  function ShowPopupCodeComponent({
    isVisibleCodeModal,
    setIsVisibleCodeModal,
    setInviteCode,
    clickCodeButton,
  }) {
    // alert('자녀 정보 입력 추가')

    return (
      isVisibleCodeModal == true && (
        <ModalComponent
          isVisible={true}
          backdropOpacity={0.6}
          backdropTransitionInTiming={400}
          backdropTransitionOutTiming={100}>
          <Modal_contents
            animation={Const.ANIMATION_NAME}
            duration={Const.ANIMATION_DURATION}
          >
            <CodeModal_head>
              <Touchable_Modal_close
                onClick={() => setIsVisibleCodeModal(false)}>
                <Img_close
                  src={closeImg}
                  resizeMode={'contain'}
                />
              </Touchable_Modal_close>
              <Img_code
                src={codeImg}
                resizeMode={'contain'}
              />
              <Text_modal_Title>초대코드 입력</Text_modal_Title>
            </CodeModal_head>

            <Text_modal_sub>
              유아 교육 기관 초대코드를 입력해주세요.
            </Text_modal_sub>

            <CodeModal_wrapper_code>
              <InviteCodeComponent setInviteCode={setInviteCode} onComplete={()=>{
                clickCodeButton();
                setIsVisibleCodeModal(false);
                }} />
            </CodeModal_wrapper_code>

            <Text_btm_capution>※ 영문 대문자, 숫자 6자리</Text_btm_capution>
            <ButtonComponent text="확인" buttonClick={ () => {
              console.log("ShowPopupCodeComponent buttonClick");
              clickCodeButton();
              setIsVisibleCodeModal(false);
            }
              } />
          </Modal_contents>
        </ModalComponent>
      )
    );
  }
  export default ShowPopupCodeComponent

/************************************************************************************************************************************************
 *  Style Start
 ************************************************************************************************************************************************/

// '자녀정보입력 팝업' 영역
// Animatable 추가
const Modal_contents = styled.div`
    background:#fff;
    border-radius:10px;
    width:90%;
    padding:25px 18px;
    box-sizing:border-box;
    position:absolute;
    left:5%;
    top:calc(50% - 167px);
  `;

const CodeModal_head = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Touchable_Modal_close = styled.div`
  position: absolute;
  right: 0px;
  top: 0;
  width:38px;
  height:38px;
  justify-content:center;
  align-items:center;
  text-align:center;
  line-height:42px;
`;

const Img_close = styled.img`
  height: 16px;
  width: 16px;
`;

const Img_code = styled.img`
  width: 43px;
  height: 45px;
  display:inline-block;
`;

const Text_modal_Title = styled.span`
  font-size: 1.4em;
  font-weight: 500;
  height:45px;
  vertical-align:top;
  line-height:45px;
  display:inline-block;
  margin-left:10px;
`;

const Text_modal_sub = styled.span`
  font-size:0.91em;
  display:block;
  color:#666;
`;

const CodeModal_wrapper_code = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height:68px;
  display:flex;
  justify-content:space-between;
  margin:18px 0 10px;
`;

const Text_btm_capution = styled.span`
  font-size: 0.82em;
  color:#fd407f;
  margin-bottom:22px;
  font-weight:600;
  display:block;
`;

/************************************************************************************************************************************************
 *  Style End
 ************************************************************************************************************************************************/