import React, {useEffect, useState} from 'react';

import Const from '../util/Const';
import Util from '../util/Util';
import ButtonComponent from './component/ButtonComponent';
import ButtonComponentInSignup from './component/ButtonComponentInSignup';

import TitleComponent from './component/TitleComponent';
import ServerRequestManager from '../util/ServerRequestManager';
import styled from 'styled-components';

import treeImg from '../img/tree.png'
import { useLocation } from "react-router-dom";
import SnsLogin from '../util/SnsLogin';
import NaverLogin from 'react-naver-login';
import KakaoLogin from 'react-kakao-login';
import GoogleLogin from 'react-google-login';

/*******************************************************************************************************
 *
 * 코드입력 화면
 *
 *******************************************************************************************************/
export default function BFSelectSignupScreen( props ) {
  const [codeResultData, setCodeResultData] = useState(null);
  const [logo, setLogo] = useState(null);
  console.log('BFSelectSignupScreen!!!!');

  useEffect(() => {
    console.log("BFSelectSignupScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT);
    Util.getToken_setCookie_forNaverLogin();

    ServerRequestManager.requestChangePage( window, "BFSelectSignupScreen" );
  }, []);

  const IpcReceiver = (arg) => {
    console.log("IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__CODE_CHECK_RESULT ) {
        setCodeResultData(arg.data);
        setLogo( Util.makeShowImageFromUrl(arg.data.logo_path, treeImg) );

        Util.setCookie('memberType', arg.data.member_type, 1);
      }
    }
  }


  const moveSignUpScreen = (member_type, id_type, id, token) => {
    if( member_type == ServerRequestManager.MEMBER_TYPE_PARENTS ) {
      props.history.push({
        pathname:"/BFSignupParentScreen",
        state:{
          id_type: id_type,
          id:id,
          id_token:token
        }
      })
    }
    else if( member_type == ServerRequestManager.MEMBER_TYPE_TEACHER ) {
      props.history.push({
        pathname:"/BFSignupSchoolScreen",
        state:{
          id_type: id_type,
          id:id,
          id_token:token
        }
      })
    }
  }


  const clickSignUpButton = () => {
    moveSignUpScreen( codeResultData.member_type, ServerRequestManager.ID_TYPE_NONE, '');
  };
    

  const _clickSnsLoginKakao = (e) => {
    const { id } = e.profile;
    const { access_token } = e.response;

    moveSignUpScreen( codeResultData.member_type, ServerRequestManager.ID_TYPE_KAKAO, id, access_token);
  };


  const _clickSnsLoginNaver = (e) => {
    const { id } = e;
    const member_type = Util.getCookie('memberType');
    const get_naver_token = Util.getCookie('access_token');

    moveSignUpScreen(member_type, ServerRequestManager.ID_TYPE_NAVER, id, get_naver_token);

    Util.deleteCookie('memberType');
    Util.deleteCookie('access_token');
  };


  const _clickSnsLoginGoogle = (e) => {
    const google_id = e.Ft.NT;
    const access_token = e.accessToken;

    moveSignUpScreen( codeResultData.member_type, ServerRequestManager.ID_TYPE_GOOGLE, google_id, access_token);
  };


  return (
    <div>
      <div>
        <Container>
          <TitleComponent history={props.history} title={'회원가입'} />
          <Container_top>
            
            <Logo_box>
              <Img_logo
                src={logo}
                resizeMode={'contain'}
              />
            </Logo_box>
            <div>
              <Text_top_first>반갑습니다.</Text_top_first>
              <Text_top_second>
                유아 교육 전용 온라인 교실 e코앤코에 오신 것을 환영합니다.
              </Text_top_second>
            </div>

            <ButtonComponentInSignup
              text="이메일로 회원가입"
              buttonClick={clickSignUpButton}
              imgName="EMAIL"
            />

            <Margin_box />

            <NaverLogin 
              clientId={SnsLogin.NAVER_CLIENT_ID}
              callbackUrl={window.location.href}
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <ButtonComponentInSignup
                    text="네이버로 회원가입"
                    imgName="NAVER"
                    styleType="type2"
                  />
                </div>
              )}
              onSuccess={(e) => _clickSnsLoginNaver(e)}
              onFailure={(result) => console.error(result)}
            />

            <Margin_box />

            <KakaoLogin
              token={SnsLogin.KAKAO_CLIENT_ID}
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <ButtonComponentInSignup
                    text="카카오로 회원가입"
                    imgName="KAKAO"
                    styleType="type2"
                  />  
                </div>
              )}
              onSuccess={(e) => _clickSnsLoginKakao(e)}
              onFail={console.error}
              onLogout={console.info}
            />

            <Margin_box />

            <GoogleLogin
              clientId={SnsLogin.GOOGLE_CLIENT_ID}
              buttonText="Login"
              render={renderProps => (
                <div onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <ButtonComponentInSignup
                    text="구글로 회원가입"
                    imgName="GOOGLE"
                    styleType="type2"
                  />
                </div>
              )}
              onSuccess={(e) => _clickSnsLoginGoogle(e)}
              onFailure={console.log}
              cookiePolicy={'single_host_origin'}
            />
          </Container_top>
        </Container>
      </div>
    </div>
  );
}

/*******************************************************************************************************
 *  Style Start
 *******************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  background: #fff;
  width:'100%';
  padding-bottom:50px;
`;

// 상단 영역
const Container_top = styled.div`
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  padding:0 26px;
`;

const Text_top_title = styled.span`
  font-size: 1.6em;
  font-weight: 400;
  display:inline-block;
  color: #000;
  width:100%;
  padding-right:70px;
  box-sizing:border-box;
`;

const Logo_box = styled.div`
  width:180px;
  height:180px;
  margin:0 auto;
  margin-top:30px;
  border:1px solid #ddd;
  border-radius:70%;
  overflow:hidden;
`;

const Img_logo = styled.img`
  width: 100%;
  height: 100%;
`;

const Text_top_first = styled.span`
  font-size: 1.6em;
  font-weight: 500;
  margin-top:40px;
  display:block;
`;

const Text_top_second = styled.span`
  font-size: 0.82em;
  margin-top:10px;
  font-weight: 400;
  color:#666;
  margin-bottom:34px;
  display:block;
`;

// 하단 영역
const Container_bottom = styled.div`
  justify-content: center;
  align-items: center;
  width: ${SCREEN_WIDTH}px;
  padding: ${Const.getRateWidth(30)}px;
`;

const Margin_box = styled.div`
  height: 10px;
`;
/*******************************************************************************************************
 *  Style End
 *******************************************************************************************************/
