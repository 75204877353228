import React, {useState, useEffect, useContext, createContext, useRef} from 'react';

import Const from '../util/Const';
import Util from '../util/Util';
import styled, {css} from 'styled-components';
//import RNPickerSelect from 'react-native-picker-select';
import RNPickerSelectComponent from './component/RNPickerSelectComponent';
//import Modal from 'react-native-modal';
import ModalComponent from './component/ModalComponent';
//import * as Animatable from 'react-native-animatable';
//import DropDownPicker from 'react-native-dropdown-picker';
import DropDownPickerComponent from './component/DropDownPickerComponent';
import ShadowComponent from './component/ShadowComponent';
import ButtonComponent from './component/ButtonComponent';
import CustomDrawerContent from "./CustomDrawerContent"; // 메뉴
import ShowPopupReservationRemoveComponent from './component/popup/ShowPopupReservationRemoveComponent';

import photo_scholl_img from '../img/photo_school.png'
import live_img from '../img/live.png'
import time_img from '../img/time.png'
import in_img from '../img/in.png'
import close_img from '../img/close.png'
import info_img from '../img/info.png'
import tree_img from '../img/tree.png'
import no_img from '../img/no_img.png'
import top_icon2_img from '../img/top_icon2.png'
import class_img_img from '../img/class_img.png'
import class_open_img from '../img/class_open.png'
import empty1_img from '../img/empty1.png'
import empty2_img from '../img/empty2.png'
import class_more_img from '../img/class_more.png'

import calendar_left_img from '../img/calendar_left.png'
import calendar_right_img from '../img/calendar_right.png'
import ServerRequestManager from '../util/ServerRequestManager';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const date = new Date();
const Context = createContext();

// const iconRefresh = <FontAwesomeIcon icon={refresh} />

// 예약일정 년, 월 선택 옵션
const years = [
  {label: String(date.getFullYear()), value: String(date.getFullYear())},
  {
    label: String(date.getFullYear() + 1),
    value: String(date.getFullYear() + 1),
  },
];
const months = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
];

const nickNameItems = [
  {label: '없음', value: 0},
  {label: '원장', value: 1},
  {label: '선생님', value: 2}
];

// 어린이집 사진
const photoArr = [
  {
    id: 0,
    img:photo_scholl_img
  },
  {
    id: 1,
    img:photo_scholl_img
  },
  {
    id: 2,
    img:photo_scholl_img
  },
];

/**************************************************************************************************
 * '오늘의 온라인 교실' component  
 * @param {items.title}   제목
 * @param {items.start}   시작 시간
 * @param {items.end}     종료 시간
 * @param {items.status}  진행중 / 대기중 / 종료 ( LIVE / WAITING / END )
/*************************************************************************************************/
const TodayConferences = ({items, props, setRemoveClass, menuOpenPosition, setMenuOpenPosition}) => {
  if (items == null || items == 'undefined') {
    items = ['1번', '2번', '3번', '1번', '2번', '3번'];
  }

  const usingContext = useContext(Context);
  let globalData = usingContext.state.globalData;

  const listItems = items.map((item, index) => (
    <div key={index}>
      <Todayclass status={item.status}>
        <Box_left status={item.status}>
          <Box_left_top>
            <Text_todayclass_title
              status={item.status}
              numberOfLines={1}
              ellipsizeMode="tail">
              {item.title}
              
            </Text_todayclass_title>
            <Text_todayclass_live>
                {
                  // 오픈중일 때 LIVE 아이콘 삽입
                  item.status == ServerRequestManager.ROOM_STATUS_LIVE && (
                  
                      <Img_live
                        src={live_img}
                        resizeMode={'contain'}
                      />
                    
                  )
                }
              </Text_todayclass_live>
            
          </Box_left_top>

          <Box_left_btm>
            <Img_time src={time_img} />

            <Text_time status={item.status}>
              {item.start} ~ {item.end}
            </Text_time>
          </Box_left_btm>
        </Box_left>

        <Box_right status={item.status}>
          {item.status == ServerRequestManager.ROOM_STATUS_LIVE ? (
            // 입장
            <div
              onClick={() => {       
                if( globalData.userData.name ) {
                  console.log("TodayConferences globalData.userData.name : " + globalData.userData.name);
                  console.log("TodayConferences item.id : " + item.ide);
                  /*var data = {};
                  data.screen_type = 1; // webview page
                  data.type = 1; // 생성 타입
                  data.code = 123555; // 생성코드*/

                  ServerRequestManager.requestJoinRoom((result, data) => {
                    console.log("requestJoinRoom result result : " + result);
                    console.log("requestJoinRoom result data : " + JSON.stringify(data));

                    if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                      
                      let rid = item.data.id;
                      let rpw = item.data.pw;
                      let childrenChk = usingContext.state.childrenChk;
                      let childrenItems = usingContext.state.childrenItems;

                      if( data.result_data ) {
                        if( data.result_data.id && data.result_data.pw ) {
                          rid = data.result_data.id;
                          rpw = data.result_data.pw;
                        }
                      }

                      // rid 
                      // 웹뷰 메시지 전송
                      let roomData = {
                        req_type:'r_join',
                        req_data:{
                          rid:rid,
                          rpw:rpw,
                          name:globalData.userData.name,
                          codec:data.result_data.codec,
                          logo:usingContext.state.logo,
                          rname:item.title
                        }
                      }
                      // console.log(usingContext.state.logo);

                      ServerRequestManager.requestWebViewPostMessage( window, roomData );
                    }
                  }, globalData.loginData.id, globalData.loginData.sess_id, item.data.id + "", null);
                }
                else {
                  usingContext.action.setCurrentRoomData(item);
                  // TODO 추가정보가 있다면은 바로 입장 없다면 추가정보 입력
                  usingContext.action.setIsAddModalVisible(true);
                }
              }}>
              <Img_btn_live
                src={in_img}
                resizeMode={'contain'}
              />
            </div>
          ) : item.status == ServerRequestManager.ROOM_STATUS_PREPARE ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_RESERVATION}>준비중</Text_tim_right>
          ) : item.status == ServerRequestManager.ROOM_STATUS_RESERVATION ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_RESERVATION}>예약</Text_tim_right>
          ) : item.status == ServerRequestManager.ROOM_STATUS_CANCEL ? (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_END}>취소</Text_tim_right>
          ) : (
            <Text_tim_right status={ServerRequestManager.ROOM_STATUS_END}>종료</Text_tim_right>
          )}
        </Box_right>

        {/* 교실을 개설한 방장일 경우에서 수정 버튼 표시 */}
        { globalData && item.data && globalData.userData.num == item.data.member_num && 
        <Todayclass_edit_menu_btn onClick={()=>{
            if( menuOpenPosition == index ) {
              setMenuOpenPosition(-1);
            }
            else {
              setMenuOpenPosition(index);
            }
          }}>
          <Todayclass_edit_menu_btn_img src={class_more_img}/>
        </Todayclass_edit_menu_btn>
        }        
        { menuOpenPosition == index && 
          <Todayclass_edit_menu>
              <Todayclass_edit_menu_item onClick={()=>{
                if( item.status == ServerRequestManager.ROOM_STATUS_RESERVATION ) {
                  props.history.push({
                    pathname:"/AFOpenClassScreen",
                    state:{
                      roomData: item.data
                    }
                  })
                }
                else {
                  Util.showToast("예약(대기)상태에서만 수정할 수 있습니다.");
                }
                setMenuOpenPosition(-1);
              }}>수정</Todayclass_edit_menu_item>
              <Todayclass_edit_menu_item_divide/>
              <Todayclass_edit_menu_item onClick={()=>{
                setRemoveClass(item.data);
                setMenuOpenPosition(-1);
              }}>취소</Todayclass_edit_menu_item>
          </Todayclass_edit_menu>
        }
       
      </Todayclass>
    </div>
  ));
  return listItems;
};

/************************************************************************************************
 * '예약일정' component
 * @param {items.day}     예약된 일정 날짜
 * @param {items.title}   제목
 * @param {items.start}   시작 시간
 * @param {items.end}     종료 시간
 ************************************************************************************************/
const MonthclassConferences = ({props, items, todayCount, setRemoveClass, menuOpenPosition, setMenuOpenPosition}) => {
  if (items == null || items == 'undefined') {
    items = ['1번', '2번', '3번'];
  }
  const usingContext = useContext(Context);
  let globalData = usingContext.state.globalData;
  console.log("MonthclassConferences menuOpenPosition : " + menuOpenPosition);

  const listItems = items.map((item, index) => (
    <Monthclass key={index}>
      <View_day>
        <Text_day>{item.day}일</Text_day>
      </View_day>

      <View_monthclass_comment>
        <Box_left_top>
          <Text_monthclass_title numberOfLines={1} ellipsizeMode="tail">
            {item.title}
          </Text_monthclass_title>
        </Box_left_top>

        <Box_left_btm>
          <Img_time src={time_img} />
          <Text_violet>
            {item.start} ~ {item.end}
          </Text_violet>
        </Box_left_btm>
      </View_monthclass_comment>

        {/* 교실을 개설한 방장일 경우에서 수정 버튼 표시 */}
      { globalData && item.data && globalData.userData.num == item.data.member_num && 
      <Todayclass_edit_menu_btn onClick={()=>{
          if( menuOpenPosition - todayCount == index ) {
            setMenuOpenPosition(-1);
          }
          else {
            setMenuOpenPosition(todayCount+index);
          }
        }}>
        <Todayclass_edit_menu_btn_img src={class_more_img}/>
      </Todayclass_edit_menu_btn>
      }
      { menuOpenPosition - todayCount == index && 
        <Todayclass_edit_menu>
            <Todayclass_edit_menu_item onClick={()=>{              
              if( item.status == ServerRequestManager.ROOM_STATUS_RESERVATION ) {
                props.history.push({
                  pathname:"/AFOpenClassScreen",
                  state:{
                    roomData: item.data
                  }
                })
              }
              else {
                Util.showToast("예약(대기)상태에서만 수정할 수 있습니다.");
              }
              setMenuOpenPosition(-1);
            }}>수정</Todayclass_edit_menu_item>
            <Todayclass_edit_menu_item_divide/>
            <Todayclass_edit_menu_item onClick={()=>{
                setRemoveClass(item.data);
                setMenuOpenPosition(-1);
              }}>취소</Todayclass_edit_menu_item>
        </Todayclass_edit_menu>
      }

    </Monthclass>
  ));
  return listItems;
};

/**************************************************************************************************
 * 추가정보입력 팝업창 component
 ****************************************************************************************************/
const AddInfoModal = ({props}) => {
  const usingContext = useContext(Context);
  
  const clickContinueButton = () => {
    let globalData = usingContext.state.globalData;

    
    var member_type = usingContext.state.globalData.loginData.member_type;
    var id = usingContext.state.globalData.loginData.id;
    var pw = "";
    var sess_id = usingContext.state.globalData.loginData.sess_id;
    var member_img = null; // TODO 변경된거 없다면 파라미터 미포함
    var member_name = usingContext.state.nameStr;
    var member_alias = usingContext.state.nickNameValue;
    var middleData = null;


    if( usingContext.state.nameStr ) {
    }
    else {
      Util.showToast("가입자 이름이 비어있습니다.");
      return;
    }
    

    ServerRequestManager.requestUpdateUserInfo((result, data) => {
      console.log("requestUpdateUserInfo result result : " + result);
      console.log("requestUpdateUserInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        reSaveUserDataAndJoinRoom();
      }
    }, member_type, id, sess_id, pw, member_img, member_name, member_alias, middleData);
  };

  const reSaveUserDataAndJoinRoom = () => {
    let globalData = usingContext.state.globalData;

    var id = usingContext.state.globalData.loginData.id;
    var sess_id = usingContext.state.globalData.loginData.sess_id;
    
    // 유저 정보 요청
    ServerRequestManager.requestGetUserInfo((result, data) => {
      console.log("requestGetUserInfo result result : " + result);
      console.log("requestGetUserInfo result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        
        Util.setGlobalData( window, Const.GLOBAL_DATA_TYPE__USER_DATA, data.result_data);

        globalData.userData = data.result_data;
        usingContext.action.setGlobalData(globalData);

        joinRoom( globalData.userData );
      }
    }, id, sess_id);  
  }  
  
  const joinRoom = ( userData ) => {
    let globalData = usingContext.state.globalData;
    

    // 웹뷰 메시지 전송
    let roomData = {
      req_type:'r_join',
      req_data:{
        rid:usingContext.state.currentRoomData.data.id,
        rpw:usingContext.state.currentRoomData.data.pw,
        name:userData.name,
        codec:usingContext.state.currentRoomData.data.codec,
        logo:usingContext.state.logo,
        rname:usingContext.state.currentRoomData.title
      }
    }
    console.log('roomData = ' + roomData)
    ServerRequestManager.requestWebViewPostMessage( window, roomData );
  }
  return (
    <div>
      <ModalComponent
        // backdropOpacity={0.6}
        //backdropTransitionInTiming={300}
        //backdropTransitionOutTiming={300}
        isVisible={usingContext.state.isAddModalVisible}
        
        // backdropTransitionInTiming={300}
        // backdropTransitionOutTiming={300}
        >
        <Modal_contents
          // animation={'zoomIn'} // 이름
          // duration={800} // 속도
          // useNativeDriver={true} // 드라이버를 Native로 할지, javascript로 할지 선택
        >
          <div>
            <Modal_head>
              <Touchable_Modal_close
                onClick={() => usingContext.action.setIsAddModalVisible(false)}>
                <Img_close
                  src={close_img}
                  resizeMode={'contain'}
                />
              </Touchable_Modal_close>
              <Modal_head_top>
                <Img_info
                  src={info_img}
                  resizeMode={'contain'}
                />
                <Modal_head_top_title>추가정보 입력</Modal_head_top_title>
              </Modal_head_top>
              <Text_head_btm>
                추가정보를 입력하시면 진행중인 온라인 교실에 참여할 수
                있습니다.
              </Text_head_btm>
            </Modal_head>

            <Line_mid2 />

            <Modal_body_title>가입자 정보</Modal_body_title>
            <ShadowComponent>
              <View_input_wrap>
                <Input_common
                  placeholder="가입자 이름을 입력해주세요"
                  placeholderTextColor={usingContext.state.nameStr == '' ? '#bbb' : '#000'}
                  onChange={(e) => usingContext.action.setNameStr(e.target.value)}
                  returnKeyType="next"
                  /*onSubmitEditing={() => {
                    pwTextInput.focus();
                  }}*/ // TODO Enter 입력시 이벤트로 변경
                  defaultValue={usingContext.state.nameStr}
                />
              </View_input_wrap>
            </ShadowComponent>
            <ShadowComponent>
              <View_select_wrap>
                <RNPickerSelectComponent
                  placeholder={{
                    label:
                    usingContext.state.nickNameValue == ''
                        ? '호칭을 선택해주세요'
                        : usingContext.state.nickNameValue,
                    value: usingContext.state.nickNameValue,
                    color: '#ff4784',
                  }}
                  items={nickNameItems}
                  onValueChange={(val) => {
                    usingContext.action.setNickNameValue(val);
                  }}
                  value={usingContext.state.nickNameValue}
                  style={{
                    placeholder: {
                      color: usingContext.state.nickNameValue == '' ? '#bbb' : '#000',
                    },
                  }}
                />
              </View_select_wrap>
            </ShadowComponent>

            <Text_info>
              ※ 온라인 교실 참여 시 다른 사람에게 표시됩니다.
            </Text_info>

            <div style={{height: 30}} /> 
            
            <ButtonComponent
              text="저장 후 입장"
              buttonClick={clickContinueButton}
            />
          </div>
        </Modal_contents>
      </ModalComponent>
    </div>
  );
};


/************************************************************************************************
 * '어린이집 사진' component
 * @param {schoolPhoto}
 ************************************************************************************************/
const ImgSchoolComponent = ({ schoolPhoto }) => {

  const listItems = schoolPhoto.map((item, index) => (
    <Img_school
      key={index}
      src={item.img}
      resizeMode={'contain'}
    />
  ));
  return listItems;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

/*******************************************************************************************************
 *
 * 메인 화면
 *
 *******************************************************************************************************/
export default function AFMainSchoolScreen(props) {

  const [schoolName, setSchoolName] = useState("기본어린이집");
  const [logo, setLogo] = useState(tree_img);          // 어린이집 로고
  const [schoolPhoto, setSchoolPhoto] = useState([]);               // 어린이집 사진
  const [todayItems, setTodayItems] = useState([]);                 // 오늘의 온라인 교실
  const [menuOpenPosition, setMenuOpenPosition] = useState(-1);     // 
  const [monthclassItems, setMonthclassItems] = useState([]);  // 예약일정
  const [year, setYear] = useState(date.getFullYear());                   // select 년 option
  const [month, setMonth] = useState(date.getMonth() + 1);                // select 월 option
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);      // 추가정보입력 팝업창 show / hide
  const [currentRoomData, setCurrentRoomData] = useState(null);           // 현재 방 데이터
  const [childcareNum, setChildcareNum] = useState(null);

  const [nameStr, setNameStr] = useState('');             // 가입자 이름
  const [nickNameValue, setNickNameValue] = useState('');     // 호칭
  const [childNameStr, setChildNameStr] = useState('');   // 아이 이름
  const [gender, setGender] = useState('');               // 남아, 여아
  const [birthStr, setBirthStr] = useState('');           // 출생년도
  const [groupStr, setGroupStr] = useState('');           // 반

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [globalData, setGlobalData] = useState(null);
  const [removeClass, setRemoveClass] = useState(null); // '자녀정보삭제' 팝업창 show /hide 여부
  const [todayClassRefrechTos, setTodayClassRefrechTos] = useState(null); // 오늘의 교실 갱신 타입아웃

  useEffect(() => {
    console.log("AFMainSchoolScreen useEffect");

    Util.setIpcReceiver(IpcReceiver);
    Util.getGlobalData( window, Const.GLOBAL_DATA_TYPE__ALL_DATA);


    ServerRequestManager.requestChangePage( window, "AFMainSchoolScreen" );
  }, []);

  /*useInterval(() => {
    requestTodayClassList( globalData.loginData, childcareNum);
  }, Const.ROOM_LIST_REFRESH_DELAY);*/

  const IpcReceiver = (arg) => {
    console.log("AFMainSchoolScreen IpcReceiver arg : " + JSON.stringify(arg) );
    if( arg.data_type == Const.IPC_DATA_TYPE_GET_DATA ) {
      if( arg.global_data_type == Const.GLOBAL_DATA_TYPE__ALL_DATA ) {
        setGlobalData(arg.data);

        let userData = arg.data.userData;
        let childCareDatas = arg.data.childCareData;
        var childcare_num = null;

        if( userData != null ) {
          setNickNameValue(userData.member_alias);
          setNameStr(userData.name);
        }

        if( childCareDatas !== null && childCareDatas !== undefined && childCareDatas.length > 0 ) {
          setSchoolElement(childCareDatas[0]);
          childcare_num = childCareDatas[0].num;

          setLogo(Util.makeShowImageFromUrl( childCareDatas[0].logo_path ,tree_img));
        }
        setChildcareNum(childcare_num);

        requestTodayClassList( arg.data.loginData, childcare_num);
        requestMonthClassList( arg.data.loginData, childcare_num);
      }
    } 
  }

  const setSchoolElement = ( childCareData ) => {
    setSchoolName(childCareData.name?childCareData.name:"어린이집");

    let photoList = [];
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path1, photo_scholl_img) } );
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path2, photo_scholl_img) } );
    photoList.push( { id:photoList.length, img: Util.makeShowImageFromUrl(childCareData.img_path3, photo_scholl_img) } );

    setSchoolPhoto(photoList);
  }

  const setTodayClassTimeout = ( todays, loginData, childcare_num ) => {
    if( todays != null && todays.length > 0 ) {
      let date = new Date();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let seconds = date.getSeconds();

      let tHour = 0;
      let tMinute = 0;

      for( var i=0; i<todays.length; i++ ) {
        let today = todays[i];
        tHour = today.start.split(":")[0] * 1;
        tMinute = today.start.split(":")[1] * 1;

        if( hour == tHour ) {
          if( minute < tMinute ) {
            break;
          }
        }
        else if( hour < tHour ) {
          break;
        }
        tHour = 0;
        tMinute = 0;
      }

      if( tHour != 0 && tMinute != 0 ) {
        let time = 0;
        time += ( tHour - hour ) * 60 * 60 * 1000;      // 시 차이를 더해줌
        time += ( tMinute - minute ) * 60 * 1000;       // 분 차이를 더해줌
        let sd = 60 - seconds == 60 ? 0 : 60 - seconds; // 초 차이를 계산 60초일경우 0으로 계산
        time -= ( sd - 5 ) * 1000;                      // 초차이를 시간에서 뺄때 5초 뒤에 실행하도록 설정

        console.log("setTodayClassTimeout time : " + time);

        let timeout = setTimeout( function() {
          requestTodayClassList( loginData, childcare_num);
        }, time);
        setTodayClassRefrechTos(timeout);
        return;
      }
    }
    
    if( todayClassRefrechTos ) {
      clearTimeout(todayClassRefrechTos);
      setTodayClassRefrechTos(null);
    }
    console.log("setTodayClassTimeout not");
  }

  /* 오늘의 온라인 교실 목록 요청 */
  const requestTodayClassList = ( loginData, childcare_num, callback ) => {
    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let todayDayOfMonth = todayDate.getDate();
    let ym = todayYear + "" + Util.makeTwoNumberStr(todayMonth);
    let ymd = ym + Util.makeTwoNumberStr(todayDayOfMonth);

    ServerRequestManager.requestRoomList((result, data) => {
      console.log("requestRoomList result result : " + result);
      console.log("requestRoomList result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        let todays = [];

        if( data.result_data != null && data.result_data != undefined && data.result_data.length > 0 ) {
          
          for( var i=0; i<data.result_data.length; i++ ) {
            // 오늘일 경우에 추가
            if( data.result_data[i].ymd === ymd ) {
              let title = data.result_data[i].subject;
              let start = data.result_data[i].st_time;
              let end = data.result_data[i].ed_time;
              let status = data.result_data[i].status;
              todays.push({
                data:data.result_data[i],
                title:title,
                start:start,
                end:end,
                status:status
              });
            }
          }
        }

        setTodayItems(todays);

        setTodayClassTimeout( todays, loginData, childcare_num );
      }

      if( callback ) {
        callback(data.result);
      }
    }, loginData.member_type, loginData.id, loginData.sess_id, ym, childcare_num);
  }

  /* 온라인 교실 목록 요청 */
  const requestMonthClassList = ( loginData, childcare_num, paramYear, paramMonth ) => {

    if( paramYear == null )   paramYear = year;
    if( paramMonth == null )  paramMonth = month;
    let ym = paramYear + "" + Util.makeTwoNumberStr(paramMonth);

    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let todayDayOfMonth = todayDate.getDate();
    let todayIntYmd = todayYear * 10000 + ( todayMonth * 100 ) + todayDayOfMonth

    ServerRequestManager.requestRoomList((result, data) => {
      console.log("requestRoomList result result : " + result);
      console.log("requestRoomList result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        let months = [];

        if( data.result_data != null && data.result_data != undefined && data.result_data.length > 0 ) {
          
          for( var i=0; i<data.result_data.length; i++ ) {
            // 오늘일 경우에 추가 
            let intYmd = 0;
            if( data.result_data[i].ymd ) {
              intYmd = parseInt(data.result_data[i].ymd);
            }

            if( intYmd > todayIntYmd ) {

              let title = data.result_data[i].subject;
              let start = data.result_data[i].st_time;
              let end = data.result_data[i].ed_time;
              let status = data.result_data[i].status;

              let day = 0;
              if( data.result_data[i].ymd ) {
                day = parseInt(data.result_data[i].ymd.substr(6, 2));
              }

              months.push({
                data:data.result_data[i],
                title:title,
                start:start,
                end:end,
                day:day,
                status:status
              });
            }
          }
        }

        setMonthclassItems(months);
      }
    }, loginData.member_type, loginData.id, loginData.sess_id, ym, childcare_num);
  }


  // 온라인 교실 개설
  const clickOpenClassButton = () => {
    // alert('온라인 교실 개설')
    // navigation.navigate('AFOpenClassScreen')

    props.history.push({
      pathname:"/AFOpenClassScreen"
    })
  }

  const clickRemoveButton = () => {

    let id = globalData.loginData.id;
    let sess_id = globalData.loginData.sess_id;
    let rid = removeClass.id;

    ServerRequestManager.requestCancelRoom((result, data) => {
      console.log("requestCancelRoom result result : " + result);
      console.log("requestCancelRoom result data : " + JSON.stringify(data));
      
      if( data.result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
        requestTodayClassList( globalData.loginData, childcareNum);
        requestMonthClassList( globalData.loginData, globalData.childCareData.num, year, month );
      }
      setRemoveClass(null);
    }, id, sess_id, rid );
  }

  console.log('Call AFMainSchoolScreen');

  /********************************************************************************
   * ※ Context API 를 이용해서, 자식 컴포넌트 어디에서든지 value를 사용할 수 있게 작업. 
   *    (다른 JS파일로 이동은 불가, 한 파일내에서만 주고받기 가능)
   *  
   * 사용법
   * 1. <Context.Provider>로 전체를 감싸고, value를 아래와 같이 JSON 형태로 던진다.
   * 2. 이 값을 사용하려는 컴포넌트에서 <Context.Consumer>로 받아서 쓰면 끝.
   * 3. 추가로 <Context.Consumer>로 받아와 쓰는 방법외에, useContext()로 변수로 값을 받아와 쓰는 방법도 있음. (이 방법도 파일내부에서 사용중)
   ******************************************************************************/
  return (
    <>
      <Context.Provider
        value={{
          // '입장' 눌렀을 시 뜨는 '추가정보입력' 팝업창에 값 전달
          state: {
            isAddModalVisible,
            nameStr,
            nickNameValue,
            childNameStr,
            birthStr,
            groupStr,
            gender,
            globalData,
            currentRoomData,
            logo
          },
          action: {
            setIsAddModalVisible,
            setNameStr,
            setNickNameValue,
            setChildNameStr,
            setBirthStr,
            setGroupStr,
            setGender,
            setGlobalData,
            setCurrentRoomData
          },
        }}
      >
        <div>
          <div>
            <Container>
              <TouchAble_btn_nav onClick={() => setIsOpenMenu(true)}>
                <Img_top_icon2
                  src={top_icon2_img}
                  resizeMode={'contain'}
                />
              </TouchAble_btn_nav>

              <Wrap>
                <Intro_profile>
                  <Box_img_propfile>
                    <Img_profile src={logo} resizeMode={'contain'} />
                  </Box_img_propfile>
                  <Box_text_profile>
                    <Text_school_name>{schoolName}</Text_school_name>
                    <Text_intro>
                      유아 교육 전용 온라인 교실에<br />오신 것을 환영합니다.
                    </Text_intro>
                  </Box_text_profile>
                </Intro_profile>

                <Box_img_school>
                  <ImgSchoolComponent schoolPhoto={schoolPhoto} />
                </Box_img_school>

                <Line_mid></Line_mid>

                <Openclass>
                  <Img_class_img
                    src={class_img_img}
                    resizeMode={'contain'}
                  />
                  <View_welcome>
                    <Text_welcome>
                      유아 교육 전용 온라인 교실 {'\n'}<br></br>
                      <Text_strong>
                        <Big>e</Big> 코앤코
                      </Text_strong>{' '}
                      에 오신 것을 환영합니다.
                    </Text_welcome>
                  </View_welcome>
                  <Btn_open onClick={clickOpenClassButton}>
                    <Btn_open_inner>
                      <Img_class_open
                        src={class_open_img}
                        resizeMode={'contain'}
                      />
                    </Btn_open_inner>
                  </Btn_open>
                </Openclass>

                <Line_mid></Line_mid>
                <Wrapper_title_head>
                  <Text_item_title>오늘의 온라인 교실</Text_item_title>
                  <Text_refresh onClick={() => {requestTodayClassList( globalData.loginData, childcareNum, (result) => {
                    if( result == ServerRequestManager.REQ_RESULT_SUCCESS ) {
                      alert("리스트 새로고침 완료");
                    }
                    else {
                      alert("리스트 새로고침 실패");
                    }
                  })
                  
                  }}>
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </Text_refresh>
                </Wrapper_title_head>
                {
                  // 프로그램 있는지 체크
                  todayItems.length == 0 ? (
                    // 프로그램 없음
                    <Today_empty>
                      <Img_empty
                        src={empty1_img}
                        resizeMode={'contain'}
                      />
                      <Text_empty>오늘 예정된 프로그램이 없습니다.</Text_empty>
                    </Today_empty>
                  ) : (
                    // 프로그램 있음
                    <TodayConferences items={todayItems} props={props} menuOpenPosition={menuOpenPosition} setRemoveClass={setRemoveClass} setMenuOpenPosition={setMenuOpenPosition}/>
                  )
                }

                <Line_mid></Line_mid>

                <View_calendar_top>
                  <Text_item_title>예약일정</Text_item_title>
                </View_calendar_top>

                <View_top>
                  <Touchable_prev
                    onClick={() => {
                      console.log("Pre - year : " + year + ", month : " + month);

                      let changeYear = year;
                      let changeMonth = month;
                      if (month == 1) {
                        changeYear = years[0].value;
                        changeMonth = year != years[0].value ? 12 : months[0].value;
                        setYear(changeYear);
                        setMonth(changeMonth); // 뒤로가기가 올해라면 12월로 변경
                      } else {
                        changeMonth = changeMonth - 1;
                        setMonth(changeMonth);
                      }
                      
                      if( menuOpenPosition > -1 ) setMenuOpenPosition(-1);

                      requestMonthClassList( globalData.loginData, globalData.childCareData.num, changeYear, changeMonth );
                    }}>
                    <Img_calendar
                      src={calendar_left_img}
                      resizeMode={'contain'}
                    />
                  </Touchable_prev>
                  <Wrap_text>
                    <DropDownPickerComponent
                      items={years}
                      defaultValue={String(year)}
                      itemStyle={{justifyContent: 'flex-start'}}
                      activeItemStyle={{display: 'none'}}
                      arrowStyle={{display: 'none'}}
                      onValueChange={(val) => {
                        setYear(parseInt(val));
                        requestMonthClassList( globalData.loginData, globalData.childCareData.num, val, month );
                      }}
                      containerStyle={{height: 40}}
                      globalTextStyle={{fontSize: 22}}
                      style={{backgroundColor: '#fff', width: 80}}
                    />
                    <Text_cal>년</Text_cal>
                    <DropDownPickerComponent
                      items={months}
                      defaultValue={month}
                      itemStyle={{justifyContent: 'flex-start'}}
                      activeItemStyle={{display: 'none'}}
                      arrowStyle={{display: 'none'}}
                      onValueChange={(val) => {
                        setMonth(parseInt(val));
                        requestMonthClassList( globalData.loginData, globalData.childCareData.num, year, val );
                      }}
                      containerStyle={{height: 40}}
                      style={{backgroundColor: '#fff', width: 60}}
                      globalTextStyle={{fontSize: 22}}
                    />
                    <Text_cal>월</Text_cal>
                  </Wrap_text>
                  <Touchable_next
                    onClick={() => {
                      console.log("Next - years : " + years + ", month : " + month);

                      let changeYear = year;
                      let changeMonth = month;

                      if (month == 12) {
                        changeYear = years[1].value;
                        changeMonth = year == years[1].value ? 12 : months[0].value; // 마지막 년도의 마지막 달이면 12월로 고정

                        setYear(changeYear);
                        setMonth(changeMonth); 
                      } else {
                        changeMonth = changeMonth + 1;
                        setMonth(changeMonth);
                      }

                      if( menuOpenPosition > -1 ) setMenuOpenPosition(-1);

                      requestMonthClassList( globalData.loginData, globalData.childCareData.num, changeYear, changeMonth );
                    }}>
                    <Img_calendar
                      src={calendar_right_img}
                      resizeMode={'contain'}
                    />
                  </Touchable_next>
                </View_top>

                {
                  // 예약일정 있는지 체크
                  monthclassItems.length == 0 ? (
                    // 예약일정 없음
                    <Today_empty>
                      <Img_empty
                        src={empty2_img}
                        resizeMode={'contain'}
                      />
                      <Text_empty>오늘 예정된 프로그램이 없습니다.</Text_empty>
                    </Today_empty>
                  ) : (
                    // 예약일정 있음
                    <>
                      <MonthclassConferences props={props} items={monthclassItems} todayCount={todayItems.length} setRemoveClass={setRemoveClass} menuOpenPosition={menuOpenPosition} setMenuOpenPosition={setMenuOpenPosition} />
                      <div style={{height:30}}/>
                    </>
                  )
                }
              </Wrap>
            </Container>
          </div>
        </div>
        

        {/* 추가정보입력 팝업창 */}
        <AddInfoModal props={props}/>
        {/* 예약일정 팝업 */}
        
        {/* '온라인 교실 삭제' 팝업창 */}
        <ShowPopupReservationRemoveComponent
          removeClass={removeClass}
          setRemoveClass={setRemoveClass}
          clickRemove={clickRemoveButton}
        />
      </Context.Provider>

      
      <CustomDrawerContent
        isOpenMenu={isOpenMenu}
          globalData={globalData}
          setIsOpenMenu={setIsOpenMenu}
          {...props}/>
    </>
  );
}

/************************************************************************************************
 * Style Start
 ************************************************************************************************/
const SCREEN_HEIGHT = Const.screenHeight;
const SCREEN_WIDTH = Const.screenWidth;

const Container = styled.div`
  background-color: #fff;
  height:100%;
  position:relative;
  padding:16px;
  box-sizing:border-box;
  margin-bottom:20px;
`;


const TouchAble_btn_nav = styled.div`
  position: absolute;
  right:0px;
  top:0px;
  width:50px;
  height:50px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const Img_top_icon2 = styled.img`
  height: 24px;
  cursor:pointer;
`;

const Wrap = styled.div`
  padding-top: 0;
`;

const Openclass = styled.div`
  position: relative;
`;

const Img_class_img = styled.img`
  width: 250px;
`;

const View_welcome = styled.div`
  background-color: #777;
  border-radius: 10px;
  height:70px;
  padding: 12px;
  box-sizing:border-box;
  margin-top:-4px;
  line-height:22px;
`;

const Text_welcome = styled.span`
  font-size: 0.91em;
  font-weight:500;
  color:#fff;
`;

const Text_strong = styled.span`
  color: #fff34e;
  text-decoration: underline;
  font-weight: 600;
`;

const Big = styled.span`
  font-size: 17px;
`;

const Btn_open = styled.div`
  width:130px;
  position: absolute;
  right: 5px;
  bottom: 10px;
  margin-top:-3px;
  cursor:pointer;

  @media only screen and (max-width: 360px) {
    width: 90px;
  }
`;

const Btn_open_inner = styled.div`
    position:relative;
    max-width:150px;    
`;

const Img_class_open = styled.img`
  width: 100%;
  height: 100%;
`;

// 어린이집 프로필 영역
const Intro_profile = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
`;

const Box_img_propfile = styled.div`
  width: 70px;
  height: 70px;
  border:1px solid #ccc;
  text-align:center;
  overflow:hidden;
  display:inline-block;
  border-radius:70%;
`;

const Img_profile = styled.img`
  width: 100%;
  height: 100%;
`;

const Box_text_profile = styled.div`
  width:calc(100% - 70px);
  display:inline-block;
  vertical-align:top;
  height:69px;
  padding:9px 0 0 10px;
  box-sizing:border-box;
  margin-bottom:20px;
`;

const Text_school_name = styled.span`
  font-size: 1.15em;
  font-weight:500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:calc(100% - 70px);
  height:28px;
  line-height:28px;
  overflow:hidden;
`;

const Text_intro = styled.span`
  font-size:0.82em;
  display:inline-block;
  width:100%;
  color:#666;
`;

const Box_img_school = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${Const.getRateHeight(10)}px;
`;

const Img_school = styled.img`
  width: 32%;
  border-radius: 5px;
  height: 70px;
  max-width:120px;
`;

const Line_mid = styled.div`
  height: 1px;
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px dotted #ddd;
`;


const Text_item_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  color: #000;
  display:block;
  margin-top:26px;
  margin-bottom:18px;
`;

// 오늘의 온라인 교실 영역
const Todayclass = styled.div`
  background-color: #fffbea;
  border-radius: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top:6px;
  display:flex;
  padding:10px 14px;
  box-sizing:border-box;
  border:1px solid rgba(0,0,0,.05);
  position:relative;

  ${(props) =>
    /* '예약' */
    ( props.status == ServerRequestManager.ROOM_STATUS_PREPARE || 
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION ) &&
    css`
      background-color: #fff;
    `}

  ${(props) =>
    /* '종료' */
    ( props.status == ServerRequestManager.ROOM_STATUS_CANCEL || 
    props.status == ServerRequestManager.ROOM_STATUS_END || 
    props.status == "" ) &&
    css`
      background-color: #f4f4f4;
    `}
`;

const Box_left = styled.div`
  width: 100%;
  line-height:24px;

  // '라이브'
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
     
    `}
`;

// 왼쪽 타이틀 + 시간 영역
const Box_left_top = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:100%;

`;

const Text_todayclass_title = styled.span`
  font-size: 0.91em;
  font-weight:500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  max-width:calc(100% - 160px);

  

  // '종료'
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}


  @media only screen and (max-width: 360px) {
    max-width:calc(100% - 130px);
  }
`;

const Text_todayclass_live = styled.div`  
  display:inline-block;
  margin-left:5px;
  vertical-align:top;
`;
const Img_live = styled.img`
  width:33px;
`;

const Box_left_btm = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height:14px;
`;

const Img_time = styled.img`
  width: 15px;
  height:15px;
`;

const Text_time = styled.span`
  font-size: 0.80em;
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;

  // '라이브' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
      color: ${Const.COLOR_PINK};
    `}
  // '대기중' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION &&
    css`
      color: ${Const.COLOR_GREEN};
    `}
  // '종료' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}
`;
const Text_tim_right = styled.span`
  font-size: 0.96em;
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;

  // '라이브' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
      color: ${Const.COLOR_PINK};
    `}
  // '대기중' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_RESERVATION &&
    css`
      color: ${Const.COLOR_GREEN};
    `}
  // '종료' 색상
  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_END &&
    css`
      color: #aaa;
    `}
`;

const Box_right = styled.div`
  width: 70px;
  text-align:center;
  margin-right:13px;
  height:43px;
  line-height:43px;
  position: absolute;
  right:20px;
  top:10px;

  ${(props) =>
    props.status == ServerRequestManager.ROOM_STATUS_LIVE &&
    css`
    cursor:pointer;
    `}
`;

// 온라인 교실 메뉴 버튼
const Todayclass_edit_menu_btn = styled.div`
   display:block;
   padding:21px 10px;
   cursor:pointer;
   position:absolute;
   top:0;
   right:0;
`;

// 온라인 교실 메뉴 이미지
const Todayclass_edit_menu_btn_img = styled.img`
    width:5px;
`;

// 팝업 메뉴
const Todayclass_edit_menu = styled.div`
    display:inline-block;
    position:absolute;
    bottom:0;
    right:0;
    margin-bottom:-64px;
    background:#fff;
    box-sizing:border-box;
    box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
    border-radius:5px;
    z-index:2;

`;

// 팝업 메뉴 아이템
const Todayclass_edit_menu_item = styled.span`
    display:block;
    height:40px;
    line-height:40px;
    width:60px;
    text-align:center;
    font-size:0.91em;
    font-weight:500;
    cursor:pointer;
`;

// 팝업 메뉴 구분 선
const Todayclass_edit_menu_item_divide = styled.div`
    height: 1px;
    margin-top: 2px;
    margin-bottom:2px;
    border-bottom:1px dotted #ddd;
    display:block;
`;

const Img_btn_live = styled.img`
  width: 70px;
`;

const Today_empty = styled.div`
  align-items: center;
  justify-content: center;
  display:block;
  text-align:center;
  padding:30px 0;
`;

const Img_empty = styled.img`
  width: ${Const.getRateWidth(150)}px;
`;

const Text_empty = styled.span`
  color: #aaa;
  display:block;
  text-align:center;
  margin-top:15px;
`;

// 예약일정 영역
const View_calendar_top = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Img_bullet3 = styled.img`
  width: ${Const.getRateWidth(20)}px;
  margin-left: ${Const.getRateWidth(25)}px;
`;

const View_top = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  display:flex;
`;

const Touchable_prev = styled.div`
  width:40px;
  height: 40px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align:center;
  line-height:40px;
  cursor:pointer;
`;

const Touchable_next = styled.div`
  width: 40px;
  height:40px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align:center;
  line-height:40px;
  cursor:pointer;
`;

const Wrap_text = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text_cal = styled.span`
  font-size: 20px;
  margin: 0 20px 0 10px;
`;

const Img_calendar = styled.img`
  width: ${Const.getRateWidth(12)}px;
`;

// 예약일정 내부 리스트박스
const Monthclass = styled.div`
  background-color: #fff;
  border-radius: 15px;
  border:1px solid #ddd;
  padding:0 8px;
  box-sizing:border-box;
  height:68px;
  margin-bottom:5px;
  position:relative;
`;

const View_day = styled.div`
  width: 50px;
  height:50px;
  background-color: ${Const.COLOR_VIOLET};
  justify-content: center;
  align-items: center;
  border-radius: 70%;
  display:inline-block;
  text-align:center;
  line-height:48px;
  vertical-align:top;
  margin-top:8px;
`;

const Text_day = styled.span`
  color: #fff;
  font-size: 17px;
`;

const View_monthclass_comment = styled.div`
  padding-left: 10px;
  width:calc(100% - 50px);
  display:inline-block;
  padding-top:12px;
  box-sizing:border-box;
`;

const Text_monthclass_title = styled.span`
  font-size:0.91em;
  font-weight: 500;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  max-width:100%;
  height:23px;
`;

const Text_violet = styled.span`
  font-size: 0.80;
  color: ${Const.COLOR_VIOLET};
  font-weight: 500;
  vertical-align:top;
  margin-left:5px;
`;

// '추가정보입력 팝업' 영역
// Animatable 추가
const Modal_contents = styled.div`
    z-index: 1;
    background-color: #fff;
    margin:0 auto;
    top:50%;
    left:50%;
    position:absolute;
    transform:translate(-50%, -50%);
    padding: ${Const.getRateWidth(15)}px ${Const.getRateWidth(15)}px;
    border-radius: 10px;
    width: 80%;
    height:90%;
  `;

// 팝업창 헤더 영역
const Modal_head = styled.div`
  position: relative;
`;

const Modal_head_top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${Const.getRateHeight(15)}px;
`;

const Img_info = styled.img`
  width: 39px;
  margin-right: 6px;
`;

const Modal_head_top_title = styled.span`
  font-size: 1.6em;
  font-weight:500;
  line-height:38px;
`;

const Text_head_btm = styled.span`
  font-size:0.82em;
  margin-top:10px;
  display:block;
  color:#666;
`;

const Touchable_Modal_close = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const Img_close = styled.img`
  width: ${Const.getRateWidth(20)}px;
`;

const Line_mid2 = styled.div`
  border-top-width: ${Const.BORDER_DEFAULT_WIDTH}px;
  border-color: ${Const.BORDER_DEFAULT_COLOR};
  width: 100%;
  margin-bottom: 26px;
`;

const Wrapper_modal = styled.div`
  padding:0 ${Const.getRateWidth(15)}px;
`;
// 팝업창 메인 컨텐츠 영역
const Modal_body_title = styled.span`
  font-size: 1.15em;
  font-weight: 500;
  margin-bottom: 15px;
  display:block;
`;

const View_input_wrap = styled.div`
  width: 100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
  margin-top:8px;

  ${(props) =>
    props.selectbox &&
    css`
      box-shadow:none;
      padding:0;
    `}
`;

const Input_common = styled.input`
  width: 100%;
  height: 50px;
  font-size: 15px;
  border:none;
`;

const View_select_wrap = styled.div`
  margin-top:8px;
`;

const Text_info = styled.span`
  font-size:0.82em;
  display:block;
  margin-top:10px;
  color:#666;
`;

const Container_school_name = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width:100%;
  height:56px;
  line-height:56px;
  padding:0 7px;
  box-sizing:border-box;
  box-shadow:1px 1px 2px 2px rgba(0,0,0,0.1);
  border-radius:5px;
`;

const Container_img_box = styled.div`
  display:inline-block;
  margin-right:10px;
`;

const Img_logo_small = styled.img`
  height: 40px;
  width:40px;
  margin-top:8px;
`;

const View_wrap_radio = styled.div`
  position: absolute;
  top:0px;
  right:0px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display:flex;
`;

const Btn_radio = styled.div`
`;

const Text_radio = styled.span`
  width: 60px;
  color: #fff;
  text-align: center;
  height:42px;
  line-height: 42px;
  border-radius: 50px;
  font-size: ${Const.BUTTON_FONT_SIZE}px;
  font-weight: 500;
  margin-left: ${Const.getRateWidth(3)}px;
  background-color: #b7b7b7;
  display:inline-block;

  // 남아 클릭 시
  ${(props) =>
    props.male &&
    css`
      // 태그에 넣은 male 속성을 가진 태그만 가져와서 별도의 css를 넣는다.
      background-color: ${({gender}) => {
        // useState에 담긴 gender 속성 내부 값을 가져온다.
        if (gender == 'male') return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}

  // 여아 클릭 시
    ${(props) =>
    props.female &&
    css`
      background-color: ${({gender}) => {
        if (gender == 'female') return '#73c4a7';
        else return '#b7b7b7';
      }};
    `}
`;

const Margin_box = styled.div`
  margin-bottom: ${Const.getRateHeight(15)}px;
`;

const Wrapper_title_head = styled.div`
  position:relative;
`;

const Text_refresh = styled.span`
  position: absolute;
  top: -5px;
  right: 0;
  width: 40px;
  height: 40px;
  margin-top: 0 !important;
  text-align: center;
  line-height: 40px;
  font-size: 1.2em;
  color: #777676;
  cursor: pointer;
`;

/************************************************************************************************
 * Style end
 ************************************************************************************************/
